import { MERCH } from "../../../api/MerchItem";
import Button from "../../shared/Button/Button";
import MerchItem from "../MerchItem/MerchItem";
import Line from "../../shared/Line/Line";

import "./Merch.scss";

const Merch = () => {
  const oldCar =
    "https://assets.gocoffeego.com/v2/assets/images/car.png";
  const girl =
    "https://assets.gocoffeego.com/v2/assets/images/girl.jpeg";

  return (
    <div className="Merch">
      <Line />
      <Line />
      <img className="Merch__car-img" src={oldCar} alt="old car" />
      <div className="Merch__wrap">
        <div className="Merch__subtitle">Merch</div>
        <h2 className="Merch__title">
          <span className="Merch__GoCoffeeGo">GoCoffeeGo</span> merch is just as
          fresh as our roaster's brews!
        </h2>
        <Button disabled={true} className="Merch__button" label="View Merch" />
        <div className="Merch__message">Super cool surprises... </div>
        <div className="Merch__message">Coming Soon! </div>
      </div>
      <div className="Merch__items">
        {MERCH.map((item) => (
          <MerchItem image={item.image} key={item.id} name={item.name} />
        ))}
      </div>
      <img className="Merch__girl-img" src={girl} alt="girl" />
      <Line />
      <Line />
    </div>
  );
};

export default Merch;
