import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import * as API from "../../../api/Api";
import FilterCategory from "./FilterCategory/FilterCategory";
import { useFilterProductsContext } from "../../../lib/context/FilterProductsContext/FilterProductsContext";
import { Icon } from "../../shared/Icon/Icon";
import cs from "classnames";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import { scrollInto } from "../../../lib/helpers/scrollTo";
import FilterCheckBoxCategory from "./FilterCheckBoxCategory/FilterCheckBoxCategory";

import "./Filter.scss";

const Filter = (props) => {
  const girls = "https://assets.gocoffeego.com/v2/assets/images/girls.svg";
  const isMobile = useResponsiveDimensions().isMobile;
  const {
    memberPicks,
    peaberryRecommends,
    awardWinners,
    bulkCoffees,
    limitedOfferings,
    top25,
  } = props;
  const [showFilter, setShowFilter] = useState(isMobile ? true : false);

  const [isFadingOut, setIsFadingOut] = useState([]);
  const [coffeeByRegion, setCoffeByRegion] = useState([]);
  const [coffeeByType, setCoffeByType] = useState([]);
  const [coffeeByRoast, setCoffeByRoast] = useState([]);
  const [purveyors, setPurveyors] = useState([]);

  let history = useHistory();
  let location = useLocation();
  let pathname = location.pathname;
  const filtersCtx = useFilterProductsContext();
  const params = useParams();
  const filterValue = params.pathParam2;

  const pathnamesArray = pathname.split("/");

  const noSlashPath = pathname.replace(/\/$/, "");
  let pathnameSnap;

  let purveyorName = pathnamesArray[pathnamesArray.indexOf("purveyors") + 1];
  let roastName = pathnamesArray[pathnamesArray.indexOf("coffee-by-roast") + 1];
  //let typeName = pathnamesArray[pathnamesArray.indexOf("coffee-by-type") + 1];
  let regionName =
    pathnamesArray[pathnamesArray.indexOf("coffee-by-region") + 1];

  useEffect(() => {
    let roast = coffeeByRoast.find((item) => item.slug === roastName);
    filtersCtx.setSelectedRoast(roast);

    let purveyor = purveyors.find((item) => item.slug === purveyorName);
    filtersCtx.setSelectedRoaster(purveyor);

    let region = coffeeByRegion.find((item) => item.slug === regionName);
    filtersCtx.setSelectedRegion(region);
  }, [coffeeByRegion, purveyors, coffeeByRoast, pathname]);

  useEffect(() => {
    let itemSum = [];
    pathnamesArray.filter((item, index, array) => {
      if (item === "coffee-by-type") {
        itemSum.push(array[index + 1]);
        let type = coffeeByType.find((item) => item.slug === array[index + 1]);

        if (type !== undefined) {
          if (
            filtersCtx.selectedType.length !== 0 &&
            filtersCtx.selectedType.some((elem) => elem?.slug !== type?.slug)
          ) {
            filtersCtx.setSelectedType((prev) => [...prev, type]);
          } else if (filtersCtx.selectedType.length === 0) {
            filtersCtx.setSelectedType((prev) => [...prev, type]);
          }
        }
      }
    });
  }, [coffeeByType]);

  const addParam = (oldParam, category, slug) => {
    if (pathnamesArray.includes(oldParam) && category !== "coffee-by-type") {
      pathnamesArray.splice(
        pathnamesArray.indexOf(oldParam),
        pathnamesArray.indexOf(oldParam) + 2,
        category,
        slug
      );
      history.push({
        pathname: pathnamesArray.join("/"),
      });
      pathnameSnap = pathnamesArray.join("/");
    } else if (!pathnamesArray.includes(slug)) {
      history.push({
        pathname: noSlashPath + `/${category}/${slug}`,
      });

      pathnameSnap = noSlashPath + `/${category}/${slug}`;
    }
  };

  const deleteParam = (param, slug) => {
    if (param === "coffee-by-type") {
      let sliceItem = pathnamesArray.slice();
      sliceItem.splice(sliceItem.indexOf(slug) - 1, 2);
      history.push({
        pathname: sliceItem.join("/"),
      });
      return;
    } else {
      let sliceItem = pathnamesArray.slice();
      sliceItem.splice(sliceItem.indexOf(param), 2);
      history.push({
        pathname: sliceItem.join("/"),
      });
    }
  };

  useEffect(() => {
    API.getCoffeeByRoast().then((res) => setCoffeByRoast(res.data));
    API.getCoffeeByRegion().then((res) => setCoffeByRegion(res.data));
    API.getCoffeeByType().then((res) => setCoffeByType(res.data));
    API.getCoffeePurveyors().then((res) => {
      setPurveyors(res.data);
    });
  }, []);

  const fadeOut = (cb, key, slug) => {
    setIsFadingOut(key);
    cb();
    deleteParam(key, slug);
  };

  const onItemSelect = (name, category_name, id, slug) => {
    addParam(category_name, category_name, slug);
    setShowFilter();

    if (pathname.startsWith("/shop")) {
      setTimeout(function () {
        scrollInto("Shop__wrapper", 10);
      }, 500);
    }
  };

  const deleteItem = (slug) => {
    const updateItems = filtersCtx.selectedType.filter(
      (item) => item.slug !== slug
    );
    filtersCtx.setSelectedType(updateItems);
    deleteParam("coffee-by-type", slug);
  };

  return (
    <div className={cs(`Filter`, showFilter && "active", props.className)}>
      <div
        className={cs("Filter__close", showFilter && "active")}
        onClick={() => setShowFilter(!showFilter)}
      >
        <h3 className="Filter__title">Coffee options</h3>
        <div>
          <div className="Filter__line1" />
          <div className="Filter__line2" />
        </div>
      </div>
      <button
        onClick={() => {
          filtersCtx.onClear();
        }}
        className="Filter__clear-btn"
      >
        Clear all
      </button>
      <div className="SelectedFilter">
        {filtersCtx.selectedRoaster && (
          <div
            className={cs(
              "SelectedFilter__item"
              /*  isFadingOut.includes("purveyors") && "fadeout" */
            )}
            id="purveyor_id"
          >
            <Icon
              style={{ width: "10px", height: "10px" }}
              onClick={() =>
                fadeOut(
                  () => setTimeout(() => filtersCtx.setSelectedRoaster(), 300),
                  "purveyors"
                )
              }
              className="SelectedFilter__icon"
              icon="close"
            />
            {filtersCtx.selectedRoaster.name}
          </div>
        )}
        {filtersCtx.selectedRoast && (
          <div
            className={cs(
              "SelectedFilter__item",
              isFadingOut.includes(
                filtersCtx.selectedRoast.old_category_name
              ) && "fadeout"
            )}
            id="coffee_roast_id"
          >
            <Icon
              style={{ width: "10px", height: "10px" }}
              onClick={() =>
                fadeOut(
                  () => setTimeout(() => filtersCtx.setSelectedRoast(), 300),
                  filtersCtx.selectedRoast.old_category_name
                )
              }
              className="SelectedFilter__icon"
              icon="close"
            />
            {filtersCtx.selectedRoast.name}
          </div>
        )}
        {filtersCtx.selectedType &&
          filtersCtx.selectedType?.map((item, index) => {
            return (
              <div
                key={index}
                className={cs(
                  "SelectedFilter__item",
                  isFadingOut.includes(item.old_category_name) && "fadeout"
                )}
                id="coffee_type_id"
              >
                <Icon
                  style={{ width: "10px", height: "10px" }}
                  onClick={() =>
                    fadeOut(
                      () => setTimeout(() => deleteItem(item?.slug), 300),
                      item.old_category_name,
                      item?.slug
                    )
                  }
                  className="SelectedFilter__icon"
                  icon="close"
                />
                {item.name}
              </div>
            );
          })}
        {filtersCtx.selectedRegion && (
          <div
            className={cs(
              "SelectedFilter__item",
              isFadingOut.includes(
                filtersCtx.selectedRegion.old_category_name
              ) && "fadeout"
            )}
            id="coffee_region_id"
          >
            <Icon
              style={{ width: "10px", height: "10px" }}
              onClick={() =>
                fadeOut(
                  () => setTimeout(() => filtersCtx.setSelectedRegion(), 300),
                  filtersCtx.selectedRegion.old_category_name
                )
              }
              className="SelectedFilter__icon"
              icon="close"
            />
            {filtersCtx.selectedRegion.name}
          </div>
        )}
      </div>

      <div className={cs("Filter__container", showFilter && "active")}>
        <Link to={pathnameSnap}></Link>
        {!peaberryRecommends && !memberPicks && (
          <FilterCategory
            purveyor
            onItemSelect={onItemSelect}
            selectedItem={filtersCtx.selectedRoaster?.slug}
            items={purveyors}
            name={"Shop by roaster"}
            setShowFilter={setShowFilter}
          />
        )}

        <FilterCategory
          onItemSelect={onItemSelect}
          selectedItem={filtersCtx.selectedRoast?.slug}
          peaberryRecommends={peaberryRecommends}
          memberPicks={memberPicks}
          items={coffeeByRoast}
          name={"Coffee By Roast"}
          setShowFilter={setShowFilter}
        />

        <FilterCheckBoxCategory
          onItemSelect={onItemSelect}
          items={coffeeByType}
          name={"Coffee By Type"}
          setShowFilter={setShowFilter}
        />
        <FilterCategory
          onItemSelect={onItemSelect}
          selectedItem={filtersCtx.selectedRegion?.slug}
          peaberryRecommends={peaberryRecommends}
          memberPicks={memberPicks}
          items={coffeeByRegion}
          name={"Coffee By Region"}
          setShowFilter={setShowFilter}
        />
        {!memberPicks &&
          !peaberryRecommends &&
          !awardWinners &&
          !bulkCoffees &&
          !limitedOfferings &&
          !top25 && (
            <div
              className="Filter__fiter-category-link"
              onClick={() => history.push("/tea")}
            >
              Tea
            </div>
          )}
        {!memberPicks &&
          !peaberryRecommends &&
          !awardWinners &&
          !bulkCoffees &&
          !limitedOfferings &&
          !top25 && (
            <div
              className="Filter__fiter-category-link"
              onClick={() => history.push("/top25")}
            >
              Top 25
            </div>
          )}
        {!memberPicks &&
          !peaberryRecommends &&
          !awardWinners &&
          !bulkCoffees &&
          !limitedOfferings &&
          !top25 && (
            <div
              className="Filter__fiter-category-link"
              onClick={() => history.push("/award-winners")}
            >
              Award Winners
            </div>
          )}
        {!memberPicks &&
          !peaberryRecommends &&
          !awardWinners &&
          !bulkCoffees &&
          !limitedOfferings &&
          !top25 && (
            <div
              className="Filter__fiter-category-link"
              onClick={() => history.push("/limited-offerings")}
            >
              Limited Offerings
            </div>
          )}
        {!memberPicks &&
          !peaberryRecommends &&
          !awardWinners &&
          !bulkCoffees &&
          !limitedOfferings &&
          !top25 && (
            <div
              className="Filter__fiter-category-link"
              onClick={() => history.push("/bulk-coffees")}
            >
              Bulk Coffees
            </div>
          )}
        {!memberPicks &&
          !peaberryRecommends &&
          !awardWinners &&
          !bulkCoffees &&
          !limitedOfferings &&
          !top25 && (
            <div
              className="Filter__fiter-category-link"
              onClick={() => history.push("/peaberry-recommends")}
            >
              Peaberry Recommends
            </div>
          )}
        {!memberPicks &&
          !peaberryRecommends &&
          !awardWinners &&
          !bulkCoffees &&
          !limitedOfferings &&
          !top25 && (
            <div
              className="Filter__fiter-category-link"
              onClick={() => history.push("/search")}
            >
              Advanced Search
            </div>
          )}
      </div>

      {filtersCtx.products.length !== 0 && (
        <img src={girls} alt="three girls" className="Filter__image" />
      )}
    </div>
  );
};

export default Filter;
