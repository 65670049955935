import React from "react";
import "./MerchItem.scss";

const MerchItem = (props) => {
  const { image, name } = props;
  return (
  <div className="MerchItem">
    <img className="MerchItem__image" src={image} alt={name} />
  </div>
  );
};

export default MerchItem;