import React from "react";

import LinkButton from "../../shared/LinkButton/LinkButton";
import { Event } from "../../../lib/helpers/GoogleAnalytics/event";

import "./HeroBanner.scss";

const Herobanner = () => {
  const Motor = "https://assets.gocoffeego.com/v2/assets/images/2.png";
  return (
    <div className="HeroBanner">
      <img className="HeroBanner__image" src={Motor} alt="motor" />
      <div className="HeroBanner__title-container">
        <div className="HeroBanner__title font-cairo">ESPRESSO</div>{" "}
        <div className="HeroBanner__title font-cairo">
          YOURSELF<span className="HeroBanner__tm">&#8482;</span>
        </div>
      </div>
      <h1 className="HeroBanner__text">
        The ultimate destination to buy coffee online. Roasted to order and
        shipped directly from the roaster to you. Voila!
      </h1>
      <div className="HeroBanner__buttons">
        <LinkButton
          onClick={() =>
            Event({
              category: "HOME_PAGE",
              action: "REDIRECT_TO_SHOP",
              label: "SHOP_ALL_BUTTON",
            })
          }
          className="HeroBanner__button"
          path="shop"
          label="SHOP ALL"
        />
        <LinkButton
          className="HeroBanner__button"
          path="roasters"
          label="SHOP BY ROASTER"
        />
      </div>
      <LinkButton
        className="HeroBanner__button2"
        path="shop"
        label="SHOP ALL SPECIALS"
      />
    </div>
  );
};

export default Herobanner;
