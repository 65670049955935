import React, { useState, useEffect } from "react";
import * as API from "../../api/Api";
import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import SelectItem from "../../components/AdvancedSearch/SelectItem/SelectItem";
import Product from "../../components/Shop/Product/Product";
import ViewMoreButton from "../../components/shared/ViewButton/ViewMoreButton";
import { useHistory, useLocation } from "react-router-dom";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import { useFilterProductsContext } from "../../lib/context/FilterProductsContext/FilterProductsContext";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";

import cs from "classnames";

import "./AdvancedSearch.scss";

const AdvancedSearch = () => {
  const FiltersCtx = useFilterProductsContext();
  const seoCtx = useSeoContext();
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  let history = useHistory();
  let location = useLocation();
  let pathname = location.pathname;
  let searchParams = new URLSearchParams(location.search);
  const roastId = searchParams.get("coffee_roast_id");
  const typeId = searchParams.get("coffee_type_id");
  const typeId2 = searchParams.get("coffee_type_id2");
  const typeId3 = searchParams.get("coffee_type_id3");
  const regionId = searchParams.get("coffee_region_id");
  const bulkCoffee = searchParams.get("bulk_coffee");
  const noteId = searchParams.get("coffee_note_id");
  const acidityId = searchParams.get("coffee_acidity_id");
  const bodyId = searchParams.get("coffee_body_id");
  const purveyorId = searchParams.get("purveyor_id");
  const isMobile = useResponsiveDimensions().isMobile;
  const searchKeyword = searchParams.get("q");
  const [inputValue, setInputValue] = useState();
  const [formSubmited, setFormSubmited] = useState(false);

  const foundSeo = seoCtx.pageMetadata.find(
    (el) => el.url === "/search/advanced"
  );

  useEffect(() => {
    API.getAdvanceSearchFilters().then((res) => {
      setItems(res.data);
      if (res.data) {
        setIsLoading(false);
      }
    });
  }, []);

  const addQuery = (key, value) => {
    // returns the existing query string
    searchParams.set(key, value);
    history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  const onItemSelect = (categoryName, itemName, id) => {
    if (itemName === "Regular") {
      addQuery(categoryName, 0);
      return;
    }
    if (itemName === "Bulk") {
      addQuery(categoryName, 1);
      return;
    }

    addQuery(categoryName, id);
  };

  return (
    <div className="AdvancedSearch">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <PrimaryTitle
        title="ADVANCED SEARCH"
        className="AdvancedSearch__main-title"
      />
      <div className="AdvancedSearch__container">
        <form>
          {!isMobile && (
            <input
              placeholder="Search by Name"
              name="search"
              value={searchKeyword}
              className="AdvancedSearch__input"
              onChange={(e) => {
                setIsLoading(true);
                setFormSubmited(false);
                setInputValue(e.target.value);
                history.push(`/search?q=${e.target.value}`);
              }}
            />
          )}
        </form>

        <div className="AdvancedSearch__wrapper">
          <div
            className={
              isMobile
                ? "AdvancedSearch__input-wrap"
                : "AdvancedSearch__input-wrap-none"
            }
          >
            <input
              placeholder="Search by Name"
              name="search"
              className="AdvancedSearch__input"
              onChange={(e) => history.push(`/search?q=${e.target.value}`)}
            />
          </div>

          <div className="AdvancedSearch__wrap">
            <div className="AdvancedSearch__input-wrap">
              <label className="AdvancedSearch__label">By Roast</label>
              <SelectItem
                isLoading={isLoading}
                className="AdvancedSearch__select"
                onItemSelect={onItemSelect}
                items={items.coffee_roasts}
                categoryName="coffee_roast_id"
                idSelected={roastId}
              />
            </div>
            <div className="AdvancedSearch__input-wrap">
              <label className="AdvancedSearch__label">By Type</label>
              <div>
                <SelectItem
                  className="AdvancedSearch__select"
                  selectedItem={FiltersCtx.typeSelected}
                  onItemSelect={onItemSelect}
                  items={items.coffee_types}
                  categoryName="coffee_type_id"
                  idSelected={typeId}
                  style={{ marginBottom: "12px" }}
                />
                <SelectItem
                  className="AdvancedSearch__select"
                  selectedItem={FiltersCtx.typeSelected}
                  onItemSelect={onItemSelect}
                  items={items.coffee_types}
                  categoryName="coffee_type_id2"
                  idSelected={typeId2}
                  style={{ marginBottom: "12px" }}
                />
                <SelectItem
                  className="AdvancedSearch__select"
                  selectedItem={FiltersCtx.typeSelected}
                  onItemSelect={onItemSelect}
                  items={items.coffee_types}
                  categoryName="coffee_type_id3"
                  idSelected={typeId3}
                />
              </div>
            </div>
            <div className="AdvancedSearch__input-wrap">
              <label className="AdvancedSearch__label">By Region</label>
              <SelectItem
                className="AdvancedSearch__select"
                selectedItem={FiltersCtx.regionSelected}
                onItemSelect={onItemSelect}
                items={items.coffee_regions}
                categoryName="coffee_region_id"
                idSelected={regionId}
              />
            </div>
          </div>
          <div className="AdvancedSearch__wrap">
            <div className="AdvancedSearch__input-wrap">
              <label className="AdvancedSearch__label">Notes</label>
              <SelectItem
                className="AdvancedSearch__select"
                selectedItem={FiltersCtx.notesSelected}
                onItemSelect={onItemSelect}
                items={items.coffee_notes}
                categoryName="coffee_note_id"
                idSelected={noteId}
              />
            </div>
            <div className="AdvancedSearch__input-wrap">
              <label className="AdvancedSearch__label">Acidity</label>
              <SelectItem
                className="AdvancedSearch__select"
                selectedItem={FiltersCtx.aciditySelected}
                onItemSelect={onItemSelect}
                items={items.coffee_acidities}
                categoryName="coffee_acidity_id"
                idSelected={acidityId}
              />
            </div>
            <div className="AdvancedSearch__input-wrap">
              <label className="AdvancedSearch__label">Body</label>
              <SelectItem
                className="AdvancedSearch__select"
                selectedItem={FiltersCtx.bodySelected}
                onItemSelect={onItemSelect}
                items={items.coffee_bodies}
                categoryName="coffee_body_id"
                idSelected={bodyId}
              />
            </div>
            <div className="AdvancedSearch__input-wrap">
              <label className="AdvancedSearch__label">Purveyor</label>
              <SelectItem
                className="AdvancedSearch__select"
                selectedItem={FiltersCtx.purveyorsSelected}
                onItemSelect={onItemSelect}
                items={items.coffee_purveyors}
                categoryName="purveyor_id"
                idSelected={purveyorId}
              />
            </div>
            <div className="AdvancedSearch__input-wrap">
              <label className="AdvancedSearch__label">By Bag Size</label>
              <SelectItem
                className="AdvancedSearch__select"
                selectedItem={FiltersCtx.bagSizeSelected}
                onItemSelect={onItemSelect}
                items={items.coffee_bag_sizes}
                categoryName="bulk_coffee"
                idSelected={bulkCoffee}
              />
            </div>
          </div>
        </div>

        <button
          onClick={() => {
            FiltersCtx.getProductsFromSearch();

            setFormSubmited(true);

            setTimeout(() => {
              FiltersCtx.scrollInto("AdvancedSearch__results");
            }, 2000);
          }}
          className={cs(
            "AdvancedSearch__button ",
            isMobile && "AdvancedSearch__button--mobile"
          )}
        >
          Find my Coffee
        </button>
      </div>
      <div
        id="AdvancedSearch__results"
        className="AdvancedSearch__products-container"
      >
        {FiltersCtx.productsFromSearch &&
          FiltersCtx.productsFromSearch.length !== 0 && (
            <div className="AdvancedSearch__products">
              {FiltersCtx.productsFromSearch?.map((product, index) => (
                <Product key={index} product={product} />
              ))}
            </div>
          )}{" "}
        {formSubmited && FiltersCtx.productsFromSearch.length === 0 && (
          <div className="AdvancedSearch__message">
            No items found {searchKeyword && `for "${searchKeyword}"`}
          </div>
        )}
      </div>
      {FiltersCtx.hasMorePage && FiltersCtx.productsFromSearch.length !== 0 && (
        <ViewMoreButton handleClick={FiltersCtx.handleLoadMore} />
      )}
    </div>
  );
};

export default AdvancedSearch;
