import React, { useState, useEffect } from "react";
import { FilterProductsContext } from "./FilterProductsContext";
import * as API from "../../../api/Api.js";
import { useHistory, useLocation } from "react-router-dom";
import { useResponsiveDimensions } from "../../hooks/useResponsiveDimensions";

const FilterProductsContextProvider = (props) => {
  const [clubData, setClubData] = useState({});
  const [clubLoading, setClubLoading] = useState(true);
  const [allRecommends, setAllRecommends] = useState([]);
  const [showLatestRecommend, setShowLatestRecommend] = useState(true);
  const [memberPicks, setMemberPicks] = useState([]);
  const [awardWinners, setAwardWinners] = useState([]);
  const [bulkCoffees, setBulkCoffees] = useState([]);
  const [limitedOfferings, setLimitedOfferings] = useState([]);
  const [top25, setTop25] = useState([]);
  const [products, setProducts] = useState([]);
  const [specials, setSpecials] = useState([]);
  const [productsFromSearch, setProductsFromSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePage, setHasMorePage] = useState(false);
  const [quizResult, setQuizResult] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [showMemberOfTheWeek, setShowMemberOfTheWeek] = useState(true);
  const isMobile = useResponsiveDimensions().isMobile;
  const pathname = location.pathname;
  let search = history.location.search;
  let searchParams = new URLSearchParams(location.search);

  const [selectedFilter, setSelectedFilter] = useState();
  const [selectedRoaster, setSelectedRoaster] = useState();
  const [selectedRoast, setSelectedRoast] = useState();
  const [selectedType, setSelectedType] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState();
  const pathnamesArray = pathname.split("/");

  useEffect(() => {
    getInitialProducts();
  }, [selectedRoast, selectedType, selectedRegion, selectedRoaster]);

  // Shop products will be called just on init of this context
  useEffect(() => {
    getShopProducts();
  }, []);

  useEffect(() => {
    API.getSpecials().then((res) => {
      if (res.data) {
        setIsLoading(false);
        setSpecials(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith("/quiz")) {
      fetchQuizPreferences();
    }
  }, []);

  const fetchQuizPreferences = () => {
    API.getQuizPreferences()
      .then((res) => {
        setQuizResult(res.data);
      })
      .catch((err) => {
        if (err) {
          setQuizResult([]);
        }
      });
  };

  useEffect(() => {
    if (pathname.startsWith("q")) {
      getProductsFromSearch();
    }

    if (search.startsWith("?q") && pathname.startsWith("/shop")) {
      onClear();
    }
    if (
      search.startsWith("?coffee-know-how") &&
      !pathname.startsWith("/quiz")
    ) {
      onClear();
    }

    if (search && !location.pathname.startsWith("/pay-as")) {
      getProductsFromSearch();

      return;
    }
  }, [pathname]);

  useEffect(() => {
    if (location.pathname.startsWith("/search")) {
      getInitialProducts();
    }
  }, [location.search]);

  const onClear = () => {
    history.push(`/${pathnamesArray[1]}`);
    setSelectedFilter({});
    setSelectedRoaster();
    setSelectedType([]);
    setSelectedRoast();
    setSelectedRegion();
    getInitialProducts();
  };

  const getClubs = () => {
    const buzz = searchParams.get("buzz");
    const brew = searchParams.get("brew");
    const taste = searchParams.get("taste");
    const roast = searchParams.get("roast");

    if (buzz === "decaffeinated") {
      API.getCoffeeClub(`?buzz=${buzz}`).then((res) => {
        setClubData(res.data[0]);
        setClubLoading(false);
      });
      return;
    }
    if (brew === "moka-pot" || brew === "espresso") {
      API.getCoffeeClub(`?brew=${brew}`).then((res) => {
        setClubData(res.data[0]);
        setClubLoading(false);
      });
      return;
    }

    API.getCoffeeClub(
      `?brew=${brew}&taste=${taste}&buzz=${buzz}&roast=${roast}`
    ).then((res) => {
      setClubData(res.data[0]);
      setClubLoading(false);
    });
  };

  const getShopProducts = () => {
    let idTypeArray = selectedType
      ? selectedType?.map((item) => item.id).toString()
      : "";
    setIsLoading(true);
    API.getProductsFilter(
      selectedRoast?.id,
      idTypeArray,
      selectedRegion?.id,
      selectedRoaster?.id
    ).then((res) => {
      setProducts(res.data);
      setHasMorePage(res.pagination?.has_more);
      setCurrentPage(res.pagination?.current_page);

      setIsLoading(false);
    });
  };

  const getProductsFromSearch = () => {
    let coffeeQuery = searchParams?.get("q");
    let coffeeType1 = searchParams?.get("coffee_type_id");
    let coffeeType2 = searchParams?.get("coffee_type_id2");
    let coffeeType3 = searchParams?.get("coffee_type_id3");
    let coffeeByRoast = searchParams?.get("coffee_roast_id");
    let coffeeByRegion = searchParams?.get("coffee_region_id");
    let coffeeByNotes = searchParams?.get("coffee_note_id");
    let coffeeByAcidity = searchParams?.get("coffee_acidity_id");
    let coffeeByBody = searchParams?.get("coffee_body_id");
    let coffeeByPurveyor = searchParams?.get("purveyor_id");
    let coffeeByBagSize = searchParams?.get("bulk_coffee");

    let newSearch = `?${coffeeQuery ? `q=${coffeeQuery}` : ""}${
      coffeeType2 || coffeeType1 || coffeeType3
        ? `&coffee_type_id=${coffeeType1 ? coffeeType1 : ""}${
            coffeeType2 ? `${coffeeType1 ? `,` : ""}${coffeeType2}` : ""
          }${
            coffeeType3
              ? `${coffeeType2 || coffeeType1 ? `,` : ""}${coffeeType3}`
              : ""
          }`
        : ""
    }${coffeeByRoast ? `&coffee_roast_id=${coffeeByRoast}` : ""}${
      coffeeByRegion ? `&coffee_region_id=${coffeeByRegion}` : ""
    }${coffeeByNotes ? `&coffee_note_id=${coffeeByNotes}` : ""}${
      coffeeByAcidity ? `&coffee_acidity_id=${coffeeByAcidity}` : ""
    }${coffeeByBody ? `&coffee_body_id=${coffeeByBody}` : ""}${
      coffeeByPurveyor ? `&purveyor_id=${coffeeByPurveyor}` : ""
    }${coffeeByBagSize ? `&bulk_coffee=${coffeeByBagSize}` : ""}`.trim();
    API.getProductsFilter("search", newSearch).then((res) => {
      setProductsFromSearch(res.data);
      setHasMorePage(res.pagination?.has_more);
      setCurrentPage(res.pagination?.current_page);

      setIsLoading(false);
    });
  };

  const getInitialProducts = () => {
    if (pathname.startsWith("/search")) {
      let coffeeQuery = searchParams?.get("q");
      let coffeeType1 = searchParams?.get("coffee_type_id");
      let coffeeType2 = searchParams?.get("coffee_type_id2");
      let coffeeType3 = searchParams?.get("coffee_type_id3");
      let coffeeByRoast = searchParams?.get("coffee_roast_id");
      let coffeeByRegion = searchParams?.get("coffee_region_id");
      let coffeeByNotes = searchParams?.get("coffee_note_id");
      let coffeeByAcidity = searchParams?.get("coffee_acidity_id");
      let coffeeByBody = searchParams?.get("coffee_body_id");
      let coffeeByPurveyor = searchParams?.get("purveyor_id");
      let coffeeByBagSize = searchParams?.get("bulk_coffee");

      let newSearch = `?${coffeeQuery ? `q=${coffeeQuery}` : ""}${
        coffeeType2 || coffeeType1 || coffeeType3
          ? `&coffee_type_id=${coffeeType1 ? coffeeType1 : ""}${
              coffeeType2 ? `${coffeeType1 ? `,` : ""}${coffeeType2}` : ""
            }${
              coffeeType3
                ? `${coffeeType2 || coffeeType1 ? `,` : ""}${coffeeType3}`
                : ""
            }`
          : ""
      }${coffeeByRoast ? `&coffee_roast_id=${coffeeByRoast}` : ""}${
        coffeeByRegion ? `&coffee_region_id=${coffeeByRegion}` : ""
      }${coffeeByNotes ? `&coffee_note_id=${coffeeByNotes}` : ""}${
        coffeeByAcidity ? `&coffee_acidity_id=${coffeeByAcidity}` : ""
      }${coffeeByBody ? `&coffee_body_id=${coffeeByBody}` : ""}${
        coffeeByPurveyor ? `&purveyor_id=${coffeeByPurveyor}` : ""
      }${coffeeByBagSize ? `&bulk_coffee=${coffeeByBagSize}` : ""}`.trim();
      API.getProductsFilter("search", newSearch).then((res) => {
        setProductsFromSearch(res.data);
        setHasMorePage(res.pagination?.has_more);
        setCurrentPage(res.pagination?.current_page);

        setIsLoading(false);
      });
    }

    if (pathname.startsWith("/shop")) {
      let idTypeArray = selectedType
        ? selectedType?.map((item) => item.id).toString()
        : "";
      API.getProductsFilter(
        selectedRoast?.id,
        idTypeArray,
        selectedRegion?.id,
        selectedRoaster?.id
      ).then((res) => {
        //@TODO added this line - to fix problem when roasters was mixed (not tested) .
        setProducts([]);
        setProducts(res.data);
        setHasMorePage(res.pagination?.has_more);
        setCurrentPage(res.pagination?.current_page);
        setIsLoading(false);
      });
      return;
    }
    //static peaberry-recommends URL for SEO
    if (pathname.startsWith("/peaberry-recommends")) {
      if (
        pathname.startsWith("/peaberry-recommends") &&
        pathnamesArray.length > 2
      ) {
        setShowLatestRecommend(false);
      } else {
        setShowLatestRecommend(true);
      }
      let idTypeArray = selectedType
        ? selectedType?.map((item) => item.id).toString()
        : "";
      API.getRecommendsByFilter(
        selectedRoast?.id,
        idTypeArray,
        selectedRegion?.id
      ).then((res) => {
        setAllRecommends(res.data);
        setHasMorePage(res.pagination?.has_more);
        setCurrentPage(res.pagination?.current_page);

        if (res.data) {
          setIsLoading(false);
        }
      });
      return;
    }

    //static award-winners URL for SEO
    if (pathname.startsWith("/award-winners")) {
      let idTypeArray = selectedType
        ? selectedType?.map((item) => item.id).toString()
        : "";
      API.getAwardWinnersByFilter(
        selectedRoast?.id,
        idTypeArray,
        selectedRegion?.id,
        selectedRoaster?.id
      ).then((res) => {
        setAwardWinners(res.data);
        setHasMorePage(res.pagination?.has_more);
        setCurrentPage(res.pagination?.current_page);

        if (res.data.length !== 0) {
          setIsLoading(false);
        }
      });
      return;
    }
    if (pathname.startsWith("/top25")) {
      let idTypeArray = selectedType
        ? selectedType?.map((item) => item.id).toString()
        : "";
      setIsLoading(true);
      API.getTop25ByFilter(
        selectedRoast?.id,
        idTypeArray,
        selectedRegion?.id,
        selectedRoaster?.id
      ).then((res) => {
        setTop25(res.data);
        setHasMorePage(res.pagination?.has_more);
        setCurrentPage(res.pagination?.current_page);

        setIsLoading(false);
      });
      return;
    }

    if (pathname.startsWith("/member-picks")) {
      if (pathname.startsWith("/member-picks") && pathnamesArray.length > 2) {
        setShowMemberOfTheWeek(false);
      } else {
        setShowMemberOfTheWeek(true);
      }
      let idTypeArray = selectedType
        ? selectedType?.map((item) => item.id).toString()
        : "";
      API.getMemberPicksByFilter(
        selectedRoast?.id,
        idTypeArray,
        selectedRegion?.id
      ).then((res) => {
        setMemberPicks([]);
        setMemberPicks(res.data);
        setHasMorePage(res.pagination?.has_more);
        setCurrentPage(res.pagination?.current_page);

        if (res.data) {
          setIsLoading(false);
        }
      });

      return;
    }
    if (pathname.startsWith("/bulk-coffees")) {
      let idTypeArray = selectedType
        ? selectedType?.map((item) => item.id).toString()
        : "";
      setIsLoading(true);
      API.getBulkCoffeesByFilter(
        selectedRoast?.id,
        idTypeArray,
        selectedRegion?.id,
        selectedRoaster?.id
      ).then((res) => {
        setBulkCoffees([]);
        setBulkCoffees(res.data);
        setHasMorePage(res.pagination?.has_more);
        setCurrentPage(res.pagination?.current_page);

        setIsLoading(false);
      });

      return;
    }
    if (pathname.startsWith("/limited-offerings")) {
      let idTypeArray = selectedType
        ? selectedType?.map((item) => item.id).toString()
        : "";
      setIsLoading(true);
      API.getLimitedOfferingsByFilter(
        selectedRoast?.id,
        idTypeArray,
        selectedRegion?.id,
        selectedRoaster?.id
      ).then((res) => {
        setLimitedOfferings([]);
        setLimitedOfferings(res.data);
        setHasMorePage(res.pagination?.has_more);
        setCurrentPage(res.pagination?.current_page);

        setIsLoading(false);
      });
      return;
    }
  };

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => {
      return prevPage + 1;
    });

    if (pathname.startsWith("/shop")) {
      let idTypeArray = selectedType
        ? selectedType?.map((item) => item.id).toString()
        : "";
      API.getProductsFilter(
        selectedRoast?.id,
        idTypeArray,
        selectedRegion?.id,
        selectedRoaster?.id,
        currentPage + 1
      ).then((res) => {
        setProducts((products) => products.concat(res.data));
        setHasMorePage(res.pagination?.has_more);
      });
      return;
    }

    if (pathname.startsWith("/search")) {
      let coffeeQuery = searchParams?.get("q");
      let coffeeType1 = searchParams?.get("coffee_type_id");
      let coffeeType2 = searchParams?.get("coffee_type_id2");
      let coffeeType3 = searchParams?.get("coffee_type_id3");
      let coffeeByRoast = searchParams?.get("coffee_roast_id");
      let coffeeByRegion = searchParams?.get("coffee_region_id");
      let coffeeByNotes = searchParams?.get("coffee_note_id");
      let coffeeByAcidity = searchParams?.get("coffee_acidity_id");
      let coffeeByBody = searchParams?.get("coffee_body_id");
      let coffeeByPurveyor = searchParams?.get("purveyor_id");
      let coffeeByBagSize = searchParams?.get("bulk_coffee");

      let newSearch = `?${coffeeQuery ? `q=${coffeeQuery}` : ""}${
        coffeeType2 || coffeeType1 || coffeeType3
          ? `&coffee_type_id=${coffeeType1 ? coffeeType1 : ""}${
              coffeeType2 ? `${coffeeType1 ? `,` : ""}${coffeeType2}` : ""
            }${
              coffeeType3
                ? `${coffeeType2 || coffeeType1 ? `,` : ""}${coffeeType3}`
                : ""
            }`
          : ""
      }${coffeeByRoast ? `&coffee_roast_id=${coffeeByRoast}` : ""}${
        coffeeByRegion ? `&coffee_region_id=${coffeeByRegion}` : ""
      }${coffeeByNotes ? `&coffee_note_id=${coffeeByNotes}` : ""}${
        coffeeByAcidity ? `&coffee_acidity_id=${coffeeByAcidity}` : ""
      }${coffeeByBody ? `&coffee_body_id=${coffeeByBody}` : ""}${
        coffeeByPurveyor ? `&purveyor_id=${coffeeByPurveyor}` : ""
      }${coffeeByBagSize ? `&bulk_coffee=${coffeeByBagSize}` : ""}`.trim();
      API.getProductsFilter("search", newSearch, currentPage + 1).then(
        (res) => {
          setProductsFromSearch((products) => products.concat(res.data));
          setHasMorePage(res.pagination?.has_more);
        }
      );
      return;
    }

    if (pathname.startsWith("/peaberry-recommends")) {
      let idTypeArray = selectedType
        ? selectedType?.map((item) => item.id).toString()
        : "";
      API.getRecommendsByFilter(
        selectedRoast?.id,
        idTypeArray,
        selectedRegion?.id,
        currentPage + 1
      ).then((res) => {
        setAllRecommends((memberPicks) => memberPicks.concat(res.data));
        setHasMorePage(res.pagination?.has_more);
      });
      return;
    }

    if (pathname.startsWith("/award-winners")) {
      let idTypeArray = selectedType
        ? selectedType?.map((item) => item.id).toString()
        : "";
      API.getAwardWinnersByFilter(
        selectedRoast?.id,
        idTypeArray,
        selectedRegion?.id,
        selectedRoaster?.id,
        currentPage + 1
      ).then((res) => {
        setAwardWinners((items) => items.concat(res.data));
        setHasMorePage(res.pagination?.has_more);
      });
      return;
    }
    if (pathname.startsWith("/bulk-coffees")) {
      let idTypeArray = selectedType
        ? selectedType?.map((item) => item.id).toString()
        : "";
      API.getBulkCoffeesByFilter(
        selectedRoast?.id,
        idTypeArray,
        selectedRegion?.id,
        selectedRoaster?.id,
        currentPage + 1
      ).then((res) => {
        setBulkCoffees((item) => item.concat(res.data));
        setHasMorePage(res.pagination?.has_more);
      });

      return;
    }

    if (pathname.startsWith("/limited-offerings")) {
      let idTypeArray = selectedType
        ? selectedType?.map((item) => item.id).toString()
        : "";
      API.getLimitedOfferingsByFilter(
        selectedRoast?.id,
        idTypeArray,
        selectedRegion?.id,
        selectedRoaster?.id,
        currentPage + 1
      ).then((res) => {
        setLimitedOfferings((item) => item.concat(res.data));
        setHasMorePage(res.pagination?.has_more);
      });
      return;
    }

    if (pathname.startsWith("/member-picks")) {
      let idTypeArray = selectedType
        ? selectedType?.map((item) => item.id).toString()
        : "";
      API.getMemberPicksByFilter(
        selectedRoast?.id,
        idTypeArray,
        selectedRegion?.id,
        currentPage + 1
      ).then((res) => {
        setMemberPicks((memberPicks) => memberPicks.concat(res.data));
        setHasMorePage(res.pagination?.has_more);
      });
    }
  };

  const changeSelectedItem = (value) => {
    setSelectedItem(value);
  };

  const scrollInto = (id) => {
    if (!isMobile) {
      setTimeout(() => {
        const el = document.getElementById(id);
        const offset = 100;
        window.scroll({
          top: el.offsetTop - 100,
          left: 0,
          behavior: "smooth",
        });
      }, 200);
    } else {
      const offset = 181;
      setTimeout(() => {
        const el = document.getElementById(id);

        window.scroll({
          top: el.offsetTop - offset,
          left: 0,
          behavior: "smooth",
        });
      }, 500);
    }
  };

  const context = {
    clubData,
    getClubs,
    onClear,
    products,
    handleLoadMore,
    hasMorePage,
    selectedItem,
    changeSelectedItem,
    isLoading,
    memberPicks,
    allRecommends,
    awardWinners,
    bulkCoffees,
    limitedOfferings,
    top25,
    showLatestRecommend,
    showMemberOfTheWeek,
    getProductsFromSearch,
    scrollInto,
    productsFromSearch,
    clubLoading,
    specials,
    getInitialProducts,
    quizResult,
    setQuizResult,
    fetchQuizPreferences,
    setSelectedFilter,
    setSelectedRoaster,
    setSelectedType,
    setSelectedRoast,
    setSelectedRegion,
    selectedRoaster,
    selectedType,
    selectedRoast,
    selectedRegion,
  };

  return (
    <FilterProductsContext.Provider value={context}>
      {props.children}
    </FilterProductsContext.Provider>
  );
};

export default FilterProductsContextProvider;
