import React, { useState } from "react";

import ModalWrapper from "../../Modals/ModalWrapper/ModalWrapper";
import Button from "../../shared/Button/Button";
import cs from "classnames";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";

import "./CoffeeTourModal.scss";

const CoffeeTourModal = (props) => {
  const [fullDecriptionActive, setFullDescriptionActive] = useState(false);

  const isMobile = useResponsiveDimensions().isMobile;

  const fullDecription = (
    <>
      <div style={{ marginBottom: "1rem" }}>{props.shortBio}</div>

      <p style={{ marginBottom: "1rem" }} className="CoffeeTourModal__tour-bio">
        {props.bio}
      </p>
    </>
  );

  return (
    <ModalWrapper closeIcon isOpen={props.isOpen} toggle={props.toggle}>
      <div className="CoffeeTourModal">
        <div className="CoffeeTourModal__tour-wrapper">
          <a
            href={`https://www.instagram.com/${props.igHandle}/`}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <img
              className="CoffeeTourModal__tastmaker-image"
              src={
                props?.image
                  ? props?.image
                  : "https://i.postimg.cc/rmCDZgBD/tastmaker1.jpg"
              }
              alt="subscription"
            />
          </a>
          <div>
            <a
              href={`https://www.instagram.com/${props.igHandle}/`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className="CoffeeTourModal__tastmaker-container">
                <h2 className="CoffeeTourModal__name">{props.name}</h2>
                <div className="CoffeeTourModal__tastmaker-container2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                  <span>{props.igHandle}</span>
                  {props.igFollowers && (
                    <div className="CoffeeTourModal__subtitle">
                      Followers: {props.igFollowers}
                    </div>
                  )}
                </div>
              </div>
            </a>

            <div
              className={cs(
                "CoffeeTourModal__description",
                fullDecriptionActive && "CoffeeTourModal__description--active"
              )}
            >
              {fullDecription}
              <span
                className={cs("CoffeeTourModal__dots")}
                onClick={() => setFullDescriptionActive(!fullDecriptionActive)}
              >
                {!fullDecriptionActive ? "Read more" : "Read less"}
              </span>
            </div>
          </div>
        </div>
        <iframe
          width={isMobile ? "300" : "560"}
          height={isMobile ? "240" : "315"}
          src={`https://www.youtube.com/embed/${props?.youtubeVideo}`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <div className="CoffeeTourModal__tour-container">
          <h2 className="CoffeeTourModal__tour-title">{props.tourName}</h2>
          {props.tourShortMessage && (
            <p className="CoffeeTourModal__tour-subtitle">
              {props.tourShortMessage}
            </p>
          )}
          <p className="CoffeeTourModal__tour-description">
            {props.tourDetail}
          </p>
          <Button
            onClick={() => {
              props.onTourSelect();
              props.toggle();
            }}
            className="Button CoffeeTourModal__button"
            label="ORDER NOW"
            spinner
            color={"#ffffff"}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default CoffeeTourModal;
