import React from "react";

import "./Page404.scss";

import LinkButton from "../../components/shared/LinkButton/LinkButton";

const Page404 = () => {
  const Logo =
    "https://assets.gocoffeego.com/v2/assets/images/peaberry-hot-air-ballon.svg";

  return (
    <div className="Page404">
      <div className="Page404__logo">
        <img className="Page404__logo-image" src={Logo} alt="logo" />
      </div>
      <div className="Page404__not-found">
        <h1 className="Page404__title"> 404 </h1>
        <h2 className="Page404__title">PAGE NOT FOUND </h2>
        <LinkButton
          className="Page404__button"
          label="BACK TO SHOPPING"
          path="/shop"
        />
      </div>
    </div>
  );
};

export default Page404;
