import React, { useEffect, useState } from "react";

import MiddleLine from "../../shared/MiddleLine/MiddleLine";

import ShipmentCard from "./ShipmentCard/ShipmentCard";

import FlickitySlider from "../../FlickitySlider/FlickitySlider";
import Brand from "../../Home/Brand/Brand";
import * as API from "../../../api/Api";

import "./CoffeeShipments.scss";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";

const CoffeeShipments = (props) => {
  const [coffePurveyors, setCoffePurveyors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useResponsiveDimensions().isMobile;

  useEffect(() => {
    API.getCoffeePurveyors().then((res) => {
      const data = res.data.sort(() => Math.random() - 0.5);
      setCoffePurveyors(data);
      if (res.data) {
        setIsLoading(false);
      }
    });
  }, []);
  const { selectedSubscription } = props;

  return (
    <div className="CoffeeShipments">
      {/*    <div className="CoffeeShipments__slider">
        <FlickitySlider
          options={{
            autoPlay: true,
            draggable: false,
            freeScroll: false,
            contain: true,
            prevNextButtons: false,
            pageDots: false,
            wrapAround: true,
            groupCells: true,
          }}
        >
          {!isLoading &&
            coffePurveyors.map((item) => (
              <Brand
                key={item.id}
                id={item.id}
                // style={{ width: "100%" }}
                style={isMobile ? { width: "100%" } : { width: "18.5%" }}
                name={item.slug}
                image={item.main_image.md}
              />
            ))}
        </FlickitySlider>
      </div> */}
      <h2 className="CoffeeShipments__roasters-title">
        A CURATED MONTHLY ASSORTMENT OF ROASTED-TO-ORDER COFFEES SHIPPED
        DIRECTLY TO YOU FROM EACH OF OUR SUPERSTAR ROASTERS!
      </h2>

      <MiddleLine className="CoffeeShipments__line" />
      <h2 className="CoffeeShipments__section-title">
        HOW MANY COFFEES PER SHIPMENT DO YOU WANT?
      </h2>
      <div className="CoffeeShipments__shipments">
        <div className="CoffeeShipments__shipment">
          <h1 className="CoffeeShipments__title">One Coffee Per Shipment</h1>
          <div className="CoffeeShipments__one">
            {selectedSubscription[0].one_coffee_plans.map((item) => (
              <ShipmentCard
                details={selectedSubscription[0].detail}
                image={selectedSubscription[0].image.image.image_url}
                id={item.id}
                months={item.months}
                price={item.price}
                coffeeShipmentQuantity="One"
                key={item?.id}
              />
            ))}
          </div>
        </div>

        <div className="CoffeeShipments__shipment">
          {selectedSubscription[0].two_coffee_plans.length > 0 && (
            <>
              <h2 className="CoffeeShipments__title">
                Two Coffees Per Shipment
              </h2>
              <p className="CoffeeShipments__description">
                Not the same coffees - each coffee will be different
              </p>
            </>
          )}
          <div className="CoffeeShipments__two">
            {selectedSubscription[0].two_coffee_plans.length > 0 &&
              selectedSubscription[0].two_coffee_plans.map((item) => (
                <ShipmentCard
                  image={selectedSubscription[0].image.image.image_url}
                  id={item.id}
                  months={item.months}
                  price={item.price}
                  coffeeShipmentQuantity="Two"
                  key={item?.id}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoffeeShipments;
