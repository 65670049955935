import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { COMPANIES } from "../../api/Companies";

import * as API from "../../api/Api";

import "./Footer.scss";

const Footer = () => {
  const Logo = "https://assets.gocoffeego.com/v2/assets/images/logo.png";
  const Facebook =
    "https://assets.gocoffeego.com/v2/assets/images/facebook.png";
  const Instagram =
    "https://assets.gocoffeego.com/v2/assets/images/instagram.png";
  const TikTok = "https://assets.gocoffeego.com/v2/assets/images/tik-tok.svg";
  const Pinterest =
    "https://assets.gocoffeego.com/v2/assets/images/pinterest.svg";
  const Linkedin =
    "https://assets.gocoffeego.com/v2/assets/images/linkedin.svg";
  const Twitter = "https://assets.gocoffeego.com/v2/assets/images/twitter.svg";
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [subscribeSuccess, setSubscribe] = useState(false);
  const [emailExist, setEmailExist] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const subscribeRef = useRef();
  const actualYear = new Date().getFullYear();

  const handleSubscribeClick = () => {
    setSubmitted(true);
    // history.push("/newsletter");

    if (searchKeyWord) {
      API.NewsletterSignUp(searchKeyWord)
        .then((res) => {
          if (res.data.email) {
            setSubscribe(true);
            setEmailExist(false);
            window.gtag("event", "conversion", {
              send_to: "AW-11011335274/PdKKCJmN9IAYEOrIzoIp",
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            if (err.response.data.error.message.email) {
              setEmailExist(true);
              setSubscribe(false);
            }
          }
        });
    }
    // localStorage.setItem("email-subscribe", subscribeRef.current.value);
  };

  const handleKeypress = (e) => {
    var key = e.keyCode || e.which;
    if (key === 13) {
      handleSubscribeClick();
    }
  };

  return (
    <div className="Footer">
      <div className="Footer__main-container">
        <div className="Footer__first-row">
          <div className="Logo">
            <img className="Logo__image" src={Logo} alt="footer-logo" />
            <p className="Logo__copyright">
              Copyright © 2008-{actualYear} GoCoffeeGo LLC. Est. All rights
              reserved.
            </p>
          </div>
          <div className="SubscribeFooter">
            <div className="SubscribeFooter__title font-cairo">
              Subscribe to the Peaberry Times Newsletter
            </div>
            <div className="SubscribeFooter__input-container">
              <input
                ref={subscribeRef}
                placeholder="YOUR EMAIL"
                type="text"
                className="SubscribeFooter__input"
                onChange={(e) => setSearchKeyWord(e.target.value)}
                onKeyPress={(e) => handleKeypress(e)}
              />
              <div
                className="SubscribeFooter__input-icon"
                onClick={handleSubscribeClick}
              />
            </div>
            {subscribeSuccess && (
              <>
                <div className="SubscribeFooter__success-subscription font-cairo">
                  Thank your for your subscription.
                </div>
              </>
            )}

            {emailExist && (
              <div className="SubscribeFooter__error-subscription font-cairo">
                The email has already been taken
              </div>
            )}

            {!searchKeyWord && submitted && (
              <div className="SubscribeFooter__error-subscription font-cairo">
                Please provide an email!
              </div>
            )}
          </div>
          <div className="Info">
            <div>
              <Link to="/blog" className="Info__text">
                <p>Blog</p>
              </Link>
              <Link to="/videos" className="Info__text">
                <p>Videos</p>
              </Link>
              <Link to="/about-us" className="Info__text">
                <p>About us</p>
              </Link>
            </div>
            <div>
              <Link to="/help" className="Info__text">
                <p> Help</p>
              </Link>
              <Link to="/contact-us" className="Info__text">
                <p> Contact us </p>
              </Link>
              <Link to="/privacy-policy" className="Info__text">
                <p> Privacy policy </p>
              </Link>
            </div>
          </div>
          <div className="Social">
            <a
              href="https://www.facebook.com/GoCoffeeGo"
              target="_blank"
              rel="noreferrer"
              className="Social__link"
            >
              <img
                src={Facebook}
                alt="facebook icon"
                className="Social__link-image"
              />
            </a>
            <a
              href="https://www.instagram.com/drinkgocoffeego/"
              target="_blank"
              rel="noreferrer"
              className="Social__link"
            >
              <img
                src={Instagram}
                alt="instagram icon"
                className="Social__link-image"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/gocoffeego/about/"
              target="_blank"
              rel="noreferrer"
              className="Social__link"
            >
              <img
                src={Linkedin}
                alt="linkedin icon"
                className="Social__link-image"
              />
            </a>

            <a
              href="https://twitter.com/GoCoffeeGo"
              target="_blank"
              rel="noreferrer"
              className="Social__link"
            >
              <img
                src={Twitter}
                alt="twitter icon"
                className="Social__link-image"
              />
            </a>
            <a
              href="https://www.pinterest.com/gocoffeego"
              target="_blank"
              rel="noreferrer"
              className="Social__link"
            >
              <img
                src={Pinterest}
                alt="pinterest icon"
                className="Social__link-image"
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              className="Social__link isDisabled"
            >
              <img
                src={TikTok}
                alt="tiktok icon"
                className="Social__link-image"
              />
            </a>
          </div>
        </div>
        <div className="Companies">
          <div className="Companies__title font-cairo"> SEEN IN </div>
          <div className="Companies__line" />
          <div className="Companies__items">
            {COMPANIES.map((item, index) => (
              <div key={index} className="Companies__item">
                <img className="Companies__image" src={item.image} alt="logo" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
