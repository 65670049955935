import React, { useEffect, useState } from "react";
import * as API from "../../api/Api";
import RoastersItem from "../../components/Roasters/RoastersItem/RoastersItem";
import Line from "../../components/shared/Line/Line";
import MiddleLine from "../../components/shared/MiddleLine/MiddleLine";
import ViewMoreButton from "../../components/shared/ViewButton/ViewMoreButton";
import RoasterLoader from "../../components/Loaders/RoasterLoader/RoasterLoader";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";

import "./Roasters.scss";

const Roasters = () => {
  const [roasters, setRoasters] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const seoCtx = useSeoContext();

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url === "/roasters/all");

  useEffect(() => {
    API.getCoffeePurveyors()
      .then((res) => {
        setRoasters(res.data);
        if (res.data) {
          setIsLoading(false);
        }
        setHasMore(res?.pagination?.has_more);
      })
      .catch((err) => console.log(err, "err"));
  }, []);

  const handleViewMore = () => {
    API.getCoffeePurveyorsPerPage(currentPage + 1).then((res) => {
      setRoasters((roaster) => roaster.concat(res.data));
      setCurrentPage(currentPage + 1);
      setHasMore(res?.pagination?.has_more);
    });
  };

  return (
    <div className="Roasters">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <Line />
      <Line />
      <div className="Roasters__main-container">
        <div className="Roasters__title-wrap">
          <MiddleLine className="Roasters__middle-line" />
          <h1 className="Roasters__title">ROASTERS</h1>
        </div>
        <div className="Roasters__container">
          {isLoading ? (
            <>
              {Array(16)
                .fill(0)
                .map((item) => (
                  <RoasterLoader />
                ))}
            </>
          ) : (
            <>
              {roasters?.map((roaster) => (
                <RoastersItem key={roaster.id} roaster={roaster} />
              ))}
            </>
          )}
        </div>
        {hasMore && (
          <ViewMoreButton
            className="Roaster__button"
            handleClick={handleViewMore}
          />
        )}
      </div>
    </div>
  );
};

export default Roasters;
