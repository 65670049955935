import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import "./Tracking.scss";

import * as API from "../../api/Api";
import MiddleLine from "../../components/shared/MiddleLine/MiddleLine";
import Line from "../../components/shared/Line/Line";

const Tracking = () => {
  const [isLoading, setIsLoading] = useState();
  const [trackingData, setTrackingData] = useState();
  const params = useParams();
  const trackingNumber = params.pathParam;

  useEffect(() => {
    setIsLoading(true);
    API.getOrderTracking(trackingNumber).then((res) => {
      console.log(res.data, "res!!!");
      if (res.data) {
        setIsLoading(false);
      }
      setTrackingData(res.data);
    });
  }, []);

  return (
    <div className="Tracking">
      <Line />
      <Line />

      <div className="Orders__title-wrap">
        <MiddleLine />
        <h1 className="Orders__title">Track Shipment</h1>
      </div>

      <div className="Tracking__container">
        <h2 className="Tracking__title">
          Order #: <Link to={`/order/${trackingData?.order_id}`}>{trackingData?.order_number}</Link>
        </h2>
        <h2 className="Tracking__title">
          Order Status: {trackingData?.order_status}
        </h2>
        <h2 className="Tracking__title">
          Tracking #: {trackingData?.tracking_number}
        </h2>
        {trackingData?.estimated_delivery_date && (
          <h2 className="Tracking__title">
            Estimated Delivery Date: {trackingData?.tracking_number}
          </h2>
        )}

        {trackingData?.activities.length > 0 ? (
          <div className="Tracking__activities">
            {trackingData?.activities?.map((tracking, index) => (
              <div className="Activity">
                <h2 className="Activity__title">
                  {" "}
                  Activity {trackingData.activities.length - index}{" "}
                </h2>

                <div className="Activity__details">
                  <p className="Activity__text"> Date: {tracking?.timestamp}</p>
                  {tracking?.status_msg && (
                  <p className="Activity__text">
                    {" "}
                    Status: {tracking?.status_msg}
                  </p>
                  )}
                  {tracking?.signed_for_by_name && (
                  <p className="Activity__text">
                    {" "}
                    Signed For By: {tracking?.signed_for_by_name}
                  </p>
                  )}
                  {tracking?.city && (
                  <p className="Activity__text">
                    {" "}
                    City: {tracking?.city}{" "}
                  </p>
                  )}
                  {tracking?.state && (
                  <p className="Activity__text">
                    {" "}
                    State: {tracking?.state}
                  </p>
                  )}
                  {tracking?.zip && (
                  <p className="Activity__text">
                    {" "}
                    Postal Code: {tracking?.zip}
                  </p>
                  )}
                  {tracking?.country && tracking?.country !== 'US' && (
                    <p className="Activity__text">
                      {" "}
                      Country: {tracking?.country}
                    </p>
                  )}
                </div>
              </div>
            ))}
            ;
          </div>
        ) :
          <div className="Activity">
            <h2 className="Activity__title">
              {" "}
              Activity {" "}
            </h2>
          </div> }
      </div>
    </div>
  );
};

export default Tracking;
