import React, { useState, useEffect } from "react";
import MiddleLine from "../../shared/MiddleLine/MiddleLine";
import RecommendationItem from "../RecommendationItem/RecommendationItem";
import Line from "../../shared/Line/Line";
import * as API from "../../../api/Api";

import "./BrilliantRecommendations.scss";

const BrilliantRecommendations = () => {
  const [BrilliantRecommendations, setBrilliantRecommendations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    API.getBrilliantRecommendations().then((res) => {
      setBrilliantRecommendations(res.data);
      if (res.data) {
        setIsLoading(false);
      }
    });
  }, []);
  const GirlInDot =
    "https://assets.gocoffeego.com/v2/assets/images/Girl-in-dot.png";
  const Circle = "https://assets.gocoffeego.com/v2/assets/images/circle-2.png";
  const YellowCircle =
    "https://assets.gocoffeego.com/v2/assets/images/cirlce-yellow.svg";

  return (
    <div className="BrilliantRecommendations">
      <div className="BrilliantRecommendations__shape" />

      <div className="BrilliantRecommendations__content">
        <img
          className="BrilliantRecommendations__girl-in-dot"
          src={GirlInDot}
          alt="girl in dot"
        />
        <MiddleLine style={{ background: "white" }} />
        <h2 className="BrilliantRecommendations__content-title">
          BRILLIANT RECOMMENDATIONS
        </h2>
        <div className="BrilliantRecommendations__items">
          {!isLoading &&
            BrilliantRecommendations.map((item) => (
              <RecommendationItem
                key={item.id}
                image={item.image}
                title={item.title}
                category={item.category}
                categoryId={item.category_id}
                item={item}
              />
            ))}
          <img
            src={YellowCircle}
            alt="yellow"
            className="BrilliantRecommendations__yellow-circle"
          />
        </div>
      </div>
      <div className="BrilliantRecommendations__line">
        <Line />
        <Line />
        <img
          className="BrilliantRecommendations__circle"
          src={Circle}
          alt="circle"
        />
      </div>
    </div>
  );
};

export default BrilliantRecommendations;
