import React from "react";

import Line from "../../shared/Line/Line";
import MiddleLine from "../../shared/MiddleLine/MiddleLine";
import Button from "../../shared/Button/Button";

import "./SubscribeSection.scss";
import LinkButton from "../../shared/LinkButton/LinkButton";

const SubscribeSection = () => {
  const BoyYelling =
    "https://assets.gocoffeego.com/v2/assets/images/boy-yelling.png";
  const YellowDot =
    "https://assets.gocoffeego.com/v2/assets/images/yellow-dot-2.svg";
  return (
    <div className="SubscribeSection">
      <Line />
      <Line />
      <div className="SubscribeSection__content">
        <h2 className="SubscribeSection__title1">SUBSCRIBE</h2>
        <MiddleLine style={{ background: "white" }} />
        <h2 className="SubscribeSection__title2">
          A COFFEE CLUB WORTH THE BUZZ
        </h2>
        <p className="SubscribeSection__description">
          Curated coffees personalized to your preferences and shipped
          direct-to-you from award-winning roasters worldwide. What’s not to
          love?
        </p>
        <LinkButton
          path="/subscribe"
          className="SubscribeSection__button"
          label="GET STARTED"
        />
        <img
          className="SubscribeSection__image"
          src={BoyYelling}
          alt="boy-yelling"
        />
        <img
          className="SubscribeSection__yellow-dot"
          src={YellowDot}
          alt="boy-yelling"
        />
      </div>
      <Line />
      <Line />
    </div>
  );
};

export default SubscribeSection;
