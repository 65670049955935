import Home from "../pages/Home/Home";
import Account from "../pages/Account/Account";
import Gift from "../pages/Gift/Gift";
import Learn from "../pages/Learn/Learn";
import Shop from "../pages/Shop/Shop";
import Subscribe from "../pages/Subscribe/Subscribe";
import Product from "../pages/Product/ProductPage";
import Quiz from "../pages/Quiz/Quiz";
import Orders from "../pages/Orders/Orders";
import Recipe from "../components/Learn/Recipe/Recipe";
import Cart from "../pages/Cart/Cart";
import Checkout from "../pages/Checkout/Checkout";
import AddressBook from "../components/Account/AddressBook/AddressBook";
import MyCoffeeClubs from "../components/Account/MyCoffeeClubs/MyCoffeeClubs";
import Login from "../pages/Login/Login";
import SignUp from "../pages/SignUp/SignUp";
import CheckBalance from "../components/Account/CheckBalance/CheckBalance";
import RedeemCard from "../components/Account/RedeemCard/RedeemCard";
import MyCreditCards from "../components/Account/MyCreditCards/MyCreditCards";
import ChangeInfo from "../components/Account/ChangeInfo/ChangeInfo";
import PayAsYouGo from "../pages/PayAsYouGo/PayAsYouGo";
import AboutUs from "../pages/AboutUs/AboutUs";
import EmailPreferences from "../components/Account/EmailPreferences/EmailPreferences";
import MemberPicks from "../pages/MemberPicks/MemberPicks";
import PeaberryRecommends from "../pages/PeaberryRecommends/PeaberryRecommends";
import CorporateGift from "../pages/CorporateGift/CorporateGift";
import PrePaid from "../pages/PrePaid/PrePaid";
import MyGiftSubscriptions from "../components/Account/MyGiftSubscriptions/MyGiftSubscriptions";
import Reviews from "../pages/Reviews/Reviews";
import GiftCertificates from "../pages/GiftCertificates/GiftCertificates";
import FAQ from "../pages/FAQ/FAQ";
import ForgotPassword from "../pages/ForgotPasswordForm/ForgotPassword";
import NewPassword from "../pages/NewPassword/NewPassword";
import Newsletter from "../pages/Newsletter/Newsletter";
import Roasters from "../pages/Roasters/Roasters";
import AdvancedSearch from "../pages/AdvancedSearch/AdvancedSearch";
import AssignedClub from "../pages/AssignedClub/AssignedClub";
import AutoShip from "../pages/AutoShip/AutoShip";
import Purveyors from "../components/Roasters/Purveyors/Purveyors";
import AwardWinners from "../pages/AwardWinners/AwardWinners";
import Page404 from "../pages/404/Page404";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import OrderSuccess from "../pages/OrderSuccess/OrderSuccess";
import JoinClub from "../pages/JoinClub/JoinClub";
import ContactUs from "../pages/ContactUs/ContactUs";
import AutoShipCheckout from "../pages/AutoShipCheckout/AutoShipCheckout";
import Music from "../pages/Music/Music";
import CoffeeReviews from "../components/Account/CoffeeReviews/CoffeeReviews";
import Order from "../pages/Order/Order";
import Videos from "../pages/Videos/Videos";
import Tracking from "../pages/Tracking/Tracking";
import Blog from "../pages/Blog/Blog";
import Post from "../pages/Post/Post";
import Help from "../pages/Help/Help";
import CustomerReviews from "../pages/CustomerReviews/CustomerReviews";
import ChannelVideos from "../pages/Videos/ChannelVideos/ChannelVideos";
import LimitedOfferings from "../pages/LimitedOfferings/LimitedOfferings";
import BulkCoffees from "../pages/BulkCoffees/BulkCoffees";
import Top25 from "../pages/Top25/Top25";
import Tea from "../pages/Tea/Tea";

export const AppRoutes = [
  //public routes
  {
    path: "/",
    exact: true,
    component: Home,
    name: "HOME",
  },
  {
    exact: true,
    path: "account",
    component: Account,
    name: "ACCOUNT",
    private: true,
  },
  {
    exact: true,
    path: "account/change-info",
    component: ChangeInfo,
    name: "CHANGEINFO",
    private: true,
  },
  {
    exact: true,
    path: "account/check-balance",
    component: CheckBalance,
    name: "CHECKBALANCE",
    private: true,
  },
  {
    exact: true,
    path: "account/address-book/:pathParam?",
    component: AddressBook,
    name: "ADDRESSBOOK",
    private: true,
  },
  {
    exact: true,
    path: "account/email-preferences",
    component: EmailPreferences,
    name: "EMAILPREFERENCES",
    private: true,
  },
  {
    exact: true,
    path: "account/my-reviews",
    component: CoffeeReviews,
    name: "Coffee Reviews",
    private: true,
  },
  {
    exact: true,
    path: "order-success/:pathParam",
    component: OrderSuccess,
    name: "Order Success",
    private: true,
  },

  {
    exact: true,
    path: "account/redeem",
    component: RedeemCard,
    name: "REDEEMCARD",
    private: true,
  },
  {
    exact: true,
    path: "account/coffee-club",
    component: MyCoffeeClubs,
    name: "MYCOFFEECLUBS",
    private: true,
  },
  {
    exact: true,
    path: "account/gift-subscriptions",
    component: MyGiftSubscriptions,
    name: "MY GIFT SUBSCRIPTIONS",
    private: true,
  },
  {
    exact: true,
    path: "account/credit-cards/:pathParam?",
    component: MyCreditCards,
    name: "MYCREDITCARDS",
    private: true,
  },
  {
    exact: true,
    path: "orders",
    component: Orders,
    name: "ORDERS",
    private: true,
  },
  {
    exact: true,
    path: "order/:pathParam1?",
    component: Order,
    name: "ORDER",
    private: true,
  },
  {
    exact: true,
    path: "gifts-for-coffee-lovers",
    component: Gift,
    name: "GIFT",
  },
  {
    exact: true,
    path: "corporate-gifts",
    component: CorporateGift,
    name: "CORPORATE GIFT",
  },
  {
    exact: true,
    path: "coffee-gift-certificates",
    component: GiftCertificates,
    name: "GIFT CERTIFICATES",
  },
  {
    exact: true,
    path: "coffee-faq",
    component: FAQ,
    name: "FAQ",
  },
  {
    exact: true,
    path:
      "award-winners/:pathParam?/:pathParam2?/:pathParam3?/:pathParam4?/:pathParam5?/:pathParam6?/:pathParam7?/:pathParam8?/:pathParam9?/:pathParam10?/",
    component: AwardWinners,
    name: "AWARD WINNERS",
  },
  {
    exact: true,
    path: "learn/:pathParam1/:pathParam2/:pathParam3?",
    component: Learn,
    name: "LEARN",
  },
  {
    exact: true,
    path: "recipe/:pathParam?",
    component: Recipe,
    name: "RECIPE",
  },
  {
    exact: true,
    path:
      "shop/:pathParam?/:pathParam2?/:pathParam3?/:pathParam4?/:pathParam5?/:pathParam6?/:pathParam7?/:pathParam8?/:pathParam9?/:pathParam10?/:pathParam11?/:pathParam12?",
    component: Shop,
    name: "SHOP",
  },
  {
    exact: true,
    path: "subscribe",
    component: Subscribe,
    name: "SUBSCRIBE",
  },
  {
    exact: true,
    path: "products/:pathParam?/:pathParam2?",
    component: Product,
    name: "PRODUCT",
  },
  {
    exact: true,
    path: "quiz",
    component: Quiz,
    name: "QUIZ",
    private: false,
  },
  {
    exact: true,
    path: "cart",
    component: Cart,
    name: "CART",
  },
  {
    exact: true,
    path: "checkout/:pathParam?",
    component: Checkout,
    name: "CHECKOUT",
    private: true,
  },
  {
    exact: true,
    path: "login/:pathParam?",
    component: Login,
    name: "LOGIN",
    restricted: true,
  },
  {
    exact: true,
    path: "sign-up",
    component: SignUp,
    name: "SIGNUP",
    restricted: true,
  },
  {
    exact: true,
    path: "pay-as-you-go",
    component: PayAsYouGo,
    name: "PAY AS YOU GO",
    private: false,
  },
  {
    exact: true,
    path: "coffee-gifts",
    component: PrePaid,
    name: "PRE PAID",
  },
  {
    exact: true,
    path: "about-us",
    component: AboutUs,
    name: "ABOUT US",
  },
  {
    exact: true,
    path:
      "member-picks/:pathParam?/:pathParam2?/:pathParam3?/:pathParam4?/:pathParam5?/:pathParam6?/",
    component: MemberPicks,
    name: "MEMBER PICKS",
  },
  {
    exact: true,
    path:
      "peaberry-recommends/:pathParam?/:pathParam2?/:pathParam3?/:pathParam4?/:pathParam5?/:pathParam6?/",
    component: PeaberryRecommends,
    name: "Peaberry Recommends",
  },
  {
    exact: true,
    path: "reviews/:pathParam?",
    component: Reviews,
    name: "Reviews",
  },
  {
    exact: true,
    path: "forgot-password",
    component: ForgotPassword,
    name: "Forgot Password",
  },
  {
    exact: true,
    path: "newsletter",
    component: Newsletter,
    name: "NEWSLETTER",
  },
  {
    exact: true,
    path: "reset-password/:pathParam?",
    component: NewPassword,
    name: "New Password",
  },
  {
    exact: false,
    path: "roasters",
    component: Roasters,
    name: "ROASTERS",
  },
  {
    exact: true,
    path: "roaster/:pathParam?",
    component: Purveyors,
    name: "PURVEYORS",
  },
  {
    exact: true,
    path: "search/:pathParam1?",
    component: AdvancedSearch,
    name: "ADVANCED SEARCH",
  },
  {
    path: "assigned-club/:pathParam?",
    component: AssignedClub,
    name: "Assigned Club",
  },
  {
    private: true,
    path: "join-club",
    component: JoinClub,
    name: "Join Club",
  },
  {
    exact: true,
    path: "auto-ship",
    component: AutoShip,
    name: "Auto Ship",
    private: false,
  },
  {
    exact: true,
    path: "auto-ship/checkout",
    component: AutoShipCheckout,
    name: "Auto Ship",
    private: true,
  },
  {
    exact: true,
    path: "privacy-policy",
    component: PrivacyPolicy,
    name: "Privacy Policy",
  },
  {
    exact: true,
    path: "404",
    component: Page404,
    name: "404",
  },
  {
    exact: true,
    path: "contact-us",
    component: ContactUs,
    name: "Contact Us",
  },
  {
    path: "music",
    exact: true,
    component: Music,
    name: "Music",
  },
  {
    path: "orders/tracking/:pathParam?",
    exact: true,
    component: Tracking,
    name: "Tracking",
    private: true,
  },
  {
    path: "videos",
    exact: true,
    component: Videos,
    name: "Videos",
  },

  {
    path: "blog",
    exact: true,
    component: Blog,
    name: "Blog",
  },
  {
    path: "blog/:pathParam?",
    exact: true,
    component: Post,
    name: "Post",
  },
  {
    path: "help",
    exact: true,
    component: Help,
    name: "Help",
  },
  {
    path: "channel-videos/:pathParam?",
    exact: true,
    component: ChannelVideos,
    name: "Channel Videos",
  },
  {
    path: "customer-reviews",
    exact: true,
    component: CustomerReviews,
    name: "Customer Reviews",
  },
  {
    path:
      "limited-offerings/:pathParam?/:pathParam2?/:pathParam3?/:pathParam4?/:pathParam5?/:pathParam6?/:pathParam7?/:pathParam8?/:pathParam9?/:pathParam10?/",
    exact: true,
    component: LimitedOfferings,
    name: "Limited Offerings",
  },
  {
    path:
      "bulk-coffees/:pathParam?/:pathParam2?/:pathParam3?/:pathParam4?/:pathParam5?/:pathParam6?/:pathParam7?/:pathParam8?/:pathParam9?/:pathParam10?/",
    exact: true,
    component: BulkCoffees,
    name: "Bulk Coffees",
  },
  {
    path:
      "top25/:pathParam?/:pathParam2?/:pathParam3?/:pathParam4?/:pathParam5?/:pathParam6?/:pathParam7?/:pathParam8?/:pathParam9?/:pathParam10?/",
    exact: true,
    component: Top25,
    name: "Top 25 Coffees",
  },
  {
    path: "tea",
    exact: true,
    component: Tea,
    name: "Tea",
  },
];
