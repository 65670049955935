import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Banner from "../../components/Shop/Banner/Banner";
import ViewButton from "../../components/shared/ViewButton/ViewMoreButton";
import Filter from "../../components/Shop/Filter/Filter";
import Product from "../../components/Shop/Product/Product";
import QuizCta from "../../components/Shop/QuizCta/QuizCta";
import Line from "../../components/shared/Line/Line";
import Coffe101 from "../../components/Coffe101/Coffe101";
import { useFilterProductsContext } from "../../lib/context/FilterProductsContext/FilterProductsContext";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import ProductLoader from "../../components/Loaders/ProductLoader/ProductLoader";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import Seo from "../../lib/helpers/SEO/Seo";

import "./Shop.scss";

const Shop = () => {
  const girls =
    "https://assets.gocoffeego.com/v2/assets/images/girls.svg";
  const FiltersCtx = useFilterProductsContext();
  const seoCtx = useSeoContext();

  const isLoading = FiltersCtx.isLoading;
  localStorage.removeItem("route_from");
  let specials = FiltersCtx.specials;
  const isMobile = useResponsiveDimensions().isMobile;

  const params = useParams();
  const pathParam1 = params.pathParam;
  const pathParam2 = params.pathParam2;

  const foundSeo = seoCtx.pageMetadata.find(
    (el) => el.url.toLowerCase() === `/search/${pathParam1}/${pathParam2}`
  );

  const foundShopSeo = seoCtx.pageMetadata.find(
    (el) => el.url.toLowerCase() === `/specials`
  );

  const seoLinks = seoCtx.pageMetadata.filter((el) =>
    el.url.startsWith("/search/coffee")
  );

  return (
    <div id="shop" className="Shop">
      {foundSeo && pathParam1 && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      {foundShopSeo && !pathParam1 && (
        <Seo
          title={foundShopSeo?.title}
          description={foundShopSeo?.description}
          keywords={foundShopSeo?.keywords}
        />
      )}
      {seoLinks &&
        seoLinks.map((item) => (
          <Link key={item?.id} to={item?.url.replace("search", "shop")} />
        ))}
      <div className="Shop__banner-grid">
        <div>{!isMobile && <Filter />}</div>

        <div>
          <div>
            {" "}
            {specials.length !== 0 && params.pathParam === undefined && (
              <Banner />
            )}
          </div>
          <div>{isMobile && <Filter />}</div>

          <div className="Shop__wrapper" id="Shop__wrapper">
            {isLoading ? (
              <div className="Shop__products-wrap">
                <div className="Shop__products">
                  {Array(12)
                    .fill(0)
                    .map((item, index) => (
                      <ProductLoader key={index} />
                    ))}
                </div>{" "}
              </div>
            ) : (
              <div id="shop-products">
                {FiltersCtx.products?.length !== 0 ? (
                  <div className="Shop__products-wrap">
                    {FiltersCtx.selectedRoaster?.short_message && (
                      <div className="Shop__roaster-message">
                        {FiltersCtx.selectedRoaster?.short_message}
                      </div>
                    )}
                    <div className="Shop__products">
                      {FiltersCtx.products?.map((product) => (
                        <div id="Product2" key={product.id}>
                          <Product product={product} isLoading={isLoading} />{" "}
                        </div>
                      ))}
                    </div>
                    {FiltersCtx.hasMorePage && (
                      <ViewButton
                        className="Shop__button"
                        handleClick={FiltersCtx.handleLoadMore}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    {!isLoading && FiltersCtx.products?.length === 0 && (
                      <div className="Shop__message">
                        No items for this category
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <img src={girls} alt="three girls" className="Shop__image" />
      <QuizCta />
      <Line />
      <Line />
      <Coffe101 showLines={false} />
    </div>
  );
};

export default Shop;
