import React from "react";
import "./CorporateGifts.scss";
import LinkButton from "../../shared/LinkButton/LinkButton";

const CorporateGifts = () => {
  const franchman =
    "https://assets.gocoffeego.com/v2/assets/images/franchman.svg";
  return (
    <div className="CorporateGifts">
      <div className="CorporateGifts__wrap">
        <div className="CorporateGifts__subtitle">Corporate Gifts</div>
        <h2 className="CorporateGifts__title">
          Lead the team to better mornings
        </h2>
        <LinkButton
          path={"/corporate-gifts"}
          className="CorporateGifts__btn"
          label="View corporate gifts"
        />
      </div>
      <div className="CorporateGifts__images">
        <div className="CorporateGifts__italian"></div>
        <img
          className="CorporateGifts__franchman"
          src={franchman}
          alt="franchman"
        />
        <div className="CorporateGifts__people-img"></div>
      </div>
    </div>
  );
};

export default CorporateGifts;
