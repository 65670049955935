import React from "react";

import ModalWrapper from "../ModalWrapper/ModalWrapper";

import "./SubscriptionDetailsModal.scss";

const SubscriptionDetailsModal = (props) => {
  const { name, shortMessage, detail } = props;

  return (
    <ModalWrapper closeIcon isOpen={props.isOpen} toggle={props.toggle}>
      <div className="SubscriptionDetailsModal">
        <h2 className="SubscriptionDetailsModal__title">{name}</h2>
        <h2 className="SubscriptionDetailsModal__title">{shortMessage}</h2>
        <p className="SubscriptionDetailsModal__description">{detail}</p>
      </div>
    </ModalWrapper>
  );
};

export default SubscriptionDetailsModal;
