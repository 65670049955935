import React, { useEffect, useState } from "react";
import * as API from "../../api/Api";
import "./Videos.scss";
import { Link } from "react-router-dom";

const Videos = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    API.getVideos().then((res) => {
      setVideos(res.data);
    });
  }, []);

  return (
    <div className="Videos">
      {videos?.map((video) => (
        <div className="Videos__modifier">
          <div className="Videos__wrap">
            <div className="Videos__header">
              {" "}
              <Link to={`/channel-videos/${video.slug}`}>
                <img
                  className="Videos__image"
                  src={video.tv_image}
                  alt="tv video"
                />

                <div className="Videos__channel">{video.tv_title}</div>
              </Link>
            </div>
            <div className="Videos__content">
              <Link to={`/channel-videos/${video.slug}`}>
                <div className="Videos__channels">{video.tv_title}</div>
                <div className="Videos__title">{video.title}</div>

                <img
                  className="Videos__category-image"
                  src={video.category_image}
                  alt="video"
                />
              </Link>
              <div className="Videos__description">{video.description}</div>
              <div className="Videos__count">{video.videos_count} videos.</div>
              <a
                href={`/channel-videos/${video.slug}`}
                className="Videos__watch-videos"
              >
                Watch Videos!
              </a>
            </div>
          </div>
        </div>
      ))}
      {/*<div className="Videos__videoTags-wrap">*/}
      {/*  {videoTags?.map((video) => (*/}
      {/*    <div className="Videos__videoTags">*/}
      {/*      <div className="Videos__name">{video.name}</div>*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*</div>*/}
    </div>
  );
};

export default Videos;
