import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import LinkButton from "../../shared/LinkButton/LinkButton";

import "./RoasterArticle.scss";

const RoasterArticle = (props) => {
  const Shape =
    "https://assets.gocoffeego.com/v2/assets/images/article-shape.svg";
  const { productIsLoading, purveyor } = props;
  const [description, setDescription] = useState([]);
  const [awards, setAwards] = useState([]);
  const params = useParams();

  const location = {
    pathname: `/roaster/${purveyor?.slug}-${purveyor?.id}`,
    state: { purveyorId: purveyor?.id },
  };

  useEffect(() => {
    if (!productIsLoading) {
      const replacedText = purveyor?.description
        .replace(/(<([^>]+)>)/gi, "")
        .replace("[BREAK]", " ");

      const description = replacedText?.split("\n");
      setDescription(description);

      const awards = purveyor?.awards?.split("•");
      setAwards(awards);
    }
  }, [params, productIsLoading]);

  return (
    <div className="RoasterArticle">
      <div className="RoasterArticle__content">
        <div className="RoasterArticle__left-col">
          <div className="RoasterArticle__title font-cairo">
            {purveyor?.name}
            <img
              className="RoasterArticle__title-shape"
              src={Shape}
              alt="article-shape"
            />
          </div>

          <div className="RoasterArticle__logo-container">
            <img
              className="RoasterArticle__logo"
              src={purveyor?.main_image?.lg}
              alt="coffee-logo"
            />
          </div>

          <div className="AwardsSection">
            <div className="AwardsSection__line" />
            <div className="AwardsSection__title1 font-cairo">Estabilished:</div>
            <div className="AwardsSection__title2 font-cairo">{purveyor?.established}</div>

            <div className="AwardsSection__title1 font-cairo">Owners:</div>
            <div className="AwardsSection__title2 font-cairo">{purveyor?.owners}</div>

            {awards && (
              <>
                <div className="AwardsSection__title1 font-cairo">Awards:</div>
                {awards?.map((text, index) => (
                  <div className="AwardsSection__title2 font-cairo" key={index}>
                    {text}
                  </div>
                ))}
              </>
            )}
          </div>
          <LinkButton
            className="RoasterArticle__button"
            label="MORE COFFEES FROM THIS ROASTER"
            path={location}
          />
        </div>
        <div className="RoasterArticle__right-col">
          <img
            className="RoasterArticle__image-frame"
            src={purveyor?.active_images?.main_image}
            alt=""
          />
          <div className="RoasterArticle__description-container">
            {description?.map((text, index) => (
              <p className="RoasterArticle__description" key={index}>
                {text}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoasterArticle;
