import Axios from "axios";

export const apiRequest = async (method, path, data) => {
  const auth = localStorage.getItem("access_token");
  const res = await Axios.request({
    url: `${process.env.REACT_APP_API_URL}/${path}`,
    method: method,
    data: data ? data : null,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth}`,
    },
  });
  return res.data;
};

export * from "./CoffePurveyors";
export * from "./Products";
export * from "./Recommendations";
export * from "./CoffeeByRegion";
export * from "./CoffeeByRoast";
export * from "./CoffeeByType";
export * from "./PurveyorsByRegion";
export * from "./Recipes";
export * from "./Music";
export * from "./MerchItem";
export * from "./AccountDetails";
export * from "./Orders";
export * from "./CreditCards";
export * from "./Login";
export * from "./MemberPicks";
export * from "./PeaberryRecommends";
export * from "./PrePaid";
export * from "./CoffeeFAQ";
export * from "./Cart";
export * from "./AutoShip";
export * from "./Quiz";
export * from "./Newsletter";
export * from "./AwardWinners";
export * from "./AdvancedSearch";
export * from "./GiftCertificates";
export * from "./Specials";
export * from "./Learn";
export * from "./ContactUs";
export * from "./Checkout";
export * from "./Videos";
export * from "./ChannelVideos";
export * from "./Blog";
export * from "./SEO";
export * from "./LimitedOfferings";
export * from "./BulkCoffees";
export * from "./Header";
export * from "./Stripe";
export * from "./Tea";
export * from "./HomeSlider";
