import React, { useState, useEffect } from "react";
import * as API from "../../api/Api";

import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import Product from "../../components/Shop/Product/Product";
import ProductLoader from "../../components/Loaders/ProductLoader/ProductLoader";
import ViewMoreButton from "../../components/shared/ViewButton/ViewMoreButton";

import "./Tea.scss";

const Tea = () => {
  const [teaProduct, setTeaProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePage, setHasMorePage] = useState(false);

  const teaLady =
    "https://assets.gocoffeego.com/v2/assets/images/lady.png";
  const flowers =
    "https://assets.gocoffeego.com/v2/assets/images/four-flowers.png";

  useEffect(() => {
    setIsLoading(true);
    API.getTeaProducts().then((res) => {
      setTeaProducts(res.data);
      setHasMorePage(res.pagination?.has_more);
      setIsLoading(false);
    });
  }, []);

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => {
      return prevPage + 1;
    });

    API.getTeaProducts(currentPage + 1).then((res) => {
      setTeaProducts((products) => products.concat(res.data));
      setHasMorePage(res.pagination?.has_more);
    });
  };

  return (
    <div className="Tea">
      <PrimaryTitle title="Tea" className="Tea__title" />

      <div className="Tea__container">
        {isLoading ? (
          <div className="Tea__products-wrapper">
            {Array(12)
              .fill(0)
              .map((item, index) => (
                <ProductLoader key={index} />
              ))}
          </div>
        ) : (
          <>
            {!isLoading && teaProduct.length !== 0 && (
              <div className="Tea__products-wrapper">
                <img src={teaLady} className="Tea__lady" alt="tea lady" />
                <img
                  src={flowers}
                  className="Tea__flowers"
                  alt="four flowers"
                />
                {teaProduct?.map((product) => (
                  <Product product={product} key={product?.id} />
                ))}
              </div>
            )}
          </>
        )}
        {hasMorePage && (
          <ViewMoreButton
            handleClick={handleLoadMore}
            style={{ marginTop: "2rem", marginBottom: "5rem" }}
          />
        )}
      </div>
    </div>
  );
};

export default Tea;
