import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGiftCertificatesFormik } from "../../components/GiftCertificates/GiftCertificatesForm/useGiftCertificatesFormik";
import * as API from "../../api/Api";
import { useCartContext } from "../../lib/context/CartContext/CartContext";
import { Form, Input, Spinner } from "reactstrap";
import MiddleLine from "../../components/shared/MiddleLine/MiddleLine";
import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import SelectBox3 from "../../components/GiftCertificates/SelectBox3/SelectBox3";
import { useMessageContext } from "../../lib/context/MessageModalContext/MessageModalContext";
import TermsOfUseModal from "../../components/Modals/TermsOfUseModal/TermsOfUseModal";
import FAQGiftCertModal from "../../components/Modals/FAQGiftCertModal/FAQGiftCertModal";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";

import "./GiftCertificates.scss";

const GiftCertificates = () => {
  const [giftCertificates, setGiftCertificates] = useState([]);
  const [priceSelected, setPriceSelected] = useState({
    id: 28,
    value: 25,
  });
  const [message, setMessage] = useState();
  const [shownImage, setShownImage] = useState(28);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [faqOpen, setFaqIsOpen] = useState(true);
  const [charsLeft, setCharsLeft] = useState(60);

  let history = useHistory();

  const msgCtx = useMessageContext();
  const cartCtx = useCartContext();
  const seoCtx = useSeoContext();
  const location = useLocation();
  const pathname = location.pathname;

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url === pathname);

  if (message) {
    setTimeout(() => setMessage(""), 3000);
  }

  const handleImage = (id) => {
    setShownImage((prev) => (prev.shownImage !== id ? id : 0));
  };

  useEffect(() => {
    API.getGiftCertificates().then((res) => {
      setGiftCertificates(res.data);
    });
  }, []);

  const formik = useGiftCertificatesFormik({
    onSubmit: async (values) => {
      let gift = {
        design: 0,
        to: values.reciever,
        from: values.sender,
        msg: values.giftMessage,
      };

      const cartId = localStorage.getItem("cart_id");
      setIsLoading(true);
      API.addGiftCertificateToCart(priceSelected.id, gift, cartId)
        .then((res) => {
          if (res.status === 200) {
            if(res?.data?.cart_id && cartId !== res?.data?.cart_id) {
              localStorage.setItem("cart_id", res.data.cart_id);
            }
            setMessage("Gift Certificate is added to cart");
            setIsLoading(false);
            cartCtx.getCartItems();
            history.push("/cart");
          }
        })
        .catch((err) => {
          msgCtx.handleError(err);
          setIsLoading(false);
        });
    },
  });

  const selectedOption = (value, id) => {
    setPriceSelected({
      value: value,
      id: id,
    });
  };

  return (
    <div className="GiftCertificates">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <PrimaryTitle title="Gift Certificates" />
      <div className="GiftCertificates__container">
        <div className="GiftCertificates__subtitle-wrap">
          <MiddleLine />
          <h2 className="GiftCertificates__subtitle">
            GOCOFFEEGO GIFT CERTIFICATE TO GO! WHEN YOU NEED IT RIGHT NOW.
          </h2>
        </div>
        <div className="GiftCertificates__wrap">
          {giftCertificates &&
            giftCertificates.map((gift, index) => (
              <img
                key={index}
                src={gift.image}
                alt="gift certificate"
                className={
                  shownImage === gift.id
                    ? "GiftCertificates__image"
                    : "GiftCertificates__image-hide"
                }
              />
            ))}

          <div className="GiftCertificates__certificate">
            <Form
              className="GiftCertificates__form"
              onSubmit={formik.handleSubmit}
            >
              <div className="GiftCertificates__inputs">
                <div className="GiftCertificates__input-container">
                  <label className="GiftCertificates__label">To</label>
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.reciever}
                    className="GiftCertificates__input"
                    name="reciever"
                    type="text"
                  />
                  {formik.errors.reciever && formik.touched.reciever && (
                    <div className="GiftCertificates__required">
                      {formik.errors.reciever}
                    </div>
                  )}
                </div>
                <div className="GiftCertificates__input-container">
                  <label className="GiftCertificates__label">From</label>
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sender}
                    className="GiftCertificates__input"
                    name="sender"
                    type="text"
                  />
                  {formik.errors.sender && formik.touched.sender && (
                    <div className="GiftCertificates__required">
                      {formik.errors.sender}
                    </div>
                  )}
                </div>
                <div className="GiftCertificates__select-input">
                  <label className="GiftCertificates__label">Gift Amount</label>
                  <SelectBox3
                    className="GiftCertificates__select"
                    onBlur={formik.handleBlur}
                    value={priceSelected.value}
                    selectedItem={priceSelected.value}
                    onItemSelect={(value, id) => {
                      selectedOption(value, id);
                      handleImage(id);
                      formik.setFieldValue("giftAmount", value);
                    }}
                    items={giftCertificates}
                  />
                  {/*   {formik.errors.js.giftAmount && formik.touched.giftAmount && (
                    <div className="GiftCertificates__required">
                      {formik.errors.js.giftAmount}
                    </div>
                  )} */}
                </div>
              </div>
              <div className="GiftCertificates__textarea-wrap">
                <label className="GiftCertificates__label">
                  Enter your gift note here
                </label>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.giftMessage}
                  className="GiftCertificates__textarea"
                  name="giftMessage"
                  type="textarea"
                  maxLength="60"
                />
                <div>
                  Characters Remaining:{" "}
                  {charsLeft - formik.values.giftMessage.length}
                </div>
                {formik.errors.giftMessage && formik.touched.giftMessage && (
                  <div className="GiftCertificates__required">
                    {formik.errors.giftMessage}
                  </div>
                )}
              </div>
              <button
                disabled={isLoading ? true : false}
                type="submit"
                className="GiftCertificates__form-button"
              >
                {isLoading ? (
                  <Spinner
                    style={{
                      border: "0.25em solid #00d1d6",
                      borderRightColor: "transparent",
                      width: "2.5rem",
                      height: "2.5rem",
                    }}
                  />
                ) : (
                  "ADD TO CART"
                )}
              </button>
            </Form>
            {message && (
              <div className="GiftCertificates__message">{message} </div>
            )}
            <ul className="GiftCertificates__more-info">
              <li>Add your own personalized message or greeting.</li>
              <li>
                Print right away on your printer and deliver to the lucky
                recipient in person.
              </li>
              <li>Prints full color, 8 ½ X 11.</li>
              <li>
                For more information see{" "}
                <span
                  className="GiftCertificates__faq"
                  onClick={() => setFaqIsOpen(!faqOpen)}
                >
                  Gift Certificate FAQ's
                </span>
              </li>
              <li>
                See{" "}
                <span
                  className="GiftCertificates__terms"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Terms and Conditions
                </span>
              </li>
              <TermsOfUseModal
                isOpen={!isOpen}
                toggle={() => setIsOpen(!isOpen)}
              />

              <FAQGiftCertModal
                isOpen={!faqOpen}
                toggle={() => setFaqIsOpen(!faqOpen)}
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftCertificates;
