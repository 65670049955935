import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import MemberPicksCard from "../../components/MemberPicks/MemberPicksCard/MemberPicksCard";
import PreviousMemberPicks from "../../components/MemberPicks/PreviousMemberPicks/PreviousMemberPicks";
import * as API from "../../api/Api";
import Filter from "../../components/Shop/Filter/Filter";
import SectionTitle from "../../components/Cart/SectionTitle/SectionTitle";
import { useFilterProductsContext } from "../../lib/context/FilterProductsContext/FilterProductsContext";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";

import "./MemberPicks.scss";

const MemberPicks = () => {
  const [data, setData] = useState();

  const filterCtx = useFilterProductsContext();
  const seoCtx = useSeoContext();
  let history = useHistory();
  let location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    API.getMemberOfTheWeek().then((res) => {
      setData(res.data);
    });
  }, []);

  useEffect(() => {
    filterCtx.getInitialProducts();

    return history.listen((location) => {
      if (history.action === "POP" && location.pathname === "/member-picks") {
        history.push("/");
      }
      filterCtx.setSelectedFilter([]);
    });
  }, []);

  const foundSeo = seoCtx.pageMetadata.find(
    (el) => el.url.toLowerCase() === pathname
  );
  const seoLinks = seoCtx.pageMetadata.filter((el) =>
    el.url.startsWith("/member-picks/")
  );

  return (
    <div className="MemberPicks">
      {seoLinks &&
        seoLinks.map((item) => <Link key={item?.id} to={item?.url} />)}
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <div className="MemberPicks__content">
        <Filter memberPicks />
        <div className="MemberPicks__cards">
          {filterCtx.showMemberOfTheWeek && (
            <>
              <SectionTitle
                middleLine
                className="MemberPicks__title"
                title="MEMBER'S PICKS OF THE WEEK"
                titleTag="h1"
              />
              <MemberPicksCard
                memberOfTheWeek
                updatedAt={data?.updated_at}
                product={data?.product}
                review={data?.review}
                image={data?.image}
                customerName={data?.customer_name}
                customerCity={data?.customer_city}
                customerState={data?.customer_state.code}
              />
            </>
          )}
          <PreviousMemberPicks />
        </div>
      </div>
    </div>
  );
};

export default MemberPicks;
