import React from "react";

import Button from "../../shared/Button/Button";
import { useHistory, Link } from "react-router-dom";

import "./RockstarProduct.scss";

const RockstarProduct = (props) => {
  const { name, image, url, id, largeImage, slug } = props;
  const history = useHistory();
  const PinterestIcon =
    "https://assets.gocoffeego.com/v2/assets/icons/pinterest-icon.svg";

  const onAddCart = () => {
    history.push(`/products/${slug}/${id}`);
  };

  return (
    <div className="RockstarProduct">
      <div className="RockstarProduct__container">
        <a
          className="RockstarProduct__icon"
          target="_blank"
          rel="noreferrer"
          data-pin-do="buttonPin"
          data-pin-custom
          data-pin-tall="true"
          data-pin-round="true"
          href={`https://www.pinterest.com/pin/create/button/?url=${url}${`&description=${name}`}${`&media=${largeImage}`}`}
        >
          <img
            src={PinterestIcon}
            alt="pinterest"
            className="RockstarProduct__pinterest-icon"
          />
        </a>
        <Link to={`/products/${slug}/${id}`}>
          {" "}
          <img
            className="RockstarProduct__image"
            src={largeImage}
            alt={name}
          />{" "}
        </Link>
      </div>

      <Button
        className="RockstarProduct__button"
        onClick={onAddCart}
        label="VIEW DETAILS"
      />
    </div>
  );
};

export default RockstarProduct;
