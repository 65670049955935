import React from "react";

import MiddleLine from "../shared/MiddleLine/MiddleLine";
import Line from "../shared/Line/Line";
import LinkButton from "../shared/LinkButton/LinkButton";

import "./Coffe101.scss";

const Coffe101 = (props) => {
  const Peaberry =
    "https://assets.gocoffeego.com/v2/assets/images/peaberry-lounge.png";
  const { showLines, showCircle } = props;
  return (
    <div className="Coffe101">
      <div className="Coffe101__content">
        <img className="Coffe101__image" src={Peaberry} alt="peaberry-lounge" />
        <h2 className="Coffe101__title">COFFEE 101</h2>
        <MiddleLine />
        <h2 className="Coffe101__title2">LEARN MORE FROM PROFESSOR PEABERRY</h2>
        <LinkButton
          path="/learn/category/the-history-of-coffee/850"
          label="LEARN MORE "
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          className="Coffe101__button"
        />
        <img
          className="Coffe101__image-mobile"
          src={Peaberry}
          alt="peaberry-lounge"
        />
      </div>

      <div
        className={showLines ? "Coffe101__line" : "Coffe101__line-disappear"}
      >
        <Line />
        <Line />

        <div
          className={
            showCircle ? "Coffe101__circle" : "Coffe101__circle-disappear"
          }
        />
      </div>
    </div>
  );
};

export default Coffe101;
