import React, { useState, useEffect } from "react";
import * as API from "../../api/Api";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "../../lib/helpers/isEmpty";
import { useFilterProductsContext } from "../../lib/context/FilterProductsContext/FilterProductsContext";
import StartQuiz from "../../components/Quiz/StartQuiz/StartQuiz";
import QuizQuestion1 from "../../components/Quiz/QuizQuestion1/QuizQuestion1";
import QuizQuestion2 from "../../components/Quiz/QuizQuestion2/QuizQuestion2";
import QuizQuestion3 from "../../components/Quiz/QuizQuestion3/QuizQuestion3";
import QuizQuestion4 from "../../components/Quiz/QuizQuestion4/QuizQuestion4";
import QuizQuestion5 from "../../components/Quiz/QuizQuestion5/QuizQuestion5";
import QuizRecommendations from "../../components/Quiz/QuizRecommendations/QuizRecommendations";
import AudioPlayer from "../../components/Quiz/AudioPlayer/AudioPlayer";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { useMessageContext } from "../../lib/context/MessageModalContext/MessageModalContext";

import "./Quiz.scss";

const Quiz = () => {
  const [coffeeDrinking, setCoffeeDrinking] = useState(0);
  const [coffeeType, setCoffeeType] = useState(0);
  const [brew, setBrew] = useState(0);
  const [roastStyle, setRoastStyle] = useState(0);
  const [flavorNotes, setFlavorNotes] = useState(0);
  const [quizFinished, setQuizFinished] = useState(false);
  const [quizAnswers, setQuizAnswers] = useState({
    quizAnswers: [],
  });
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [showRecommendations, setShowRecommendations] = useState(false);
  const [recommendations, setRecommendations] = useState(null);
  const [quizSubmited, setQuizSubmited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const ProductCtx = useFilterProductsContext();
  const quizResult = ProductCtx.quizResult;
  const authCtx = useAuthContext();
  const msgCtx = useMessageContext();
  let location = useLocation();
  let history = useHistory();
  let pathname = location.pathname;
  let searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (quizFinished) {
      handleSubmit();
    }
  }, [quizFinished]);

  useEffect(() => {
    if (flavorNotes > 0) {
      handleSubmit();
    }
  }, [flavorNotes]);

  const addQuery = (key, value) => {
    searchParams.set(key, value);
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const deleteQuery = (key) => {
    searchParams.delete(key);
    history.push({
      pathname: pathname,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    if (location.pathname.startsWith("/quiz")) {
      ProductCtx.fetchQuizPreferences();
    }
  }, []);

  const fetchQuizRecommendations = () => {
    API.getQuizCoffeeRecommendations().then((res) => {
      setRecommendations(res.data["coffee-recommendations"]);
      if (res.data["coffee-recommendations"]?.length > 0) {
        setShowRecommendations(true);
        scrollInto("quiz-recommendations");
      }
      if (res.data["coffee-recommendations"]?.length === 0) {
        setShowRecommendations(false);
      }
    });
  };

  const scrollInto = (id) => {
    setTimeout(() => {
      const el = document.getElementById(id);
      if (el) {
        window.scroll({
          top: el.offsetTop - 180,
          left: 0,
          behavior: "smooth",
        });
      }
    }, 1000);
  };

  const startQuiz = () => {
    setQuizSubmited(false);
    setActiveQuestion(1);
    scrollInto("question-1");
  };

  const handleSubmit = () => {
    if (quizFinished && !isEmpty(quizResult)) {
      let updateQuiz = {
        id: quizResult.id,
        coffee_know_how_id: coffeeDrinking,
        coffee_type_id: coffeeType === 92 ? 0 : coffeeType,
        coffee_brew_id: brew,
        coffee_roast_id: roastStyle === 2222 ? 0 : roastStyle,
        coffee_region_id:
          flavorNotes === 2234 ? 0 : flavorNotes === 22 ? null : flavorNotes,
        coffee_other_regions: flavorNotes === 22 ? 1 : null,
      };
      setIsLoading(true);
      API.updateQuizPreferences(quizResult.id, updateQuiz).then((res) => {
        ProductCtx.fetchQuizPreferences();
        fetchQuizRecommendations();
        setQuizSubmited(true);
        setQuizFinished(false);
        setIsLoading(false);
      });
    }

    if (isEmpty(quizResult) && quizFinished) {
      setIsLoading(true);

      let addQuiz = {
        coffee_know_how_id: coffeeDrinking,
        coffee_type_id: coffeeType === 92 ? 0 : coffeeType,
        coffee_brew_id: brew,
        coffee_roast_id: roastStyle === 2222 ? 0 : roastStyle,
        coffee_region_id:
          flavorNotes === 2234 ? 0 : flavorNotes === 22 ? null : flavorNotes,
        coffee_other_regions: flavorNotes === 22 ? 1 : null,
      };
      if (!authCtx.isAuthenticated) {
        API.addQuiz(searchParams.toString())
          .then((res) => {
            setRecommendations(res.data);
            if (res.data?.length > 0) {
              setShowRecommendations(true);
              scrollInto("quiz-recommendations");
              setQuizSubmited(true);
              setQuizFinished(false);
              setIsLoading(false);
            }
            if (res.data?.length === 0) {
              setShowRecommendations(false);
            }
            setQuizSubmited(true);
            setQuizFinished(false);
            setIsLoading(false);
          })
          .catch((err) => msgCtx.handleError(err));
        return;
      } else if (authCtx.isAuthenticated) {
        API.addQuizPreferences(addQuiz)
          .then((res) => {
            ProductCtx.fetchQuizPreferences();
            fetchQuizRecommendations();
            setQuizSubmited(true);
            setQuizFinished(false);
            setIsLoading(false);
          })
          .catch((err) => console.log(err.response));
        return;
      }
    }
  };

  return (
    <div id="quiz" className="Quiz">
      <StartQuiz
        startQuiz={startQuiz}
        quizResult={quizResult}
        showRecommendations={showRecommendations}
      />
      <AudioPlayer />
      <div className="Quiz__questions">
        {activeQuestion === 1 && (
          <QuizQuestion1
            selectedAnswer={coffeeDrinking}
            onItemSelect={(id) => {
              setCoffeeDrinking(id);
              addQuery("coffee-know-how", id);
              setQuizAnswers((prev) => ({
                quizAnswers: [...prev.quizAnswers, id],
              }));

              scrollInto("question-2");
            }}
          />
        )}
        {coffeeDrinking !== 0 && (
          <QuizQuestion2
            selectedAnswer={coffeeType}
            onItemSelect={(id) => {
              setCoffeeType(id);
              addQuery("coffee_type_id", id);
              setQuizAnswers((prev) => ({
                quizAnswers: [...prev.quizAnswers, id],
              }));

              scrollInto("question-3");
            }}
          />
        )}
        {coffeeType !== 0 && (
          <QuizQuestion3
            selectedAnswer={brew}
            onItemSelect={(id) => {
              setBrew(id);
              addQuery("coffee_brew_id", id);
              setQuizAnswers((prev) => ({
                quizAnswers: [...prev.quizAnswers, id],
              }));

              scrollInto("question-4");
            }}
          />
        )}
        {brew !== 0 && (
          <QuizQuestion4
            selectedAnswer={roastStyle}
            onItemSelect={(id) => {
              setRoastStyle(id);
              if (id === 2222) {
                addQuery("coffee_roast_id", 0);
              } else {
                addQuery("coffee_roast_id", id);
              }
              setQuizAnswers((prev) => ({
                quizAnswers: [...prev.quizAnswers, id],
              }));

              scrollInto("question-5");
            }}
          />
        )}
        {roastStyle !== 0 && (
          <QuizQuestion5
            selectedAnswer={flavorNotes}
            onItemSelect={(id) => {
              setFlavorNotes(id);
              if (id === 22) {
                addQuery("coffee_other_regions", 1);
                if (searchParams.has("coffee_region_id") === true) {
                  deleteQuery("coffee_region_id");
                }
              }
              if (id === 2234) {
                addQuery("coffee_region_id", 0);
                if (searchParams.has("coffee_other_regions") === true) {
                  deleteQuery("coffee_other_regions");
                }
              }
              if (id && id !== 22 && id !== 2234) {
                addQuery("coffee_region_id", id);
                if (searchParams.has("coffee_other_regions") === true) {
                  deleteQuery("coffee_other_regions");
                }
              }
              setQuizAnswers((prev) => ({
                quizAnswers: [...prev.quizAnswers, id],
              }));

              setQuizFinished(true);
              handleSubmit();
              // fetchQuizRecommendations();
            }}
            recommendations={recommendations}
          />
        )}
      </div>

      {showRecommendations &&
        quizSubmited &&
        !isLoading &&
        quizAnswers?.quizAnswers?.length >= 5 &&
        recommendations?.length > 0 && (
          <QuizRecommendations quizRecommendations={recommendations} />
        )}
    </div>
  );
};

export default Quiz;
