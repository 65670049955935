import React from "react";
import { useLocation } from "react-router-dom";
import CorporateGifts from "../../components/Gift/CorporateGifts/CorporateGifts";
import GiftIntro from "../../components/Gift/GiftIntro/GiftIntro";
import GiftSubscriptions from "../../components/Gift/GiftSubscriptions/GiftSubscriptions";
import Merch from "../../components/Gift/Merch/Merch";
import Coffe101 from "../../components/Coffe101/Coffe101";
import Line from "../../components/shared/Line/Line";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";

import "./Gift.scss";

const Gift = () => {
  const seoCtx = useSeoContext();
  const location = useLocation();
  const pathname = location.pathname;

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url === pathname);
  return (
    <div className="Gift">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <GiftIntro />
      <GiftSubscriptions />
      <Merch />
      <CorporateGifts />
      <Line />
      <Line />
      <Coffe101 showLines={false} />
    </div>
  );
};

export default Gift;
