import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import * as API from "../../../api/Api";
import { useSeoContext } from "../../../lib/context/SeoContext/SeoContext";
import Line from "../../shared/Line/Line";
import Purveyor from "../Purveyor/Purveyor";
import RoasterLoader from "../../Loaders/RoasterLoader/RoasterLoader";
import PurveyorsLoader from "../../Loaders/PurveyorsLoader/PurveyorsLoader";
import Seo from "../../../lib/helpers/SEO/Seo";
import { ROASTERS_MESSAGE } from "../../../api/RoastersMessage";

import "./Purveyors.scss";

const Purveyors = () => {
  const [roaster, setRoaster] = useState([]);
  const [roasterLoading, setRoasterLoading] = useState(true);
  const [products, setProducts] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const params = useParams();
  const roasterId = params.pathParam;
  const numberPattern = /\d+/g;

  const [showContent, setShowContent] = useState(false);
  const seoCtx = useSeoContext();

  const foundSeo = seoCtx.pageMetadata.find(
    (el) => el.url.toLowerCase() === `/roaster/${roasterId}.html`
  );

  const foundSeo2 = seoCtx.pageMetadata.find(
    (el) => el.url.toLowerCase() === `/roaster/${roasterId}`
  );

  let roasterImage = ROASTERS_MESSAGE.find(
    (item) => roaster.slug === item.slug
  );

  let roastBannerImage = null;
  if(roaster?.active_images?.main_image) {
    roastBannerImage = roaster?.active_images?.main_image;
  } else {
    roastBannerImage = roasterImage?.image;
  }

  // const coffeeReviewBadge = products?.coffee_review_image;
  let purveyorId = roasterId.match(numberPattern);

  if (purveyorId?.length > 1) {
    // purveyorId = purveyorId.join("-");
    purveyorId = purveyorId[1];
  }

  useEffect(() => {
    API.getCoffeePurveyorsById(purveyorId)
      .then((res) => {
        setRoaster(res.data);
        if (res.data) {
          setRoasterLoading(false);
        }
      })
      .catch((err) => console.log(err, "err"));
    API.getProductsByRoaster(purveyorId, 50, currentPage)
      .then((res) => {
        setProducts(res.data);
        setHasMore(res.pagination.has_more);
      })
      .catch((err) => console.log(err, "err"));
  }, []);

  const handleViewMore = () => {
    API.getProductsByRoaster(purveyorId, 50, currentPage + 1)
      .then((res) => {
        setProducts((products) => products.concat(res.data));
        setCurrentPage(currentPage + 1);
        setHasMore(res.pagination.has_more);
      })
      .catch((err) => console.log(err, "err"));
  };

  return (
    <div className="Purveyors">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      {foundSeo2 && (
        <Seo
          title={foundSeo2?.title}
          description={foundSeo2?.description}
          keywords={foundSeo2?.keywords}
        />
      )}
      <Seo roaster={roaster} />

      <Line />
      <Line />
      <div className="Purveyors__main-container">
        <div className="Purveyors__container">
          {roasterLoading ? (
            <RoasterLoader />
          ) : (
            <img
              src={roaster.main_image?.md}
              alt={roaster.name}
              className="Purveyors__image"
            />
          )}
          <div className="Purveyors__info">
            <div className="Purveyors__info-item">
              <span className="Purveyors__info-name">Owners:</span>
              <span className="Purveyors__info-desc" style={{fontSize: "1.5rem"}}>{roaster.owners}</span>
            </div>
            <div className="Purveyors__info-item">
              <span className="Purveyors__info-name">Name: </span>
              <span className="Purveyors__info-desc"> {roaster?.name}</span>
            </div>
            {roaster?.roaster && (
              <div className="Purveyors__info-item">
                <span className="Purveyors__info-name">Roasters: </span>
                <span className="Purveyors__info-desc">
                  {" "}
                  {roaster?.roaster}
                </span>
              </div>
            )}
            <div className="Purveyors__info-item">
              <span className="Purveyors__info-name">Established: </span>
              <span className="Purveyors__info-desc">
                {" "}
                {roaster?.established}
              </span>
            </div>
            {roaster?.awards && (
              <div className="Purveyors__info-item">
                <span className="Purveyors__info-name">Awards: </span>
                <span className="Purveyors__info-desc"> {roaster?.awards}</span>
              </div>
            )}
          </div>
        </div>
        <div className="Purveyors__circle" />
      </div>
      <div className="Purveyors__main-img-wrap">
        {roastBannerImage && (
          <img
            src={roastBannerImage}
            alt="main"
            className="Purveyors__main-img"
          />
        )}
      </div>
      <div className="Purveyors__description">
        <div className="Purveyors__description-container">
          {showContent && (
            <div className="Purveyors__description-content">
              <p className="Purveyors__description-text">
                {roaster?.description
                  ?.replace(/(<([^>]+)>)/gi, "")
                  .replace("[BREAK]", " ")
                  .substring(0, roaster?.description.length)}
              </p>

              <p className="Purveyors__description-process">
                <span>Roasting Process / Roaster: </span>
                <span>
                  {roaster?.roasting_process} / {roaster.roaster}
                </span>
              </p>
            </div>
          )}

          <h2
            className="Purveyors__description-link"
            onClick={() => setShowContent(!showContent)}
          >
            {showContent ? "Read less about" : "Read more about"} {roaster.name}
            ...
          </h2>
        </div>
      </div>
      {products && products.length !== 0 && (
        <>
          {roaster?.special_heading && (
            <div className="Purveyors__special">
              <h2 className="Purveyors__special-text">
                {roaster?.special_heading}
              </h2>
            </div>
          )}

          <div className="Purveyors__products-wrap">
            <div className="Purveyors__products-icon-container">
              <h1 className="Purveyors__heading">{roaster?.name}</h1>
              <InfiniteScroll
                dataLength={products.length}
                next={handleViewMore}
                hasMore={hasMore}
                loader={
                  <div className="Purveyors__loaders">
                    {Array(4)
                      .fill(0)
                      .map((item, index) => (
                        <PurveyorsLoader key={index} />
                      ))}
                  </div>
                }
                scrollThreshold="35%"
              >
                <div className="Purveyors__products">
                  {products?.map((product, index) => (
                    <Purveyor key={index} purveyor={product} />
                  ))}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Purveyors;
