import { apiRequest } from "./Api";

const Tank =
  "https://assets.gocoffeego.com/v2/assets/images/merch/tank.png";
const Cup =
  "https://assets.gocoffeego.com/v2/assets/images/merch/cup.png";
const Cards =
  "https://assets.gocoffeego.com/v2/assets/images/merch/cards.png";
const Onesie =
  "https://assets.gocoffeego.com/v2/assets/images/merch/onesie.png";
const Tee =
  "https://assets.gocoffeego.com/v2/assets/images/merch/tee.png";

export const MERCH = [
  {
    id: 1,
    name: "tank",
    image: Tank,
  },
  {
    id: 2,
    name: "cup",
    image: Cup,
  },
  {
    id: 3,
    name: "cards",
    image: Cards,
  },
  {
    id: 4,
    name: "onesie",
    image: Onesie,
  },
  {
    id: 5,
    name: "tee",
    image: Tee,
  },
];

export const getMerch = async () => {
  return apiRequest("get", `merch`);
};
