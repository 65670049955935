import React from "react";

import Line from "../Line/Line";
import cs from "classnames";

import "./PrimaryTitle.scss";

const PrimaryTitle = (props) => {
  const { title, className, primaryClassName } = props;
  return (
    <div className={cs("PrimaryTitle", primaryClassName)}>
      <Line />
      <Line />

      <div className={cs("PrimaryTitle__title-container", className)}>
        <h1 className="PrimaryTitle__title"> {title}</h1>
      </div>
    </div>
  );
};

export default PrimaryTitle;
