import React from "react";
import { useHistory } from "react-router-dom";
import { useFilterProductsContext } from "../../../lib/context/FilterProductsContext/FilterProductsContext";

import "./RecommendationItem.scss";

const RecommendationItem = (props) => {
  const { image, title, category, categoryId } = props;
  const FiltersCtx = useFilterProductsContext();
  const history = useHistory();

  const handleOnClick = (category, category_id, title) => {
    if (title === "Member Picks") {
      history.push("/member-picks");
      return;
    }
    if (title === "Peaberry Recommends") {
      history.push("/peaberry-recommends");
      return;
    }
    if (category === "all_roasters") {
      history.push("/roasters");
      return;
    }
    if (category === "advanced_search") {
      history.push("/search");
      return;
    }
    if (category === "award_winners") {
      history.push("/award-winners");
      return;
    }
    if (title === "Top 25") {
      history.push("/top25");
      return;
    }
    if (title === "Espresso") {
      FiltersCtx.setSelectedFilter({
        category_name: "coffee_type_id",
        id: 10,
        name: "Espresso",
        old_category_name: "coffee-by-type",
        slug: "espresso",
      });

      history.push("/shop/coffee-by-type/espresso");
    }
    if (title === "Single Origin") {
      FiltersCtx.setSelectedFilter({
        category_name: "coffee_type_id",
        id: 12,
        name: "Single Origin",
        old_category_name: "coffee-by-type",
        slug: "single-origin",
      });

      history.push("/shop/coffee-by-type/single-origin");
    }
    if (title === "Blends") {
      FiltersCtx.setSelectedFilter({
        category_name: "coffee_type_id",
        id: 11,
        name: "Blends",
        old_category_name: "coffee-by-type",
        slug: "blends",
      });
      history.push("/shop/coffee-by-type/blends");
    }
    if (title === "Organic") {
      FiltersCtx.setSelectedFilter({
        category_name: "coffee_type_id",
        id: 1,
        name: "Organic",
        old_category_name: "coffee-by-type",
        slug: "organic",
      });
      history.push("/shop/coffee-by-type/organic");
    }

    if (title === "Natural Process") {
      FiltersCtx.setSelectedFilter({
        category_name: "coffee_type_id",
        id: 34,
        name: "Natural Process",
        old_category_name: "coffee-by-type",
        slug: "natural-process",
      });
      history.push("/shop/coffee-by-type/natural-process");
    }

    if (title === "Tea") {
      history.push("/tea");
      return;
    }

    if (title === "Light Roast") {
      FiltersCtx.setSelectedFilter({
        category_name: "coffee_roast_id",
        id: 1,
        name: "Light",
        old_category_name: "coffee-by-roast",
        slug: "light",
      });
      history.push("shop/coffee-by-roast/light");
    }
    if (title === "Dark Roast") {
      FiltersCtx.setSelectedFilter({
        category_name: "coffee_roast_id",
        id: 3,
        name: "Dark",
        old_category_name: "coffee-by-roast",
        slug: "dark",
      });
      history.push("shop/coffee-by-roast/dark");
    }
    if (title === "Medium Roast") {
      FiltersCtx.setSelectedFilter({
        category_name: "coffee_roast_id",
        id: 2,
        name: "Medium",
        old_category_name: "coffee-by-roast",
        slug: "medium",
      });
      history.push("shop/coffee-by-roast/medium");
    }

    setTimeout(function () {
      document.getElementById("Shop__wrapper").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 1000);
  };

  return (
    <div
      className="RecommendationItem"
      onClick={() => handleOnClick(category, categoryId, title)}
    >
      <img className="RecommendationItem__image" src={image} alt={title} />
      <div className="RecommendationItem__title-container">
        <div className="RecommendationItem__title font-cairo"> {title}</div>
      </div>
    </div>
  );
};

export default RecommendationItem;
