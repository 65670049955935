import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import * as API from "../../api/Api";
import cs from "classnames";

import MiddleLine from "../../components/shared/MiddleLine/MiddleLine";
import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";
import "./FAQ.scss";

const FAQ = () => {
  const [coffeeFaq, setCoffeeFaq] = useState([]);
  const [collapse, setCollapse] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const seoCtx = useSeoContext();

  const foundSeo = seoCtx.pageMetadata.find(
    (el) => el.url === "/professor-peaberry/coffee-faq"
  );

  useEffect(() => {
    API.getCoffeeFaq().then((res) => setCoffeeFaq(res.data));
  }, []);

  const toggleCollapse = (id) => {
    if (!collapse || id !== collapse) {
      setCollapse(id);
      setIsOpen(true);
    } else if (collapse === id) {
      setCollapse(false);
      setIsOpen(false);
    }
  };

  return (
    <div className="FAQ">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
          coffeeFaq={coffeeFaq}
        />
      )}
      <div className="FAQ__main-wrapper">
        <PrimaryTitle
          title="FREQUENTLY ASKED QUESTIONS"
          className="FAQ__title"
        />
        <div className="FAQ__container">
          <div className="FAQ__circle"></div>
          <div className="FAQ__subtitle-wrap">
            <MiddleLine />
            <div className="FAQ__subtitle">Answers to your coffee queries </div>
          </div>
          <div className="FAQ__questions-wrap">
            <div className="FAQ__questions-container">
              {coffeeFaq.map((faq) => (
                <div className="FAQ__question-wrap" key={faq.id}>
                  <div onClick={() => toggleCollapse(faq.id)}>
                    <div
                      className={cs(
                        "FAQ__close",
                        collapse === faq.id && isOpen && "active"
                      )}
                    >
                      <div className="FAQ__line1"></div>
                      <div className="FAQ__line2"></div>
                    </div>
                    <div
                      className={cs(
                        "FAQ__question",
                        collapse === faq.id && isOpen && "active"
                      )}
                    >
                      {faq.question}
                    </div>
                  </div>

                  <Collapse isOpen={collapse === faq.id}>
                    <div className="FAQ__answers">{faq.answer}</div>
                  </Collapse>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
