import React from "react";

import MiddleLine from "../shared/MiddleLine/MiddleLine";
import Item from "./Item/Item";
import Line from "../shared/Line/Line";

import "./WhyChooseGoCoffeGo.scss";

const WhyChooseGoCoffeGo = () => {
  const CoffeIcon1 =
    "https://assets.gocoffeego.com/v2/assets/icons/coffe-icon-1.svg";
  const CoffeIcon2 =
    "https://assets.gocoffeego.com/v2/assets/icons/coffe-icon-2.svg";
  const CoffeIcon3 =
    "https://assets.gocoffeego.com/v2/assets/icons/coffe-icon-3.svg";
  const ScooterGirl2 =
    "https://assets.gocoffeego.com/v2/assets/images/scooter-girl-2.png";
  return (
    <div className="WhyChooseGoCoffeGo">
      <MiddleLine />
      <h2 className="WhyChooseGoCoffeGo__title"> WHY CHOOSE GoCoffeeGo </h2>
      <div className="WhyChooseGoCoffeGo__items">
        <Item
          image={CoffeIcon1}
          title="Meticulously curated"
          description="1000+ COVETED COFFEES FROM 32+ ACCOMPLISHED ROASTERS"
        />
        <Item
          image={CoffeIcon2}
          title="Fresh as can be"
          description="ROASTED TO ORDER & SHIPPED DIRECT-TO-YOU FOR THE FRESHEST BREW OUT THERE"
        />
        <Item
          image={CoffeIcon3}
          title="Perfectly tailored"
          description="ENDLESS EXPERT RECOMMENDATIONS BASED ON YOUR PREFERENCES"
        />
        <img src={ScooterGirl2} className="WhyChooseGoCoffeGo__image" />
      </div>
      <Line />
      <Line />
    </div>
  );
};

export default WhyChooseGoCoffeGo;
