import React, { useEffect, useState } from "react";
import * as API from "../../api/Api";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

import Line from "../../components/shared/Line/Line";
import Button from "../../components/shared/Button/Button";
import SelectPlan from "../../components/AssignedClub/SelectPlan/SelectPlan";
import PriceRange from "../../components/AssignedClub/PriceRange/PriceRange";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { isEmpty } from "../../lib/helpers/isEmpty";
import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";
import CoffeeClubInfoModal from "../../components/Modals/CoffeeClubInfoModal/CoffeeClubInfoModal";

import "./AssignedClub.scss";

const AssignedClub = (props) => {
  const Icon =
    "https://assets.gocoffeego.com/v2/assets/images/info-icon.svg";
  let history = useHistory();
  const [clubData, setClubData] = useState({});
  const [clubLoading, setClubLoading] = useState(true);
  const [subscriptionThatExist, setSubscriptionThatExist] = useState({});
  const [giftCreditCardExist, setGiftCreditCardExist] = useState(false);
  const [planExist, setPlanExist] = useState(false);
  const data = clubData;
  const isLoading = clubLoading;
  const clubJoined = props.location.clubJoined;
  const tier1plan = data?.tier_1_plans;
  const tier2plan = data?.tier_2_plans;
  const [coffeeClubs, setCoffeeClubs] = useState([]);
  const [myCoffeeClubs, setMyCoffeeClubs] = useState([]);
  const [showCoffeeClubs, setShowCoffeeClubs] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [showCreditCards, setShowCreditCards] = useState(false);
  const [description, setDescription] = useState({
    active: false,
    id: null,
  });
  const [selectedPlan, setSelectedPlan] = useState({});
  const [newSelectedPlan, setNewSelectedPlan] = useState({});
  const [selectedClub, setSelectedClub] = useState();
  const [loading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const params = useParams();
  const authCtx = useAuthContext();
  const seoCtx = useSeoContext();

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url === "/coffee-club");

  const gift_subscription_adress =
    authCtx.customerData?.gift_subscription_adress;

  useEffect(() => {
    API.getCreditCards()
      .then((res) => {
        if (res.data) {
          const card = res.data.find((item) => {
            return item?.subscription_card === 1;
          });

          if (card?.id) {
            setGiftCreditCardExist(true);
          }
        }
      })
      .catch((err) => console.log(err, "Err"));
  }, []);

  useEffect(() => {
    if (params.pathParam === undefined) {
      API.getAllCoffeeClubs().then((res) => setCoffeeClubs(res.data));
      setShowCoffeeClubs(true);
    }
    API.getCoffeeClub(params.pathParam).then((club) => {
      setClubData(club.data[0]);
      setClubLoading(false);

      API.getMyCoffeeClubs()
        .then((res) => {
          const subscriptionExist = res.data.find((item) => {
            return item.subscription_tour.id === club.data[0].id;
          });

          if (subscriptionExist) {
            setSubscriptionThatExist(subscriptionExist);
          } else {
            setSubscriptionThatExist(null);
          }

          setMyCoffeeClubs(res.data);
        })
        .catch((err) => console.log(err, "err"));
    });
  }, []);

  useEffect(() => {
    if (subscriptionThatExist?.id) {
      const planIdExist =
        subscriptionThatExist.subscription_plan.id === selectedPlan.id;

      if (planIdExist) {
        setPlanExist(true);
      }
    }
  }, [subscriptionThatExist, selectedPlan]);

  const showAllClubs = () => {
    API.getAllCoffeeClubs().then((res) => setCoffeeClubs(res.data));
    setShowCoffeeClubs(!showCoffeeClubs);
    scrollInto("clubs");
  };

  const joinAssignedClub = (id, clubId) => {
    if (!authCtx.isAuthenticated) {
      history.push("/login");
    }

    const cartId = localStorage.getItem("cart_id");
    setIsLoading(true);

    if (gift_subscription_adress?.id && giftCreditCardExist) {
      API.joinClub(cartId, id)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => history.push("/account/coffee-club"), 2000);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setMessage(err.response.data.error.message);
          scrollInto("AssignedClub__message");
          setNewSelectedPlan({});
          setIsLoading(false);
        });
      return;
    }

    if (newSelectedPlan?.id && !selectedPlan?.id) {
      localStorage.setItem("plan_id", newSelectedPlan?.id);
    } else {
      localStorage.setItem("plan_id", selectedPlan.id);
    }
    history.push("/join-club");
  };

  const updateClub = () => {
    setIsLoading(true);
    API.updateCoffeeClub(
      subscriptionThatExist.id,
      selectedPlan.id,
      subscriptionThatExist.subscription_tour.id
    ).then((res) => {
      setMessage(res.data.result);
      setIsLoading(false);
    });
  };

  const scrollInto = (id) => {
    setTimeout(() => {
      const el = document.getElementById(id);
      if (id !== "clubs") {
        el.scrollIntoView({ behavior: "smooth", block: "end" });
      }
      if (id === "clubs") {
        el.scrollIntoView({ behavior: "smooth" });
      }
      if (id === "error") {
        el.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }, 400);
  };

  return (
    <div className="AssignedClub">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <Line />
      <Line className="AssignedClub__line" />
      {params.pathParam === undefined && (
        <PrimaryTitle
          title="Coffee clubs"
          className="AssignedClub__main-title"
        />
      )}
      {params.pathParam === undefined && (
        <div className="AssignedClub__title-wrapper">
          <h2 className="AssignedClub__title-desc">
            Let Our Experts Curate Great Coffees For You. Join One Of Our Coffee
            Clubs.
          </h2>
          <h2 className="AssignedClub__title-desc">
            {" "}
            A Monthly Subscription To Fresh Roasted Coffee.{" "}
          </h2>
          <h2 className="AssignedClub__title-desc">
            {" "}
            Simple. Easy. Convenient.{" "}
          </h2>
          <ul className="AssignedClub__subtitle-desc">
            <li>Pay As You Go.</li>
            <li>Cancel Anytime.</li>
            <li>All Coffee Fresh Roasted To Order.</li>
            <li>A Different Top Roaster Every Delivery.</li>
          </ul>
        </div>
      )}

      <>
        <div id="clubs" className="AssignedClub__clubs">
          {params.pathParam === undefined && (
            <>
              <div className="AssignedClub__bubble">
                How do Coffee Clubs work, you ask?
                <span
                  className="AssignedClub__bubble-link"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {" "}
                  Find Out How{" "}
                </span>
              </div>
              <img
                src="https://assets.gocoffeego.com/v2/assets/images/peaberry1.png"
                alt="prof peaberry"
                className="AssignedClub__prof-peaberry"
              />
            </>
          )}

          <CoffeeClubInfoModal
            isOpen={!isOpen}
            toggle={() => setIsOpen(!isOpen)}
          />
          {showCoffeeClubs && coffeeClubs.length !== 0
            ? coffeeClubs.map((club) => (
                <>
                  {club.id !== data.id && (
                    <div key={club.id} className="AssignedClub__club">
                      <div className="AssignedClub__cart">
                        <div className="AssignedClub__column-1">
                          <img
                            src={club.image.image_url}
                            className="AssignedClub__image"
                            alt=""
                          />
                          <h1
                            className="AssignedClub__learn-more"
                            onClick={() => {
                              setDescription({
                                active: !description.active,
                                id: club.id,
                              });
                            }}
                          >
                            Learn More
                          </h1>
                        </div>
                        <div className="AssignedClub__column-2">
                          <div className="AssignedClub__title-container">
                            <h2 className="AssignedClub__club-title">
                              {club.name}
                              <img
                                onClick={() =>
                                  setDescription({
                                    active: !description.active,
                                    id: club.id,
                                  })
                                }
                                className="AssignedClub__icon"
                                src={Icon}
                                alt="Assigned Club icon"
                              />
                            </h2>
                            <p className="AssignedClub__subtitle">
                              Select coffee plan and join the club
                            </p>
                          </div>

                          <SelectPlan
                            supremeCoffeeClubs={club.tier_1_plans}
                            ultimateCoffeeClubs={club.tier_2_plans}
                            onItemSelect={(description, price, id) =>
                              setNewSelectedPlan({
                                description: description,
                                price: price,
                                id: id,
                              })
                            }
                            selectedItem={
                              selectedClub === club.id && newSelectedPlan
                              /*   ? newSelectedPlan
                              : setNewSelectedPlan(club.tier_1_plans[0]) */
                            }
                            clubId={club.id}
                            setSelectedClub={setSelectedClub}
                            selectedClub={selectedClub}
                            label="Please select coffee plan"
                          />

                          <div className="Shipment">
                            <h2 className="Shipment__title">Shipments on:</h2>
                            <h2 className="Shipment__title">
                              {club.shipments_on}
                            </h2>
                          </div>
                          <div className="Shipment">
                            <h2 className="Shipment__title">Next Shipment:</h2>
                            <h2 className="Shipment__title">
                              {club.next_shipment}
                            </h2>
                          </div>
                        </div>
                        <div className="AssignedClub__column-3">
                          {subscriptionThatExist?.id === club.id ? (
                            <Button
                              className="AssignedClub__button"
                              onClick={() => {
                                setSelectedClub(club.id);
                                updateClub();
                              }}
                              label="Update the club"
                              isLoading={loading}
                              disabled={
                                loading ||
                                isEmpty(newSelectedPlan) ||
                                selectedClub !== club.id
                                  ? true
                                  : false
                              }
                              spinner={selectedClub === club.id ? true : false}
                              color={"#00d1d6"}
                            />
                          ) : (
                            <>
                              <Button
                                className="AssignedClub__button"
                                onClick={() => {
                                  setSelectedClub(club.id);

                                  /*   if (isEmpty(newSelectedPlan)) {
                                  setNewSelectedPlan(club.tier_1_plans[0]);
                                  joinAssignedClub(
                                    club.tier_1_plans[0]?.id,
                                    club.id
                                  ); */

                                  joinAssignedClub(newSelectedPlan.id, club.id);
                                }}
                                label="Join the club"
                                isLoading={loading}
                                disabled={
                                  loading ||
                                  isEmpty(newSelectedPlan) ||
                                  selectedClub !== club.id
                                    ? true
                                    : false
                                }
                                spinner={
                                  selectedClub === club.id ? true : false
                                }
                                color={"#00d1d6"}
                              />
                            </>
                          )}
                        </div>
                      </div>

                      {!isLoading &&
                        description.active &&
                        description.id === club.id && (
                          <div className="AssignedClub__description">
                            <p className="AssignedClub__text">{club.detail}</p>
                          </div>
                        )}
                    </div>
                  )}
                </>
              ))
            : null}
        </div>
        {!showCreditCards && !isLoading && (
          <div className="AssignedClub__cart">
            <div className="AssignedClub__column-1">
              <img
                src={data?.image?.image_url}
                className="AssignedClub__image"
                alt=""
              />
              <h1
                className="AssignedClub__learn-more"
                onClick={() => {
                  setDescription({
                    active: !description.active,
                    id: data.id,
                  });
                }}
              >
                Learn More
              </h1>
            </div>
            <div className="AssignedClub__column-2">
              {clubJoined ? (
                <h1 className="AssignedClub__title AssignedClub__title--green">
                  YOU HAVE SUCCESSFULLY JOINED TO {data.name}
                  <img
                    onClick={() =>
                      setDescription({
                        active: !description.active,
                        id: data.id,
                      })
                    }
                    className="AssignedClub__icon"
                    src={Icon}
                    alt="Assigned Club icon"
                  />
                </h1>
              ) : (
                [
                  subscriptionThatExist?.id ? (
                    <h1 className="AssignedClub__title">
                      YOU ARE ALREADY BEEN ASSIGNED TO {data.name}
                      <img
                        onClick={() =>
                          setDescription({
                            active: !description.active,
                            id: data.id,
                          })
                        }
                        className="AssignedClub__icon"
                        src={Icon}
                        alt="Assigned Club icon"
                      />
                    </h1>
                  ) : (
                    <h1 className="AssignedClub__title">
                      {params.pathParam === undefined
                        ? `${data?.name}`
                        : `YOU HAVE BEEN ASSIGNED TO ${data.name}`}
                      <img
                        onClick={() =>
                          setDescription({
                            active: !description.active,
                            id: data.id,
                          })
                        }
                        className="AssignedClub__icon"
                        src={Icon}
                        alt="Assigned Club icon"
                      />
                    </h1>
                  ),
                ]
              )}
              <div
                className="AssignedClub__price-range-question"
                onClick={() => setOpenModal(true)}
              >
                Why is there a price range?
              </div>
              <SelectPlan
                supremeCoffeeClubs={tier1plan}
                ultimateCoffeeClubs={tier2plan}
                onItemSelect={(description, price, id) =>
                  setSelectedPlan({
                    description: description,
                    price: price,
                    id: id,
                  })
                }
                selectedItem={selectedPlan}
                clubId={data.id}
                setSelectedClub={setSelectedClub}
                selectedClub={selectedClub}
                assignedClub
                label="Please select coffee plan"
              />

              <div className="Shipment">
                <h1 className="Shipment__title">Shipments on:</h1>
                <h1 className="Shipment__title">{data.shipments_on}</h1>
              </div>
              <div className="Shipment">
                <h1 className="Shipment__title">Next Shipment:</h1>
                <h1 className="Shipment__title">{data.next_shipment}</h1>
              </div>
              <div className="Buttons">
                <Link to="/pay-as-you-go">
                  <button className="Buttons__go-back">GO BACK</button>
                </Link>
                {params.pathParam !== undefined && (
                  <button
                    className="Buttons__all-clubs"
                    onClick={() => showAllClubs()}
                  >
                    SEE ALL CLUBS
                  </button>
                )}
              </div>
            </div>

            <div className="AssignedClub__column-3">
              {subscriptionThatExist?.id ? (
                <Button
                  className="AssignedClub__button"
                  onClick={() => updateClub()}
                  label="Update the club"
                  isLoading={loading}
                  disabled={loading || isEmpty(selectedPlan) ? true : false}
                  spinner={selectedClub === data.id ? true : false}
                  color={"#00d1d6"}
                />
              ) : (
                <Button
                  className="AssignedClub__button"
                  onClick={() => {
                    joinAssignedClub(selectedPlan.id);
                  }}
                  label="Join the club"
                  isLoading={loading}
                  disabled={loading || isEmpty(selectedPlan) ? true : false}
                  spinner={selectedClub === data.id ? true : false}
                  color={"#00d1d6"}
                />
              )}
            </div>
          </div>
        )}
        {!isLoading && description.active && description.id === data.id && (
          <div className="AssignedClub__description">
            <p className="AssignedClub__text">{data.detail}</p>
          </div>
        )}
        {message && (
          <div id="AssignedClub__message" className="AssignedClub__message">
            {message}
          </div>
        )}
        {error && (
          <div id="error" className="AssignedClub__error">
            {error}
          </div>
        )}

        {openModal && (
          <PriceRange
            closeIcon
            isOpen={openModal}
            toggle={() => setOpenModal(!openModal)}
          />
        )}
      </>
    </div>
  );
};

export default AssignedClub;
