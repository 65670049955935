import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CartItem from "../../components/Cart/CartItem/CartItem";
import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import SelectBox from "../../components/shared/SelectBox/SelectBox";
import Button from "../../components/shared/Button/Button";
import LinkButton from "../../components/shared/LinkButton/LinkButton";
import MoreCoffees from "../../components/AutoShip/MoreCoffees/MoreCoffees";
import { format } from "date-fns";
import * as API from "../../api/Api";
import moment from "moment";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import CartItemMobile from "../../components/Cart/CartItemMobile/CartItemMobile";
import AutoShipInfoModal from "../../components/Modals/AutoShipInfoModal/AutoShipInfoModal";

import "./AutoShip.scss";
import {
  autoshipHoldMessage,
  autoshipUpdatedMessage,
} from "../../lib/helpers/messages";
import { useMessageContext } from "../../lib/context/MessageModalContext/MessageModalContext";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";

const AutoShip = (props) => {
  const ScooterGirl =
    "https://assets.gocoffeego.com/v2/assets/images/scooter-girl1.png";
  const [autoshipData, setAutoShipData] = useState();
  const [creditCards, setCreditCards] = useState([]);
  const [activeTimePeriods, setActiveTimePeriods] = useState([]);
  const [shipmentsData, setShipmentsData] = useState([]);
  const [shipments, setShipments] = useState();
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [autoShipNextRun, setAutoShipNextRun] = useState();
  const [autoShipResumeOn, setAutoShipResumeOn] = useState(new Date());
  const [error, setError] = useState(false);
  const [autoshipUpdated, setAutoShipUpdated] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [holdDateError, setHoldDateError] = useState(false);
  const [selectedActiveTimePeriod, setActiveTimePeriod] = useState({
    id: 0,
    name: "Choose Frequency",
  });
  const [selectedHoldOption, setSelectedHoldOption] = useState({
    id: 1,
    name: "Hold indefinitely",
  });
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [loadingHoldSpinner, setLoadingHoldSpinner] = useState(false);
  const [combine, setCombine] = useState({
    active: false,
    id: null,
    shipmentNumber: null,
  });
  const [isOpen, setIsOpen] = useState(true);
  const history = useHistory();
  const isMobile = useResponsiveDimensions().isMobile;
  const isTablet = useResponsiveDimensions().isTablet;
  const autoShipActivated = props.location.autoshipActivated;
  const authCtx = useAuthContext();
  const defaultAddress = authCtx.customerData.default_address;
  const autoShipCard = authCtx.customerData.auto_ship_card;
  const cartId = localStorage.getItem("cart_id");
  const lastFourNumbers = autoShipCard?.last_four.slice(
    1,
    autoShipCard?.last_four.length
  );

  const msgCtx = useMessageContext();
  const seoCtx = useSeoContext();
  const location = useLocation();
  const pathname = location.pathname;

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url === pathname);

  //@TODO testing purpose
  // const auto_ship_status_id = 2;

  useEffect(() => {
    if (authCtx.isAuthenticated) {
      API.getCreditCards().then((res) => {
        setCreditCards(res.data);
      });
    }
  }, []);

  const handleDateChange = (value) => {
    const formatted = moment(value).format("MM/DD/yyyy");

    setAutoShipNextRun(formatted);
  };

  useEffect(() => {
    getAutoShips();
    if (authCtx.isAuthenticated) {
      authCtx.getCustomer();
    }
  }, []);

  const minimumDate = format(new Date(), "yyyy-MM-dd");

  const getAutoShips = () => {
    const cartId = localStorage.getItem("cart_id");

    API.getAutoShips(cartId).then((res) => {
      setShipmentsData(res.data.shipments);
      res?.data?.shipments?.forEach((item) => {
        setShipments(item);
      });

      setActiveTimePeriods(res.data.active_time_periods);
      setList(res.data.items);
      setAutoShipData(res.data);
      if (res.data.items) {
        setIsLoading(false);
      }
    });
  };

  const holdOptions = [
    { id: 1, name: "Hold Indefinitely" },
    { id: 2, name: "Hold Until Date" },
  ];

  const selectedCardForAutoShip = creditCards.find((item) => {
    return item.auto_ship_card === 1;
  });

  const handleSubmit = () => {
    if (!authCtx.isAuthenticated) {
      localStorage.setItem("route_from", "/auto-ship");
      // console.log("redirecting to login")
      history.push("/login");
      return;
    }
    if (selectedActiveTimePeriod.id > 1) {
      setError(false);
    }
    if (autoShipNextRun) {
      setDateError(false);
    }
    if (selectedActiveTimePeriod.id <= 0) {
      setError(true);
    } else if (autoShipNextRun === undefined) {
      setDateError(true);
    } else {
      setError(false);

      if (selectedCardForAutoShip === undefined) {
        localStorage.setItem(
          "selectedActiveTimePeriodId",
          selectedActiveTimePeriod.id
        );
        localStorage.setItem("autoShipNextRun", autoShipNextRun);
        history.push({
          pathname: "/auto-ship/checkout",
        });
      } else {
        setLoadingSpinner(true);
        API.updateAutoShip(0, selectedActiveTimePeriod.id, autoShipNextRun)
          .then((res) => {
            getAutoShips();
            // setAutoShipUpdated(true);
            msgCtx.handleSuccessMessage(autoshipUpdatedMessage);
            setLoadingSpinner(false);
          })
          .catch((err) => {
            setLoadingSpinner(false);
            msgCtx.handleError(err);
          });
      }
    }
  };

  const handleHold = () => {
    if (!authCtx.isAuthenticated) {
      localStorage.setItem("route_from", "/auto-ship");
      // console.log("redirecting to login")
      history.push("/login");
      return;
    }
    setLoadingHoldSpinner(true);
    if (selectedHoldOption.id === 1) {
      API.holdAutoShip(3, 0)
        .then(() => {
          setLoadingHoldSpinner(false);
          getAutoShips();
          msgCtx.handleSuccessMessage(autoshipHoldMessage);
        })
        .catch((err) => {
          setLoadingHoldSpinner(false);
          msgCtx.handleError(err);
        });
    } else {
      API.holdAutoShip(3, autoShipResumeOn, 1)
        .then(() => {
          setLoadingHoldSpinner(false);
          // setHoldUpdated(true);
          msgCtx.handleSuccessMessage(autoshipHoldMessage);
          setAutoShipUpdated(false);
          getAutoShips();
        })
        .catch((err) => {
          setLoadingHoldSpinner(false);
        });
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "white" : "pink",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? "lightblue" : "grey",
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (result.combine) {
      const selectedItem = Array.from(shipmentsData);
      selectedItem.splice(result.source.index, 1);
      setShipmentsData(selectedItem);

      const selectedId = shipmentsData[result.source.index]?.items[0]?.id;
      const numberPattern = /\d+/g;
      //   let productId = result.draggableId.match(numberPattern);
      //   let combineSipment = result.combine.draggableId.match(numberPattern);
      // let newCombineShipment = combineSipment[1];
      //  let selectedProductId = productId[0];

      API.updateAutoShipOrder(
        selectedId,
        result.combine.draggableId,
        cartId
      ).then((res) => {
        getAutoShips();
        setShipmentsData(res.data.shipments);
      });
    }

    if (result.destination) {
      const shipments = reorder(
        shipmentsData,
        result.source.index,
        result.destination.index
      );
      setShipmentsData(shipments);

      //get new shipment
      const newShipment =
        shipmentsData[result.destination.index]?.shipment_number;
      //get old shipment number
      const numberPattern = /\d+/g;
      let oldShipment = result.draggableId.match(numberPattern);
      if (oldShipment.length > 1) {
        oldShipment = oldShipment[oldShipment.length - 1];
      }

      API.reorderAutoShipOrder(oldShipment, newShipment, cartId).then((res) => {
        getAutoShips();
        setShipmentsData(res.data.shipments);
      });
      //  return;
    }
  };

  return (
    <div className="AutoShip">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <PrimaryTitle
        primaryClassName="AutoShip__primary-title"
        title="AUTO-SHIP"
      />
      <div className="AutoShip__content">
        {autoShipActivated === undefined ? (
          <>
            <div className="AutoShip__title-wrapper">
              <h2 className="AutoShip__title">
                Never run out of coffee again! You Line Them Up.
                <br /> We Ship Them Out!
              </h2>
              <ul className="AutoShip__subtitle">
                <li>
                  Add an unlimited amount of different roasters' coffees to your
                  Auto-Ship line-up and they will be automatically sent to you.
                </li>
                <li>
                  You can modify your order or suspend Auto-Ship at any time.
                </li>
                <li>
                  {" "}
                  Every 6th Auto-Ship, You Get Free Shipping!{" "}
                  <span className="AutoShip__start-today">Start Today</span>
                </li>
              </ul>
              <div className="AutoShip__more-info">
                <div className="AutoShip__bubble">
                  How does Auto-Ship work, you ask?
                  <span
                    className="AutoShip__bubble-link"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {" "}
                    Find Out How
                  </span>
                </div>
                <img
                  src="https://assets.gocoffeego.com/v2/assets/images/peaberry1.png"
                  alt="prof peaberry"
                  className="AutoShip__prof-peaberry"
                />
              </div>
              <AutoShipInfoModal
                isOpen={!isOpen}
                toggle={() => setIsOpen(!isOpen)}
              />
            </div>
          </>
        ) : (
          <h2 className="AutoShip__title-2 ">
            Congratulations, you have successfully activated auto-ship!
          </h2>
        )}

        {shipmentsData ? (
          <div className="Status">
            <p className="Status__text">
              Your current status is: {""}
              {autoshipData?.auto_ship_status_id === 1 ||
              autoshipData?.auto_ship_status_id === 2 ? (
                <span className="Status__text--bold">
                  ACTIVE - {autoshipData?.auto_ship_status}
                </span>
              ) : (
                <span> INACTIVE </span>
              )}{" "}
            </p>

            <p className="Status__text">
              Your next shipment processes on: {""}
              <span className="Status__text--bold">
                {autoshipData?.next_run_date}
              </span>
            </p>
          </div>
        ) : (
          <div className="Status">
            <p className="Status__text"> Your Auto-Ship is empty.</p>
          </div>
        )}

        {shipmentsData && (
          <h1 className="AutoShip__content-title">
            Coffees in your Auto-Ship{" "}
          </h1>
        )}
        <div className="AutoShip__container">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId="droppable"
              isCombineEnabled={combine.active}
            >
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <div>
                    {!isMobile && !isTablet && shipmentsData ? (
                      <div className="Items">
                        <div className="Items__row">
                          <div className="Items__menu">
                            <h1 className="Items__title">Shipment Number </h1>
                            <h1 className="Items__title Items__title--item">
                              Item{" "}
                            </h1>
                            <h1 className="Items__title">
                              Rearrange Shipment{" "}
                            </h1>
                            <h1 className="Items__title">Brew Style </h1>
                            <h1 className="Items__title">Price</h1>
                            <h1 className="Items__title">Qty </h1>
                          </div>
                        </div>

                        {shipmentsData?.map((data, index) => {
                          return (
                            <Draggable
                              index={index}
                              key={`${data.shipment_id}-${data.shipment_number}`}
                              draggableId={`${data.shipment_id}-${data.shipment_number}`}
                              // key={data.shipment_number}
                              // draggableId={data.shipment_number.toString()}
                              shouldRespectForcePress="true"
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {data.items.map((item) => (
                                    <CartItem
                                      shipmentsData={shipmentsData}
                                      setShipmentsData={(res) =>
                                        setShipmentsData(res)
                                      }
                                      shipmentNumber={data.shipment_number}
                                      autoShip
                                      refetchAutoShips={getAutoShips}
                                      itemId={item.id}
                                      key={data.shipment_number}
                                      quantity={item.quantity}
                                      coffee_grind={item?.coffee_grind}
                                      product={item.product}
                                      shipments={data?.items}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                      setCombine={setCombine}
                                      combine={combine}
                                      parent_id={item?.parent_id}
                                    />
                                  ))}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      </div>
                    ) : (
                      <>
                        {shipmentsData &&
                          shipmentsData.map((data, index) => {
                            return (
                              <Draggable
                                index={index}
                                key={data.shipment_number}
                                draggableId={data.shipment_number.toString()}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {data.items.map((item) => {
                                      return (
                                        <CartItemMobile
                                          shipmentsData={shipmentsData}
                                          setShipmentsData={(res) =>
                                            setShipmentsData(res)
                                          }
                                          autoShip
                                          shipmentNumber={data.shipment_number}
                                          refetchAutoShips={getAutoShips}
                                          itemId={item.id}
                                          key={item.id}
                                          quantity={item.quantity}
                                          coffee_grind={item?.coffee_grind}
                                          product={item.product}
                                          shipments={data?.items}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                          setCombine={setCombine}
                                          combine={combine}
                                          parent_id={item?.parent_id}
                                        />
                                      );
                                    })}
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                      </>
                    )}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {shipmentsData && (
            <div className="AutoShipForm">
              <h1 className="AutoShipForm__select-title">Choose Frequency</h1>

              <SelectBox
                className="AutoShipForm__select"
                onItemSelect={(name, id) => {
                  setActiveTimePeriod({ name: name, id: id });
                  setError(false);
                }}
                selectedItem={selectedActiveTimePeriod}
                items={activeTimePeriods}
              />

              {error && (
                <p className="AutoShip__required">Please choose frequency!</p>
              )}
              <h1 className="AutoShipForm__start-date">From Start Date</h1>
              <div className="AutoShipForm__date-wrap">
                {/*<DatePicker*/}
                {/*  className="AutoShipForm__datepicker"*/}
                {/*  selected={startDate}*/}
                {/*  onChange={(e) => setAutoShipNextRun(e.target.value)}*/}
                {/*/>*/}
                <input
                  className="AutoShipForm__datepicker"
                  type="date"
                  min={minimumDate}
                  onChange={(e) => {
                    if (minimumDate <= e.target.value) {
                      handleDateChange(e.target.value);
                      setDateError(false);
                    } else {
                      setDateError(true);
                      return;
                    }
                  }}
                />
              </div>
              {dateError && (
                <p className="AutoShip__required">Please choose start date!</p>
              )}

              <Button
                onClick={handleSubmit}
                label="LET'S GO"
                className="AutoShipForm__button"
                disabled={loadingSpinner || list?.length === 0}
                isLoading={loadingSpinner}
                spinner
                color={"#ffffff"}
              />

              {/*{autoshipUpdated && (*/}
              {/*  <p className="AutoShip__updated-message ">Auto-ship updated!</p>*/}
              {/*)}*/}

              <div className="AutoShipForm__or">
                <span className="or-line" />
                <p className="or-text"> or </p>
                <span className="or-line" />
              </div>

              <SelectBox
                className="AutoShipForm__select AutoShipForm__select--hold "
                onItemSelect={(name, id) =>
                  setSelectedHoldOption({ name: name, id: id })
                }
                selectedItem={selectedHoldOption}
                items={holdOptions}
              />
              {selectedHoldOption.id === 2 && (
                <input
                  min={minimumDate}
                  onChange={(e) => {
                    if (minimumDate <= e.target.value) {
                      const formatted = moment(e.target.value).format(
                        "MM/DD/yyyy"
                      );
                      setAutoShipResumeOn(formatted);
                      setHoldDateError(false);
                    } else {
                      setHoldDateError(true);
                      return;
                    }
                  }}
                  className="AutoShipForm__datepicker"
                  type="date"
                />
              )}
              {holdDateError && (
                <p className="AutoShip__required">Please choose date!</p>
              )}
              {/*{holdUpdated && (*/}
              {/*  <p className="AutoShip__updated-message ">Auto-ship updated!</p>*/}
              {/*)}*/}
              <Button
                onClick={handleHold}
                label="HOLD"
                className="AutoShipForm__button AutoShipForm__button--yellow "
                disabled={loadingHoldSpinner || list?.length === 0}
                isLoading={loadingHoldSpinner}
                spinner
                color={"#ffffff"}
              />

              {selectedCardForAutoShip !== undefined &&
                selectedCardForAutoShip && (
                  <>
                    <div className="ViewDetails">
                      <h1 className="ViewDetails__title">
                        {" "}
                        Shipping Address:{" "}
                        <Link
                          className="ViewDetails__title ViewDetails__title--link"
                          to="/account/address-book/auto-ship"
                        >
                          ( Change ){" "}
                        </Link>
                      </h1>
                      <div className="ViewDetails__content">
                        <p className="ViewDetails__description">
                          {defaultAddress?.address1}
                        </p>
                        <p className="ViewDetails__description">
                          {defaultAddress?.address2}
                        </p>
                        <p className="ViewDetails__description">
                          {defaultAddress?.city}, {defaultAddress?.zip}
                        </p>
                        <p className="ViewDetails__description">
                          {defaultAddress?.state?.name}
                        </p>
                        <p className="ViewDetails__description">
                          {defaultAddress?.country?.name}
                        </p>
                        <p className="ViewDetails__description">
                          {defaultAddress?.phone}
                        </p>
                      </div>
                    </div>
                    <div className="ViewDetails">
                      <h1 className="ViewDetails__title">
                        {" "}
                        Credit Card:{" "}
                        <Link
                          className="ViewDetails__title ViewDetails__title--link"
                          to="/account/credit-cards/auto-ship"
                        >
                          ( Change ){" "}
                        </Link>
                      </h1>
                      <div className="ViewDetails__content">
                        <p className="ViewDetails__description">
                          xxxx-xxxx-xxxx-x{lastFourNumbers}
                        </p>
                      </div>
                    </div>
                  </>
                )}
            </div>
          )}
        </div>
        {!shipmentsData && (
          <div className="Message__autoship">
            <img
              className="Cart__scooter-girl"
              src={ScooterGirl}
              alt="scooter girl"
            />
            <LinkButton
              label="Start Shopping"
              path="/shop"
              className="Cart__shopping-link"
            />
          </div>
        )}
      </div>

      <MoreCoffees />
    </div>
  );
};

export default AutoShip;
