import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import * as API from "../../api/Api";
import MemberPicksCard from "../../components/MemberPicks/MemberPicksCard/MemberPicksCard";
import Line from "../../components/shared/Line/Line";

import "./Reviews.scss";

const Reviews = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();

  const productId = params.pathParam;

  useEffect(() => {
    API.getReviewsForProduct(productId).then((res) => {
      setData(res.data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
  }

  return (
    <div className="Reviews">
      {data?.length === 0 && (
        <div className="Reviews__message">
          No reviews for this product.
          <div className="Reviews__description">
            {" "}
            To write a review, head to your
            <Link
              to={`/account/my-reviews`}
              className="Reviews__description--link"
            >
              {" "}
              personal page{" "}
            </Link>{" "}
            where you can rate and review all your fabulous future coffee
            purchases, or continue{" "}
            <Link to={`/shop`} className="Reviews__description--link">
              shopping.{" "}
            </Link>
          </div>
        </div>
      )}
      {data?.map((item) => (
        <div className="Reviews__review">
          <MemberPicksCard
            updatedAt={item?.updated_at}
            product={item?.product}
            review={item?.review}
            image={item?.image}
            customerName={item?.customer_name}
            customerCity={item?.customer_city}
          />
          <Line className="Reviews__line" />
        </div>
      ))}
    </div>
  );
};

export default Reviews;
