import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link, useLocation } from "react-router-dom";

import Filter from "../../components/Shop/Filter/Filter";
import Line from "../../components/shared/Line/Line";
import SectionTitle from "../../components/Cart/SectionTitle/SectionTitle";
import * as API from "../../api/Api";
import PeaberryRecommendsCard from "../../components/PeaberryRecommends/PeaberryRecommendsCard/PeaberryRecommendsCard";
import PreviuousPeaberryRecommends from "../../components/PeaberryRecommends/PreviuousPeaberryRecommends/PreviuousPeaberryRecommends";
import { useFilterProductsContext } from "../../lib/context/FilterProductsContext/FilterProductsContext";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";
import OrderLoader from "../../components/Loaders/OrderLoader/OrderLoader";

import "./PeaberryRecommends.scss";

const PeaberryRecommends = () => {
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const FiltersCtx = useFilterProductsContext();
  const seoCtx = useSeoContext();
  let history = useHistory();
  let params = useParams();
  let location = useLocation();
  const pathname = location.pathname;

  const pathParam1 = params.pathParam;
  const pathParam2 = params.pathParam2;

  useEffect(() => {
    API.getRecommendOfTheWeek().then((res) => {
      setItem(res.data);

      if (res.data) {
        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    FiltersCtx.getInitialProducts();

    return history.listen((location) => {
      if (
        history.action === "POP" &&
        location.pathname === "/peaberry-recommends"
      ) {
        history.push("/");
        FiltersCtx.setSelectedFilter([]);
      }
    });
  }, []);

  const foundSeo = seoCtx.pageMetadata.find(
    (el) =>
      el.url.toLowerCase() ===
      `/professor-peaberry/recommendations/${pathParam1}/${pathParam2}`
  );

  const foundSeoPeaberryRecommends = seoCtx.pageMetadata.find(
    (el) => el.url.toLowerCase() === "/professor-peaberry/recommendations"
  );

  const seoLinks = seoCtx.pageMetadata.filter((el) =>
    el.url.startsWith("/professor-peaberry/recommendations/")
  );

  const article = {
    title: "Professor Peaberry Recommends",
    image: "https://assets.gocoffeego.com/v2/assets/images/prof-peaberry-with-a-cup-new.png",
    url: "https://www.gocoffeego.com/peaberry-recommends"
  };

  return (
    <div className="PeaberryRecommends">
      <Line />
      <Line className="PeaberryRecommends__line" />
      {seoLinks &&
        seoLinks.map((item) => <Link key={item?.id} to={item?.url} />)}
      <div className="PeaberryRecommends__content">
        {foundSeo && (
          <Seo
            title={foundSeo?.title}
            description={foundSeo?.description}
            keywords={foundSeo?.keywords}
          />
        )}
        {foundSeoPeaberryRecommends && !pathParam1 && (
          <Seo
            title={foundSeoPeaberryRecommends?.title}
            description={foundSeoPeaberryRecommends?.description}
            keywords={foundSeoPeaberryRecommends?.keywords}
          />
        )}
        <Seo article={article} />
        <Filter peaberryRecommends className="PeaberryRecommends__filters" />
        {isLoading ? (
          <div className="PeaberryRecommends__card">
            <OrderLoader />
          </div>
        ) : (
          <div className="PeaberryRecommends__cards">
            {!isLoading && FiltersCtx.showLatestRecommend ? (
              <div className="PeaberryRecommends__latest-recommend">
                <SectionTitle
                  titleClassName="PeaberryRecommends__title"
                  title="PROFESSOR PEABERRY RECOMMENDS"
                  titleTag="h1"
                />
                <div className="PeaberryRecommends__card">
                  <PeaberryRecommendsCard
                    peaberryRecommends
                    updatedAt={item?.updated_at}
                    product={item?.product}
                    review={item?.review}
                    image={item?.image}
                  />
                </div>
              </div>
            ) : (
              <SectionTitle
                titleClassName="PeaberryRecommends__title2"
                title="PREVIOUS PEABERRY RECOMMENDS"
              />
            )}
            <PreviuousPeaberryRecommends />
          </div>
        )}
      </div>
    </div>
  );
};

export default PeaberryRecommends;
