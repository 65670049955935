import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import RadioButton from "./RadioButton/RadioButton";
import Button from "../../shared/Button/Button";
import Collapsible from "./Collapsible/Collapsible";
import Story from "../Story/Story";
import Characteristics from "./Characteristics/Characteristics";
import SelectBox from "../../shared/SelectBox/SelectBox";
import RoasterArticle from "../RoasterArticle/RoasterArticle";
import * as API from "../../../api/Api";
import { useCartContext } from "../../../lib/context/CartContext/CartContext";
import { useMessageContext } from "../../../lib/context/MessageModalContext/MessageModalContext";

import "./ProductDetails.scss";

const ProductDetails = (props) => {
  const {
    product,
    roasterId,
    purveyorSlug,
    productSize,
    productIsLoading,
  } = props;
  const [reviews, setReviews] = useState([]);
  const [reviewsLoading, setReviewsLoading] = useState(true);
  const [size, setSize] = useState(productSize);

  const Stars =
    "https://assets.gocoffeego.com/v2/assets/images/star-rating.png";

  const [selectedGrindOption, setSelectedGrindOption] = useState({
    name: "Whole Bean",
    id: 1,
  });
  const [isLoading, setIsLoading] = useState({ autoship: false, cart: false });
  const msgCtx = useMessageContext();

  const location = {
    pathname: `/roaster/${purveyorSlug}-${roasterId}`,
    state: { purveyorId: roasterId },
  };

  const params = useParams();
  const productId = params.pathParam2.match(/\b(\d+)\b(?=[^\d]*$)/)[0];

  useEffect(() => {
    API.getReviewsForProduct(productId).then((res) => {
      setReviews(res.data);
      setReviewsLoading(false);
    });
  }, []);

  const cartCtx = useCartContext();
  const purveyourId = product?.purveyor?.id;
  const history = useHistory();

  const purchaseOptions = [
    { id: 1, name: "One-time purchase" },
    { id: 2, name: "Auto-ship" },
  ];

  const handleAddToCart = () => {
    const cartId = localStorage.getItem("cart_id");

    const data = {
      cart_id: cartId,
      product_id: product.id,
      coffee_grind_id: selectedGrindOption.id,
    };
    setIsLoading({ cart: true });

    if (!cartId) {
      API.addItemToCart(data).then((res) => {
        localStorage.setItem("cart_id", res.data.cart_id);
        cartCtx.getCartItems();
        setIsLoading({ cart: false });
        cartCtx.toggleCartModal();
        window.gtag("event", "conversion", {
          send_to: "AW-11011335274/p2AmCJON9IAYEOrIzoIp",
        });
      });
    } else {
      API.addItemToCart(data).then((res) => {
        cartCtx.getCartItems();
        setIsLoading({ cart: false });
        cartCtx.toggleCartModal();
        window.gtag("event", "conversion", {
          send_to: "AW-11011335274/p2AmCJON9IAYEOrIzoIp",
        });
        window.fbq("track", "AddToCart", {
          content_ids: product.id,
          content_name: product.name,
          currency: "USD",
          value: product.retail_price,
        });
      });
    }
  };

  const handleAddToAutoShip = () => {
    const cartId = localStorage.getItem("cart_id");
    const data = {
      cart_id: cartId,
      purveyor_id: purveyourId,
      product_id: product.id,
      coffee_grind_id: selectedGrindOption.id,
    };
    setIsLoading({ autoship: true });
    API.addItemToAutoShip(data).then((res) => {
      localStorage.setItem("cart_id", res.data.cart_id);
      setIsLoading({ autoship: false });
      history.push("/auto-ship");
    });
  };

  const isMobile = useResponsiveDimensions().isMobile;

  return (
    <div className="ProductDetails">
      <Link className="ProductDetails__title1-container" to={location}>
        <h2 className="ProductDetails__title1 font-cairo">
          {product?.purveyor?.name}{" "}
        </h2>
      </Link>
      <h1 className="ProductDetails__title2">{product?.name}</h1>
      {product?.slug !== "re-shipping-padded-envelope" && (
        <div className="ProductDetails__reviews">
          <img className="ProductDetails__stars" src={Stars} alt="Stars" />
          {!reviewsLoading && reviews.length !== 0 ? (
            <Link
              to={`/reviews/${product.id}`}
              className="ProductDetails__reviews-button"
            >
              [READ REVIEWS]
            </Link>
          ) : (
            <Link
              to={`/reviews/${product.id}`}
              className="ProductDetails__reviews-button"
            >
              [WRITE REVIEW]
            </Link>
          )}
        </div>
      )}

      <div className="ProductDetails__short-message font-cairo">
        {product?.short_message}
      </div>
      <div className="ProductDetails__short-message ProductDetails__short-message--2 font-cairo">
        {product?.short_message_2}
      </div>

      <div className="ProductDetails__price font-cairo">
        {product?.retail_price && (
          <span
            className={
              product?.sale?.active
                ? "ProductDetails__retail-price--sale"
                : "ProductDetails__retail-price"
            }
          >
            {`$${product?.retail_price?.toFixed(2)} `}
          </span>
        )}
        {product?.sale?.active && product?.sale_price && (
          <span className="ProductDetails__price--sale">
            {`  $${product?.sale_price.toFixed(2)} `}
          </span>
        )}
        <span> / {product.weight}</span>
      </div>
      {product?.slug !== "re-shipping-padded-envelope" && (
        <div className="SelectGrinds">
          <div className="ProductDetails__title3 font-cairo">SELECT GRIND</div>
          <SelectBox
            onItemSelect={(name, id) =>
              setSelectedGrindOption({ name: name, id: id })
            }
            selectedItem={selectedGrindOption}
            items={product?.coffee_grinds}
          />
          <div className="ProductDetails__line" />
        </div>
      )}

      <div>
        {product?.other_sizes && (
          <div className="PurchaseOptions__other-sizes-container">
            <p className="PurchaseOptions__other-sizes"> Other Sizes </p>
            <div className="PurchaseOptions__items">
              <RadioButton
                selectedItem={size === undefined ? productSize : size}
                onClick={(name) => {
                  props.setProductIsLoading(true);
                  setSize(name);
                  history.push(`/${product.product_url}`);
                }}
                name={product.weight}
                id={product.weight}
              />
              {product?.other_sizes?.map((product, index) => (
                <RadioButton
                  selectedItem={size === undefined ? productSize : size}
                  onClick={(name) => {
                    props.setProductIsLoading(true);
                    setSize(name);
                    history.push(`/${product.product_url}`);
                  }}
                  name={product.weight}
                  id={product.weight}
                  key={index}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="ProductDetails__buttons">
        <Button
          onClick={handleAddToCart}
          className="ProductDetails__cart-button"
          isLoading={isLoading.cart}
          disabled={isLoading.cart || productIsLoading ? true : false}
          label="ADD TO CART"
          spinner
          color={"#ffffff"}
        />

        {product?.slug !== "re-shipping-padded-envelope" && (
          <>
            <h2 className="ProductDetails__or"> or </h2>

            <Button
              onClick={handleAddToAutoShip}
              className="ProductDetails__cart-button"
              isLoading={isLoading.autoship}
              disabled={isLoading.autoship || productIsLoading ? true : false}
              label="ADD TO AUTO-SHIP"
              spinner
              color={"#ffffff"}
            />
          </>
        )}
      </div>

      {!isMobile ? (
        <Characteristics product={product} />
      ) : (
        <div>
          <Collapsible name="Characteristics">
            <Characteristics product={product} />
          </Collapsible>
          <Collapsible name="Story">
            <Story product={product} productIsLoading={productIsLoading} />
          </Collapsible>
          <Collapsible name="Roaster Detail">
            <RoasterArticle
              productIsLoading={productIsLoading}
              purveyor={product?.purveyor}
            />
          </Collapsible>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
