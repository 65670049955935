import React, { useEffect, useState } from "react";

import MiddleLine from "../../shared/MiddleLine/MiddleLine";
import RockstarProduct from "../RockstarProduct/RockstarProduct";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import * as API from "../../../api/Api";
import FlickitySlider from "../../FlickitySlider/FlickitySlider";

import "./RockstarCoffes.scss";

const RockstarCoffes = () => {
  const [products, setProducts] = useState([]);
  const isMobile = useResponsiveDimensions().isMobile;
  const RockstarIlo =
    "https://assets.gocoffeego.com/v2/assets/images/rockstar-ilo.svg";

  useEffect(() => {
    API.getRockstarCoffees().then((res) => setProducts(res.data));
  }, []);

  let options = {
    draggable: false,
    freeScroll: false,
    contain: true,
    groupCells: true,
    pageDots: false,
    prevNextButtons: isMobile ? true : false,
  };

  return (
    <div className="RockstarCoffes">
      <MiddleLine />
      <h2 className="RockstarCoffes__title"> ROCKSTAR COFFEES </h2>

      <div className="RockstarCoffes__slider">
        <FlickitySlider options={options}>
          {products.map((item) => (
            <RockstarProduct
              key={item.id}
              id={item.id}
              slug={item.slug}
              image={item.main_image.sm}
              url={item.url}
              name={item.name}
              largeImage={item.main_image.xlg}
            />
          ))}
        </FlickitySlider>
        <img
          src={RockstarIlo}
          alt="rockstar-ilo"
          className="RockstarCoffes__man"
        />
      </div>
    </div>
  );
};

export default RockstarCoffes;
