import React from "react";
import { Link } from "react-router-dom";

import LinkButton from "../../components/shared/LinkButton/LinkButton";
import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import HowSubscriptionsWork from "../../components/Subscribe/HowSubscriptionsWork/HowSubscriptionsWork";
import PeopleReview from "../../components/Home/PeopleReview/PeopleReview";

import { useResponsiveDimensions } from "../../lib/hooks/useResponsiveDimensions";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";

import "./Subscribe.scss";

const Subscribe = () => {
  const isTV = useResponsiveDimensions().isTV;
  const isMobile = useResponsiveDimensions().isMobile;
  const Video = "https://assets.gocoffeego.com/v2/assets/video/video.mp4";
  const Stocksy = "https://assets.gocoffeego.com/v2/assets/images/stocksy.png";
  const PeopleMobile =
    "https://assets.gocoffeego.com/v2/assets/images/subscription-jet-set.png";
  const seoCtx = useSeoContext();

  const makeMuted = (elt) => {
    if (elt) {
      elt.muted = true;
    }
  };

  const foundSeo = seoCtx.pageMetadata.find(
    (el) => el.url === "/coffee-delivery"
  );
  return (
    <div className="Subscribe">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <PrimaryTitle
        primaryClassName="Subscribe__primary-container"
        className="Subscribe__primary-title"
        title="TWO GROOVY WAYS TO SUBSCRIBE"
      />
      <h2 className="Subscribe__sign-up">
        Sign up for yourself or give a subscription as a gift
      </h2>
      <h3 className="Subscribe__paragraph">
        All coffee clubs are whole beans only
      </h3>
      <div className={isTV ? "container" : ""}>
        <div className="Subscribe__container">
          <div className="Subscribe__subscription1">
            <div className="Subscribe__content">
              <div className="Subscribe__number">1</div>
              <h2 className="Subscribe__title"> PAY AS YOU GO CLUBS</h2>
              <p className="Subscribe__description">
                If flexibility is key, choose from one of our 6 pay-as-you-go
                clubs and pay only when your coffee ships. Cancel or change your
                club at any time. Take our quiz and find your perfect club.
              </p>
              <LinkButton
                path="/pay-as-you-go"
                className="Subscribe__button"
                label="EXPLORE CLUBS"
              />
            </div>
            <Link to="/pay-as-you-go">
              <div className="Subscribe__video-container">
                <video
                  type="video/mp4"
                  playsInline
                  autoPlay
                  loop
                  ref={makeMuted}
                >
                  <source src={Video} type="video/mp4"></source>
                </video>
              </div>
            </Link>
          </div>

          <div
            className={
              isMobile
                ? "Subscribe__subscription2-mobile"
                : "Subscribe__subscription2"
            }
          >
            <Link to="/coffee-gifts">
              <img
                src={PeopleMobile}
                className="Subscribe__people"
                alt="people"
              />
            </Link>

            <img src={Stocksy} className="Subscribe__stocksy" alt="stocksy" />
            <div className="Subscribe__content">
              <div className="Subscribe__number">2</div>
              <h2 className="Subscribe__title">
                {" "}
                PRE-PAID COFFEE SUBSCRIPTIONS
              </h2>
              <p className="Subscribe__description">
                Choose one of our 10 world-class coffee subscriptions and pay up
                front for 3, 6 or 12 months worth of deliveries. Jetsetting has
                never been more convenient.
              </p>
              <LinkButton
                className="Subscribe__button"
                label="EXPLORE SUBSCRIPTIONS"
                path="/coffee-gifts"
              />
              <Link to="/coffee-gifts">
                <div className="Subscribe__people-container">
                  <img
                    src={PeopleMobile}
                    className="Subscribe__people-mobile"
                    alt="people"
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="Subscribe__how-it-works">
          <HowSubscriptionsWork />
        </div>
        <PeopleReview />
      </div>
    </div>
  );
};

export default Subscribe;
