import React, { useState } from "react";

import PopUp from "../../shared/PopUp/PopUp";
import SubscriptionDetailsModal from "../../Modals/SubscriptionDetailsModal/SubscriptionDetailsModal";
import RadioButton from "../../ProductPage/ProductDetails/RadioButton/RadioButton";

import cs from "classnames";

import "./PrePaidSubscription.scss";
import Button from "../../shared/Button/Button";

const PrePaidSubscription = (props) => {
  const {
    image,
    id,
    selectedSubscriptionId,
    name,
    shortMessage,
    detail,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="PrePaidSubscription">
      <SubscriptionDetailsModal
        name={name}
        shortMessage={shortMessage}
        detail={detail}
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
      />

      <div
        onClick={() => props.onClick()}
        className="PrePaidSubscription__image-container"
      >
        <PopUp
          prePaid
          prePaidName={name}
          PrePaidMessage={shortMessage}
          PrePaidDetail={detail}
          handleClick={() => props.onClick()}
        />
        {/*    <RadioButton
          onClick={() => props.onClick()}
          className="PrePaidSubscription__radio-button"
          selectedItem={selectedSubscriptionId}
          name=""
          id={id}
        /> */}
        <img
          className={cs(
            "PrePaidSubscription__image",
            selectedSubscriptionId === id &&
              "PrePaidSubscription__image--selected"
          )}
          src={image}
          alt="subscription"
        />
      </div>
      <div className="PrePaidSubscription__buttons-container">
        <Button
          onClick={() => setIsOpen(!isOpen)}
          label="See Tour"
          className="PrePaidSubscription__button-tour"
        />
        <Button
          onClick={() => props.onClick()}
          label="Order Now"
          className="PrePaidSubscription__button-order"
        />
      </div>
    </div>
  );
};

export default PrePaidSubscription;
