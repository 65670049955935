import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import ContactUsForm from "../../components/ContactUsForm/ContactUsForm";
import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";

import "./ContactUs.scss";

const ContactUs = () => {
  const [inputSelected, setInputSelected] = useState(0);
  const [reasonError, setReasonError] = useState("");
  const seoCtx = useSeoContext();
  const location = useLocation();
  const pathname = location.pathname;

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url === pathname);

  return (
    <div className="ContactUs">
      <PrimaryTitle title="CONTACT US" />
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}

      <h1 className="ContactUs__title">Please select a topic below:</h1>

      <div className="ContactUs__container">
        <div className="ContactUs__left-container">
          <div className="ContactUs__leftContent-container">
            <div className="ContactUs__image-container">
              <img
                className="ContactUs__image"
                src="https://assets.gocoffeego.com/vader/customers/images/Contact-us/contact-us.jpg"
                alt="phone"
              />
            </div>
            <p className="ContactUs__text1">
              Thank you for contacting us. Don't fret, we will get back to you
              within one day during business hours.
            </p>
            <p className="ContactUs__text2">
              GoCoffeeGo <br />
              2027 Fillmore Street <br />
              San Francisco, CA 94115
            </p>
          </div>
        </div>
        <div className="ContactUs__right-container">
          <div className="ContactUs__rightContent-container">
            <div className="ContactUs__checkbox-container">
              <div className="ContactUs__checkbox-div">
                <input
                  onChange={() => setInputSelected(1)}
                  name="radioinputs"
                  className="ContactUs__checkbox-input"
                  type="radio"
                  id="heart"
                />
                <img
                  className="ContactUs__checkbox-icon"
                  src="https://assets.gocoffeego.com/vader/customers/images/Contact-us/heart.svg"
                  alt="heart"
                />
                <label className="ContactUs__checkbox-label" for="heart">
                  I want to express my love
                </label>
              </div>

              <div className="ContactUs__checkbox-div">
                <input
                  onChange={() => setInputSelected(2)}
                  name="radioinputs"
                  className="ContactUs__checkbox-input"
                  type="radio"
                  id="sad"
                />
                <img
                  className="ContactUs__checkbox-icon"
                  src="https://assets.gocoffeego.com/vader/customers/images/Contact-us/sad.svg"
                  alt="sad face"
                />
                <label className="ContactUs__checkbox-label" for="sad">
                  Loss / Damage / Problem with product
                </label>
              </div>

              <div className="ContactUs__checkbox-div">
                <input
                  onChange={() => setInputSelected(3)}
                  name="radioinputs"
                  className="ContactUs__checkbox-input"
                  type="radio"
                  id="question"
                />
                <img
                  className="ContactUs__checkbox-icon"
                  src="https://assets.gocoffeego.com/vader/customers/images/Contact-us/question.svg"
                  alt="question mark"
                />
                <label className="ContactUs__checkbox-label" for="question">
                  Question about my order
                </label>
              </div>

              <div className="ContactUs__checkbox-div">
                <input
                  onChange={() => setInputSelected(4)}
                  name="radioinputs"
                  className="ContactUs__checkbox-input"
                  type="radio"
                  id="angry"
                />
                <img
                  className="ContactUs__checkbox-icon"
                  src="https://assets.gocoffeego.com/vader/customers/images/Contact-us/angry.svg"
                  alt="angry face"
                />
                <label className="ContactUs__checkbox-label" for="angry">
                  I want to speak to someone NOW,NOW,NOW!
                </label>
              </div>

              <div className="ContactUs__checkbox-div">
                <input
                  onChange={() => setInputSelected(5)}
                  name="radioinputs"
                  className="ContactUs__checkbox-input"
                  type="radio"
                  id="star"
                />
                <img
                  className="ContactUs__checkbox-icon"
                  src="https://assets.gocoffeego.com/vader/customers/images/Contact-us/star.svg"
                  alt="star"
                />
                <label className="ContactUs__checkbox-label" for="star">
                  Other
                </label>
              </div>
            </div>
            <h1 className="ContactUs__reasonError"> {reasonError}</h1>
            <ContactUsForm
              reasonError={(err) => {
                if (err) {
                  setReasonError("Please select one of the topics");
                } else {
                  setReasonError("");
                }
              }}
              inputSelected={inputSelected}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
