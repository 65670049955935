import React from "react";

import "./GiftIntro.scss";

const GiftIntro = () => {
  const makingCoffee =
    "https://assets.gocoffeego.com/v2/assets/images/makingCoffee.png";
  const gift =
    "https://assets.gocoffeego.com/v2/assets/images/gift.png";
  return (
    <div className="GiftIntro">
      <img
        src={makingCoffee}
        alt="Making coffee"
        className="GiftIntro__coffee-img"
      />
      <div className="GiftIntro__line1"></div>
      <div className="GiftIntro__line2"></div>
      <div className="GiftIntro__title-wrap">
        <h1 className="GiftIntro__title"> Giving is the best buzz </h1>
      </div>
      <div className="GiftIntro__text">
        With a variety of gifting options, we have something for everyone.
      </div>
      <img src={gift} alt="Gift box" className="GiftIntro__gift-img" />
    </div>
  );
};

export default GiftIntro;
