import React from "react";
import SpecialBannerLoader from "../../Loaders/SpecialBannerLoader/SpecialBannerLoader";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import { useFilterProductsContext } from "../../../lib/context/FilterProductsContext/FilterProductsContext";
import cs from "classnames";
import Special from "../Special/Special";

import "./Banner.scss";

const Banner = (props) => {
  const { className } = props;
  const isMobile = useResponsiveDimensions().isMobile;
  const productCtx = useFilterProductsContext();
  let specials = productCtx.specials;
  let isLoading = productCtx.isLoading;

  // let options = {
  //   draggable: false,
  //   freeScroll: false,
  //   contain: true,
  //   groupCells: false,
  //   pageDots: false,
  //   prevNextButtons: false,
  //   autoPlay: true,
  // };

  return (
    <div className={cs("Banner", className)}>
      <div className="Banner__title-wrapper">
        <span className="Banner__title-white-line" />
        <h1 className="Banner__title">Specials</h1>
        <span className="Banner__title-white-line" />
      </div>
      {isLoading ? (
        <div className="Banner__loaders">
          {Array(isMobile ? 1 : 4)
            .fill(0)
            .map((item) => (
              <SpecialBannerLoader />
            ))}
        </div>
      ) : (
        <>
          {specials && specials[0]?.purveyor?.length > 1 && (
            <h2 className="Banner__spacial-title">{specials[0].name}</h2>
          )}
          <div className="Banner__container">
            {specials &&
              specials.map((item) => <Special key={item.id} item={item} />)}
          </div>
        </>
      )}
    </div>
  );
};

export default Banner;
