import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import * as API from "../../api/Api";

import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import CreditAndBillingAddress from "../../components/Checkout/CreditAndBillingAddress/CreditAndBillingAddress";
import SelectCard from "../../components/Checkout/CreditCardForm/SelectCard/SelectCard";
import { useMessageContext } from "../../lib/context/MessageModalContext/MessageModalContext";

import "./AutoShipCheckout.scss";
import { autoshipUpdatedMessage } from "../../lib/helpers/messages";
import { autoshipActivatedMessage } from "../../lib/helpers/messages";

const AutoShipCheckout = (props) => {
  const autoShipActivated = props.location.autoshipActivated;
  const authCtx = useAuthContext();
  const history = useHistory();
  const [creditCards, setCreditCards] = useState([]);
  const [showCreditCardForm, setShowCreditCardForm] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const msgCtx = useMessageContext();

  useEffect(() => {
    API.getCreditCards().then((res) => {
      let filteredCreditCards = res?.data.filter(
        (item) => item?.customer_address?.country?.id === 64
      );
      if (filteredCreditCards?.length === 0) {
        setShowCreditCardForm(true);
      } else {
        setShowCreditCardForm(false);
      }
      setCreditCards(filteredCreditCards);
    });
  }, []);

  // call endpoint to select card as autoship
  const selectCardAsAutoShip = (cardId) => {
    const cartId = localStorage.getItem("cart_id");
    const autoShipNextRun = localStorage.getItem("autoShipNextRun");
    const selectedActiveTimePeriodId = localStorage.getItem(
      "selectedActiveTimePeriodId"
    );
    setIsLoading(true);

    API.selectCardAsAutoShip(cardId).then((res) => {
      if (res.status === 200) {
        // history.push({
        //   pathname: "/auto-ship",
        //   autoshipActivated: true,
        // });

        msgCtx.handleSuccessMessage(autoshipActivatedMessage);

        API.updateAutoShip(0, selectedActiveTimePeriodId, autoShipNextRun).then(
          (res) => {
            setShowCreditCardForm(false);
            authCtx.getCustomer();
            history.push({
              pathname: "/auto-ship",
              // autoshipActivated: true,
            });
            msgCtx.handleSuccessMessage(autoshipUpdatedMessage);
            API.getAutoShips(cartId);
          }
        );
        setShowCreditCardForm(false);
        setIsLoading(false);
      }
    });
    setShowCreditCardForm(false);
  };

  return (
    <div className="AutoShipCheckout">
      <PrimaryTitle
        primaryClassName="AutoShipCheckout__primary-title"
        title="AUTO-SHIP"
      />

      <div className="AutoShipCheckout__content">
        {autoShipActivated === undefined ? (
          <>
            <h1 className="AutoShipCheckout__title">
              One more step to activate your auto-ship!
            </h1>
            <h1 className="AutoShipCheckout__title AutoShipCheckout__title--second">
              Select address & credit card
            </h1>{" "}
          </>
        ) : (
          <h1 className="AutoShipCheckout__title-2 ">
            Congratulations, you have successfully activated auto-ship!
          </h1>
        )}

        <div className="AutoShipCheckout__form">
          <div className="AutoShipCheckout__form-test">
            {creditCards?.length > 0 && !showCreditCardForm && (
              <>
                <div className="AutoShipCheckout__select">
                  <h1 className="PaymentMethod__title">
                    Select saved credit cards
                  </h1>
                  <button
                    onClick={() => {
                      setShowCreditCardForm(true);
                    }}
                    className="Checkout__change-button"
                  >
                    Add new credit card
                  </button>
                </div>

                <SelectCard
                  onItemSelect={(item) => {
                    setSelectedCard(item);
                    // setShowCreditCardForm(false);
                  }}
                  selectedItem={selectedCard && selectedCard}
                  items={creditCards}
                  autoShip
                />
                <button
                  className="AutoShipCheckout__button4"
                  type="button"
                  disabled={isLoading ? true : false}
                  onClick={() => selectCardAsAutoShip(selectedCard.id)}
                >
                  {isLoading ? (
                    <Spinner
                      style={{
                        border: "0.25em solid #fffff",
                        borderRightColor: "transparent",
                        width: "2.5rem",
                        height: "2.5rem",
                      }}
                    />
                  ) : (
                    "Set Auto-ship card"
                  )}
                </button>
              </>
            )}

            {showCreditCardForm && (
              <CreditAndBillingAddress
                onSubmit={(cardId) => {
                  selectCardAsAutoShip(cardId);
                }}
                autoShip
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoShipCheckout;
