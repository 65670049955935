import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as API from "../../api/Api";
import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";

import "./Music.scss";

const Music = () => {
  const [music, setMusic] = useState([]);
  const seoCtx = useSeoContext();
  const location = useLocation();
  const pathname = location.pathname;

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url === pathname);

  useEffect(() => {
    API.getMusic().then((res) => setMusic(res.data));
  }, []);

  return (
    <div className="Music">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      <PrimaryTitle title="Music" className="Music__main-title" />
      <h2 className="Music__subtitle">GoCoffeeGo Playlist</h2>
      <p className="Music__subtitle-text">
        To enjoy, click on and listen to our music player at the top of the
        browser.
      </p>
      <div className="Music__container">
        {music &&
          music.map((item) => (
            <div className="Music__wrapper" key={item.id}>
              <img src={item.image} alt="music" className="Music__image" />
              <div className="Music__description">
                <div className="Music__song-title">{item.title}</div>
                <div>
                  Artist:
                  <span className="Music__song-artist">{item.artist}</span>
                </div>
                <div>
                  Album:
                  <span className="Music__song-album">{item.album}</span>
                </div>
                {item?.available_at && (
                  <div>
                    {" "}
                    Available at:
                    <span className="Music__song-available">
                      {" "}
                      {item?.available_at}
                    </span>
                  </div>
                )}
                {item?.available_at_url && (
                  <div>
                    Purchase at:
                    <span className="Music__song-available">
                      {" "}
                      {item?.available_at_url}{" "}
                    </span>
                  </div>
                )}
                {item?.contact && (
                  <div>
                    Contact:
                    <a href={item?.contact} className="Music__song-contact">
                      {" "}
                      {item?.contact}
                    </a>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Music;
