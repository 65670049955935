import React, { useState, useEffect } from "react";
import * as API from "../../../api/Api";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";
import { useHistory, Link } from "react-router-dom";
import cs from "classnames";
import FlickitySlider from "../../FlickitySlider/FlickitySlider";

import ShippingInfoModal from "../../Modals/ShippingInfoModal/ShippingInfoModal";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";

import "./TopHeader.scss";

const TopHeader = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const isMobile = useResponsiveDimensions().isMobile;
  const history = useHistory();
  const shippingInfoIcon =
    "https://assets.gocoffeego.com/v2/assets/icons/shipping-info-yellow.svg";

  const AuthCtx = useAuthContext();
  const user = AuthCtx.user;

  useEffect(() => {
    API.getTopBarMessages().then((res) => {
      setMessages(res.data);
    });
  }, []);

  let options = {
    draggable: false,
    freeScroll: false,
    contain: true,
    groupCells: false,
    pageDots: false,
    prevNextButtons: false,
    autoPlay: 3000,
    wrapAround: true,
    adaptiveHeight: true,
    fade: true,
  };

  return (
    <div className={cs("TopHeader", props.small && "TopHeader--hide")}>
      <ShippingInfoModal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} />

      <div className="TopHeader__button-container">
        {user && (
          <div className="TopHeader__userName">
            <span>Hi, </span>
            <span
              className="TopHeader__userName-link"
              onClick={
                !user?.guest ? () => history.push("/account") : undefined
              }
            >
              {user?.first_name}
            </span>
          </div>
        )}
        {!isMobile && (
          <button
            className="TopHeader__button"
            onClick={() => setIsOpen(!isOpen)}
          >
            Shipping Info
            <img
              className="TopHeader__icon"
              src={shippingInfoIcon}
              alt="icon"
            />
          </button>
        )}
      </div>
      <div className="TopHeader__text-container">
        <FlickitySlider options={options}>
          {messages &&
            messages.map((item, index) => (
              <Link
                key={index}
                to={
                  item?.url.startsWith("https://www.gocoffeego.com/")
                    ? item?.url?.replace("https://www.gocoffeego.com/", "/")
                    : "/"
                }
                className="TopHeader__text"
              >
                {!item?.text?.startsWith("https://www.gocoffeego.com/") && (
                  <div>{item?.text}</div>
                )}
              </Link>
            ))}
        </FlickitySlider>
      </div>
    </div>
  );
};

export default TopHeader;
