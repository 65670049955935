import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import TakeQuiz from "../../components/Home/TakeQuiz/TakeQuiz";
import Line from "../../components/shared/Line/Line";
import CoffesByRoast from "../../components/Home/CoffesByRoast/CoffesByRoast";
import ChooseCoffes from "../../components/Home/ChooseCoffes/ChooseCoffes";
import Herobanner from "../../components/Home/HeroBanner/HeroBanner";
import BrilliantRecommendations from "../../components/Home/BrilliantRecommendations/BrilliantRecommendations";
import RockstarCoffes from "../../components/Home/RockstarCoffes/RockstarCoffes";
import Subscribe from "../../components/Home/SubscribeSection/SubscribeSection";
import WhyChooseGoCoffeGo from "../../components/WhyChooseGoCoffeGo/WhyChooseGoCoffeGo";
import Coffe101 from "../../components/Coffe101/Coffe101";
import PeopleReview from "../../components/Home/PeopleReview/PeopleReview";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import Seo from "../../lib/helpers/SEO/Seo";
import * as API from "../../api/Api";

import "./Home.scss";
import NewsletterSignUpModal from "../../components/Modals/NewsletterSignUpModal/NewsletterSignUpModal";
import HeroSlider from "../../components/Home/HeroSlider/HeroSlider";

const Home = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [homeSlider, setHomeSlider] = useState([]);
  const seoCtx = useSeoContext();
  const AuthCtx = useAuthContext();
  const user = AuthCtx.user;
  const YellowCircle =
    "https://assets.gocoffeego.com/v2/assets/images/yellow-circle.svg";

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url === `/`);
  const location = useLocation();

  useEffect(() => {
    API.getHomeSlider()
      .then((res) => {
        console.log(res.data, "res");
        setHomeSlider(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (navigator.userAgent !== "ReactSnap") {
      const timer = setTimeout(showModalOnce, 5000);
      return () => clearTimeout(timer);
    }
  }, []);

  const showModalOnce = () => {
    let is_already_shown = sessionStorage.getItem("alreadyShown");
    if (
      is_already_shown !== "already shown" &&
      location.pathname === "/" &&
      !user
    ) {
      sessionStorage.setItem("alreadyShown", "already shown");
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <div className="Home">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}
      {navigator.userAgent !== "ReactSnap" && isOpen && (
        <NewsletterSignUpModal
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
        />
      )}
      {homeSlider?.length !== 0 && homeSlider?.images?.length !== 0 ? (
        <HeroSlider
          images={homeSlider?.images}
          pauseOnHover={homeSlider?.pause_on_mouse_over}
          effect={homeSlider?.effect}
          duration={homeSlider?.duration}
        />
      ) : (
        <Herobanner />
      )}

      <TakeQuiz />
      <Line className="Home__line1" />
      <Line />
      <CoffesByRoast {...props} />
      <div className="Home__line">
        <Line />
        <img className="yellow-circle" src={YellowCircle} alt="yellow-circle" />
      </div>
      <Line />
      <ChooseCoffes />
      <BrilliantRecommendations />
      <RockstarCoffes />
      <Subscribe />
      <WhyChooseGoCoffeGo />
      <Coffe101 showLines showCircle />
      <PeopleReview />
    </div>
  );
};

export default Home;
