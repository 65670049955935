import React from "react";
import FlickitySlider from "../../FlickitySlider/FlickitySlider";
import Herobanner from "../HeroBanner/HeroBanner";
import { useResponsiveDimensions } from "../../../lib/hooks/useResponsiveDimensions";
import { Link } from "react-router-dom";
import fade from "flickity-fade";

import slide3 from "../../../testVideos/slide15.png";
import "./HeroSlider.scss";

const HeroSlider = (props) => {
  const { images, duration, pauseOnHover } = props;
  const isMobile = useResponsiveDimensions().isMobile;

  let durationProp = parseInt(`${duration}000`);

  let options = {
    autoPlay: 8000,
    pauseAutoPlayOnHover: false,
    prevNextButtons: false,
    pageDots: true,
    /*     wrapAround: true, */
    groupCells: false,
    fade: true,
    on: {
      ready: function () {
        this.off("uiChange", this.stopPlayer);
        this.off("pointerDown", this.stopPlayer);
      },
    },
  };

  return (
    <div className="HeroSlider">
      <div className="HeroSlider__slider">
        <FlickitySlider options={options}>
          {!isMobile && <Herobanner />}
          {images.map((item, index) => (
            <div key={index} className="HeroSlider__review">
              {item?.link !== null ? (
                <Link to={item?.link}>
                  <img
                    className="HeroSlider__stars"
                    src={item?.src}
                    alt={item?.alt_tag_text}
                  />
                </Link>
              ) : (
                <Link to={"/coffee-gifts"}>
                  <img
                    className="HeroSlider__stars"
                    /*   src={item?.src} */
                    src={slide3}
                    alt={item?.alt_tag_text}
                  />
                </Link>
              )}
            </div>
          ))}
        </FlickitySlider>
      </div>
    </div>
  );
};

export default HeroSlider;
