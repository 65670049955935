import React, { useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import * as API from "../../api/Api";

import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import AwardWinnersItem from "../../components/AwardWinners/AwardWinnersItem";
import ViewMoreButton from "../../components/shared/ViewButton/ViewMoreButton";
import Filter from "../../components/Shop/Filter/Filter";
import { useFilterProductsContext } from "../../lib/context/FilterProductsContext/FilterProductsContext";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";

import "./AwardWinners.scss";
import ProductLoader from "../../components/Loaders/ProductLoader/ProductLoader";

const AwardWinners = () => {
  const Trophy =
    "https://assets.gocoffeego.com/v2/assets/images/trophy.png";

  const FiltersCtx = useFilterProductsContext();
  const seoCtx = useSeoContext();
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    FiltersCtx.getInitialProducts();

    return history.listen((location) => {
      if (history.action === "POP" && location.pathname === "/award-winners") {
        history.push("/");
      }
      FiltersCtx.setSelectedFilter([]);
    });
  }, []);

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url.toLowerCase() === pathname);
  const seoLinks = seoCtx.pageMetadata.filter((el) =>
    el.url.startsWith("/award-winners/")
  );

  return (
    <div className="AwardWinners">
      <PrimaryTitle title="AWARD WINNERS" />
      {seoLinks &&
        seoLinks.map((item) => <Link key={item?.id} to={item?.url} />)}
      <div className="AwardWinners__image-wrap">
        <img src={Trophy} alt="trophy" className="AwardWinners__trophy-image" />
      </div>
      <div className="AwardWinners__wrapper">
        {foundSeo && (
          <Seo
            title={foundSeo?.title}
            description={foundSeo?.description}
            keywords={foundSeo?.keywords}
          />
        )}
        <Filter awardWinners className="AwardWinners__filter" />

        {FiltersCtx.isLoading && FiltersCtx.awardWinners.length === 0 && (
          <div className="AwardWinners__container">
            {Array(12)
              .fill(0)
              .map((item, index) => (
                <ProductLoader key={index} />
              ))}
          </div>
        )}

        {!FiltersCtx.isLoading && FiltersCtx.awardWinners.length !== 0 && (
          <div className="AwardWinners__container">
            {FiltersCtx.awardWinners.map((item) => (
              <AwardWinnersItem item={item} />
            ))}
          </div>
        )}

        {FiltersCtx.awardWinners.length === 0 && (
          <div className="PreviousPeaberryRecommends__message font-cairo">
            No coffees found for this category
          </div>
        )}
      </div>
      {FiltersCtx.hasMorePage && (
        <ViewMoreButton handleClick={FiltersCtx.handleLoadMore} />
      )}
    </div>
  );
};

export default AwardWinners;
