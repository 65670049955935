import React from "react";
import { Link } from "react-router-dom";

import LinkButton from "../../shared/LinkButton/LinkButton";
import Button from "../../shared/Button/Button";

import "./GiftSubscriptions.scss";

const GiftSubscriptions = () => {
  const yogaGirl =
    "https://assets.gocoffeego.com/v2/assets/images/yogagirl.svg";
  const franchman =
    "https://assets.gocoffeego.com/v2/assets/images/franchman.svg";
  const jetset =
    "https://assets.gocoffeego.com/v2/assets/images/jetset.svg";
  return (
    <div className="GiftSubscriptions">
      <div className="GiftSubscriptions__types">
        <Link to="/coffee-gift-certificates">
          <div className="GiftSubscriptions__cert">
            <div className="GiftSubscriptions__background"></div>
            <div className="GiftSubscriptions__title">Gift Certificates</div>
            <div className="GiftSubscriptions__text">
              Personalize <br />
              a fab certificate <br />
              and let them <br />
              choose <br />
              the rest!
            </div>
            <img
              src={yogaGirl}
              alt="Girl doing yoga"
              className="GiftSubscriptions__image-girl"
            />
            <Button
              label="Shop certificates"
              className="GiftSubscriptions__gift-btn"
              onClick={() => console.log("clicked")}
            />
          </div>
        </Link>
        <Link to="/coffee-gifts">
          <div className="GiftSubscriptions__subsc">
            <div className="GiftSubscriptions__background"></div>
            <div className="GiftSubscriptions__subsc-title">
              Gift Subscriptions
            </div>
            <div className="GiftSubscriptions__subsc-text">
              Set them <br />
              off on <br />
              a coffee <br />
              adventure!
            </div>
            <img
              src={franchman}
              alt="Franchman"
              className="GiftSubscriptions__image-man"
            />
            <Button
              onClick={() => console.log("clicked")}
              label="Shop Subscriptions"
              className="GiftSubscriptions__gift-btn"
            />
          </div>
        </Link>
      </div>
      <div className="GiftSubscriptions__wrap">
        <Link to="/coffee-gifts">
          {" "}
          <div className="GiftSubscriptions__jetset-wrap">
            <img
              className="GiftSubscriptions__jetset"
              src={jetset}
              alt="jetset"
            />
          </div>
        </Link>
        <div className="GiftSubscriptions__tours">
          <div className="GiftSubscriptions__subtitle">
            Pre-paid Coffee gift subscriptions
          </div>
          <div className="GiftSubscriptions__tours-title">
            10 fab tours to choose from!
          </div>
          <LinkButton
            path="/coffee-gifts"
            className="GiftSubscriptions__tours-btn"
            label="View options"
          />
        </div>
      </div>
    </div>
  );
};

export default GiftSubscriptions;
