import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import { useFilterProductsContext } from "../../lib/context/FilterProductsContext/FilterProductsContext";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import MiddleLine from "../../components/shared/MiddleLine/MiddleLine";
import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import ViewMoreButton from "../../components/shared/ViewButton/ViewMoreButton";
import Filter from "../../components/Shop/Filter/Filter";
import Product from "../../components/Shop/Product/Product";
import Seo from "../../lib/helpers/SEO/Seo";

import "./LimitedOfferings.scss";
import ProductLoader from "../../components/Loaders/ProductLoader/ProductLoader";

const LimitedOfferings = () => {
  const LimitedOfferingsBanner =
    "https://assets.gocoffeego.com/v2/assets/images/limited-offerings.jpg";

  const FiltersCtx = useFilterProductsContext();
  const seoCtx = useSeoContext();
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  const pathnamesArray = pathname.split("/");

  useEffect(() => {
    FiltersCtx.getInitialProducts();

    return history.listen((location) => {
      if (
        history.action === "POP" &&
        location.pathname === "/limited-offerings"
      ) {
        history.push("/");
      }
      FiltersCtx.setSelectedFilter([]);
    });
  }, []);

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url.toLowerCase() === pathname);

  const seoLinks = seoCtx.pageMetadata.filter((el) =>
    el.url.startsWith("/limited-offerings/")
  );

  return (
    <div className="LimitedOfferings">
      <PrimaryTitle
        title="Limited Offerings"
        className="LimitedOfferings__title"
      />
      {seoLinks &&
        seoLinks.map((item) => <Link key={item?.id} to={item?.url} />)}
      <div className="LimitedOfferings__container">
        {foundSeo && (
          <Seo
            title={foundSeo?.title}
            description={foundSeo?.description}
            keywords={foundSeo?.keywords}
          />
        )}
        <div className="LimitedOfferings__filter-wrapper">
          <Filter limitedOfferings className="LimitedOfferings__filter" />
        </div>
        <div>
          {pathname.startsWith("/limited-offerings") &&
            pathnamesArray.length <= 2 && (
              <>
                <div className="LimitedOfferings__banner-wrapper">
                  <img
                    className="LimitedOfferings__banner"
                    src={LimitedOfferingsBanner}
                    alt="Limited Offerings banner"
                  />
                </div>
                <MiddleLine className="LimitedOfferings__middle-line" />
                <h2 className="LimitedOfferings__subtitle">
                  Our Limited Offering coffee beans for sale online are Limited
                  Edition Roasts and Rare or Seasonal Micro-Lots that are not
                  offered year round.
                </h2>
              </>
            )}
          {FiltersCtx.isLoading ? (
            <div className="LimitedOfferings__products-wrapper">
              {Array(12)
                .fill(0)
                .map((item, index) => (
                  <ProductLoader key={index} />
                ))}
            </div>
          ) : (
            <>
              {!FiltersCtx.isLoading &&
                FiltersCtx.limitedOfferings.length !== 0 && (
                  <div className="LimitedOfferings__products-wrapper">
                    {FiltersCtx.limitedOfferings?.map((product) => (
                      <Product product={product} key={product?.id} />
                    ))}
                  </div>
                )}
            </>
          )}
          {!FiltersCtx.isLoading &&
            FiltersCtx.limitedOfferings.length === 0 && (
              <h1 className="LimitedOfferings__message">
                No coffees found for this category
              </h1>
            )}
        </div>
      </div>
      {FiltersCtx.hasMorePage && (
        <ViewMoreButton
          handleClick={FiltersCtx.handleLoadMore}
          style={{ marginTop: "2rem", marginBottom: "5rem" }}
        />
      )}
    </div>
  );
};

export default LimitedOfferings;
