import React, { useEffect, useState } from "react";

import * as API from "../../api/Api.js";

import PrimaryTitle from "../../components/shared/PrimaryTitle/PrimaryTitle";
import MiddleLine from "../../components/shared/MiddleLine/MiddleLine";
import PrePaidSubscription from "../../components/PrePaid/PrePaidSubscription/PrePaidSubscription";
import Line from "../../components/shared/Line/Line";
import CoffeeShipments from "../../components/PrePaid/CoffeeShipments/CoffeeShipments";
import PrePaidImageLoader from "../../components/Loaders/PrePaidImageLoader/PrePaidImageLoader";
import { useSeoContext } from "../../lib/context/SeoContext/SeoContext";
import Seo from "../../lib/helpers/SEO/Seo";
import CoffeeTourModal from "../../components/PrePaid/CoffeeTourModal/CoffeeTourModal.js";
import { Link } from "react-router-dom";
import Button from "../../components/shared/Button/Button.js";

import "./PrePaid.scss";

const PrePaid = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const seoCtx = useSeoContext();

  const foundSeo = seoCtx.pageMetadata.find((el) => el.url === "/coffee-gifts");

  const yogaGirl =
    "https://assets.gocoffeego.com/v2/assets/images/yogagirl.svg";

  useEffect(() => {
    API.getAllCoffeeGifts().then((res) => {
      setSubscriptions(res.data);

      if (res.data) {
        setIsLoading(false);
      }
    });
  }, []);

  const selectedSubscription = subscriptions?.filter((item) => {
    return item.id === selectedSubscriptionId;
  });

  const onSubscriptionSelect = (id) => {
    const el = document.getElementById("coffee-shipments").offsetTop;
    window.scrollTo({
      top: el + 160,
      behavior: "smooth",
    });

    setSelectedSubscriptionId(id);
    localStorage.setItem("selected-subscription", id);
  };

  return (
    <div className="PrePaid">
      {foundSeo && (
        <Seo
          title={foundSeo?.title}
          description={foundSeo?.description}
          keywords={foundSeo?.keywords}
        />
      )}

      <PrimaryTitle className="PrePaid__title" title="Amazing Coffee Tours" />
      <div className="PrePaid__content">
        <MiddleLine className="PrePaid__line" />

        <div style={{ position: "relative" }}>
          <h2 className="PrePaid__section-title">
            Gift one of our Pre-paid Coffee Tours
          </h2>
          <Link to="/coffee-gift-certificates">
            <div className="PrePaid__cert-wrapper">
              <div className="PrePaid__cert">
                <div className="PrePaid__background"></div>
                <div className="PrePaid__gift-title">
                  Shop Gift Certificates
                </div>
                <div className="PrePaid__gift-text">
                  Personalize <br />
                  a fab certificate <br />
                  and let them <br />
                  choose <br />
                  the rest!
                </div>
                <img
                  src={yogaGirl}
                  alt="Girl doing yoga"
                  className="PrePaid__image-girl"
                />
              </div>
            </div>
          </Link>
        </div>

        <h3 className="PrePaid__section-subtitle">Tastemaker coffee tours</h3>
        <div className="PrePaid__subscriptions-tastemaker">
          {subscriptions
            .filter((tour) => tour?.taste_maker !== null)
            .map((item) => {
              return (
                <div className="PrePaid__tastemaker-tour" key={item.id}>
                  <CoffeeTourModal
                    isOpen={isOpen}
                    toggle={() => setIsOpen(!isOpen)}
                    tourName={item?.name}
                    tourShortMessage={item?.short_message}
                    tourDetail={item?.detail}
                    image={item?.taste_maker?.image}
                    name={item?.taste_maker?.name}
                    shortBio={item?.taste_maker?.short_bio}
                    bio={item?.taste_maker?.bio}
                    igFollowers={item?.taste_maker?.ig_followers}
                    igHandle={item?.taste_maker?.ig_handle}
                    onTourSelect={() => onSubscriptionSelect(item?.id)}
                    youtubeVideo={item?.taste_maker?.youtube_video}
                  />
                  <div
                    className="PrePaid__image-container"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <img
                      className="PrePaid__image"
                      src={
                        item?.image
                          ? item?.image
                          : "https://i.postimg.cc/rmCDZgBD/tastmaker1.jpg"
                      }
                      alt="subscription"
                    />
                    <div className="PrePaid__tour-wrapper">
                      <img
                        className="PrePaid__tastmaker-image"
                        src={
                          item?.taste_maker?.image
                            ? item?.taste_maker?.image
                            : "https://i.postimg.cc/rmCDZgBD/tastmaker1.jpg"
                        }
                        alt="subscription"
                      />
                      <div>
                        <h2 className="PrePaid__tour-title">{item?.name}</h2>
                        {item?.short_message && (
                          <p className="PrePaid__tour-subtitle">
                            {item?.short_message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="PrePaid__buttons-container">
                    <Button
                      onClick={() => setIsOpen(!isOpen)}
                      label="See Tour"
                      className="PrePaid__button-tour"
                    />
                    <Button
                      onClick={() => onSubscriptionSelect(item.id)}
                      label="Order Now"
                      className="PrePaid__button-order"
                    />
                  </div>
                </div>
              );
            })}
        </div>

        <h3 className="PrePaid__section-subtitle">Classic coffee tours</h3>
        <div className="PrePaid__subscriptions">
          <>
            {isLoading ? (
              <>
                {Array(10)
                  .fill(0)
                  .map((item, index) => (
                    <PrePaidImageLoader key={index} />
                  ))}
              </>
            ) : (
              <>
                {subscriptions
                  .filter((tour) => tour?.taste_maker === null)
                  .map((item) => {
                    return (
                      <PrePaidSubscription
                        shortMessage={item?.short_message}
                        detail={item?.detail}
                        name={item?.name}
                        id={item?.id}
                        selectedSubscriptionId={selectedSubscriptionId}
                        onClick={() => onSubscriptionSelect(item?.id)}
                        key={item?.id}
                        image={item?.image?.image?.image_url}
                      />
                    );
                  })}
              </>
            )}
          </>
        </div>
      </div>
      <Line />
      <Line className="PrePaid__line-bottom" />
      <div style={{ paddingTop: "20px" }} id="coffee-shipments">
        {selectedSubscription.length > 0 && (
          <CoffeeShipments selectedSubscription={selectedSubscription} />
        )}
      </div>
    </div>
  );
};

export default PrePaid;
